import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@app/_services/api.service';
import { Subscription, of } from 'rxjs';
import { IOption, IMember } from '@app/@core/interfaces';
import { isNullOrUndefined } from 'util';
import { DataHelperService } from '@app/_services/data.helper.service';
import { KazooMedia, KazooMediaUpload, KazooMediaUploadResponse, RingStratgy } from '@app/@core/interfaces/kz.interface';
import { MODAL_CLOSE_REASON } from '@app/variables/constants';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { IApiResponse } from './../../../../../@core/interfaces/api.interface';
import { ManageMembersModalComponent } from '../manage-members-modal/manage-members-modal.component';
import { CallFlowService } from '@app/_services/call-flow.service';
import { orderBy } from 'lodash';
import { alphaNumericValidator } from '../alphanumeric-validator';
import { LocalizationService } from '@app/internationalization/localization.service';
import * as moment from 'moment';

@Component({
  selector: 'app-select-team',
  templateUrl: './select-team.component.html',
  styleUrls: ['./select-team.component.scss']
})
export class SelectTeamComponent implements OnInit, OnDestroy {
  @Input() isEdit = false;
  @Input() isEditRoot = false;
  @Input() data;
  @Input() isOriginal;
  @Input() isSubIvr = false;
  @Input() isIvr = false;
  @Input() members: IMember[] = [];
  @Input() src;
  @Input() target;
  @Input() popupTitle = 'ring_group';
  mediaList: KazooMedia[] = [];
  mediaListOptions: IOption[] = [];
  form: FormGroup;
  formAddNew: FormGroup;
  loading = false;
  subscription$: Subscription;
  selectedMedia: KazooMedia = null;
  addNew = false;
  selectedRingstrategy: RingStratgy = 'single';
  teamManageDoneSubscription: Subscription;
  teamMembersOrder: Map<number, number> = new Map();
  selectedFile: File = null;
  subscription: Subscription;
  subscriptionOnHidden: Subscription;
  mediaListback: any;
  mediaplayer = false;
  mediasrc: any;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toasterService: ToastrService,
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private dataHelper: DataHelperService,
    private callflowService: CallFlowService,
    private localizationService: LocalizationService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initForm();
    this.getMediaList();
    this.subscribeToManageTeam();
    this.subscribeCloseModal();
  }

  subscribeToManageTeam() {
    this.teamManageDoneSubscription = this.callflowService.teamManageDone.subscribe(data => {
      if (!isNullOrUndefined(data)) {
        this.teamMembersOrder = data;
        this.form.get('membersOrder').setValue(this.teamMembersOrder);
        this.callflowService.teamManageChange(null);
      }
    });
  }

  onRingstrategyChanged(event) {
    const value = event.target.value;
    this.selectedRingstrategy = value;
    this.form.get('ringStrategy').setValue(value);
  }


  getMediaList() {
    const subscription = this.apiService.callGetMediaList().subscribe((res: IApiResponse<any>) => {
      this.mediaList = res.data as KazooMedia[];
      this.mediaListback = res.data;
      this.mediaListOptions = this.mediaList.map<IOption>(media => {
        return { label: media.name, value: media.id, selected: false };
      });

      if (this.isEdit) {
        let teamName = null;
        let ringStrategy = null;
        let seconds = null;
        let membersOrder = null;
        let media = null;
        let repeat = 1;
        if (this.isOriginal) {
          teamName = this.data.name;
          ringStrategy = this.data.strategy;
          seconds = this.data.endpoints[0].timeout;
          media = this.data.ringback;
          repeat = this.data.repeats;
          for (let i = 0; i < this.data.endpoints.length; i++) {
            const endpont = this.data.endpoints[i];
            const memberFound = this.members.find(member => member.kz_member_uuid === endpont.id);
            if (!isNullOrUndefined(memberFound)) {
              this.teamMembersOrder.set(memberFound.member_id, (i + 1));
            }
          }
          membersOrder = this.teamMembersOrder;
        } else {
          teamName = this.data.team;
          ringStrategy = this.data.teamData.ringStrategy;
          seconds = this.data.teamData.seconds;
          repeat = this.data.teamData.repeat;
          media = this.data.media ? this.data.media.id : null;
          membersOrder = orderBy(this.data.members, 'order', ['asc']);
          for (let i = 0; i < this.data.members.length; i++) {
            const member = this.data.members[i];
            if (!isNullOrUndefined(member)) {
              this.teamMembersOrder.set(member.member_id, member.order);
            }
          }
        }
        this.form.get('teamName').setValue(teamName);
        this.form.get('ringStrategy').setValue(ringStrategy);
        this.selectedRingstrategy = ringStrategy;
        this.form.get('seconds').setValue(seconds);
        this.form.get('media').setValue(media);
        this.form.get('membersOrder').setValue(membersOrder);
        this.form.get('repeat').setValue(repeat);
      }
      if (!isNullOrUndefined(subscription) && !subscription.closed) {
        subscription.unsubscribe();
      }
    }, error => {
      if (!isNullOrUndefined(subscription) && !subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }

  private initForm() {
    this.formAddNew = this.fb.group({
      name: [null, [Validators.required, alphaNumericValidator]],
      media: [null],
      document_progress: new FormControl(null)
    });
    this.form = this.fb.group({
      teamName: [null, [Validators.required, alphaNumericValidator]],
      ringStrategy: ['single', [Validators.required]],
      seconds: [30, [Validators.required, Validators.min(15), Validators.max(60)]],
      repeat: [1, [Validators.required, Validators.min(1), Validators.max(15)]],
      membersOrder: [null, [Validators.required]],
      media: [null],
    });
    this.form.get('media').valueChanges.subscribe(value => {
      if (this.mediasrc != '') {
        this.mediaplayer = false;
        this.mediasrc = '';
        this.changeDetector.detectChanges();
      }
      if (!isNullOrUndefined(value)) {
        if (this.addNew) {
          this.resetFormAddNew();
          this.toggleAddNew();
        }
        const mediaSelected = this.mediaList.find(media => media.id === value);
        if (!isNullOrUndefined(mediaSelected)) {
          this.selectedMedia = mediaSelected;
          const medialink = this.mediaListback.find(media => media.id === value);
          this.mediasrc = medialink.link;
          this.mediaplayer = true;
        }
      }
    });
  }

  toggleAddNew() {
    this.addNew = !this.addNew;
    if (this.addNew) {
      this.form.get('media').setValidators(null);
      this.form.get('media').setValue(null);
      if (!this.isEdit) {
        this.selectedMedia = null;
      }
    } else {
      this.form.get('media').setValidators(Validators.required);
      if (this.isEdit) {
        let mediaSelected = null;
        if (this.isOriginal) {
          // mediaSelected = this.data.id;
          mediaSelected = this.data.ringback;
        } else {
          mediaSelected = this.data.media ? this.data.media.id : null;
          // mediaSelected = this.data.media.id;
        }
        this.selectedMedia = this.mediaList.find(mediaItem => mediaItem.id === mediaSelected);
        this.form.get('media').setValue(mediaSelected);
      }
    }
  }

  openManageMembers() {
    const initialState = {
      members: this.members,
      selectedMembers: this.teamMembersOrder,
      isEdit: (this.isEdit || this.teamMembersOrder.size > 0)
    };
    this.bsModalService.show(
      ManageMembersModalComponent,
      { initialState, class: `dsk-modal manage-members-modal`, ignoreBackdropClick: true }
    );
  }

  fileSelected(file: File) {
    this.formAddNew.get('document_progress').setValue('selected');
    this.selectedFile = file;
  }


  uploadDocument(file: File, mediaForm: FormGroup) {
    if (mediaForm.invalid) {
      this.toasterService.warning('Please fill all required fields.');
      return;
    }

    let name = file.name;
    let type = file.type;
    let size = file.size;
    let sizeInMB = Math.round(size / 1024);
    if (sizeInMB > 5000) {
      this.toasterService.warning('The media file exceeds maximum size limit (5 MB).');
      mediaForm.get('media').setValue(null);
      mediaForm.get('document_progress').setValue('invlaid');
      return of(`${file.name} upload failed.`);
    }
    const payload: KazooMediaUpload = {
      media: file,
      name: this.formAddNew.value.name
    };
    return this.apiService.uploadMedia(payload).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = ((event.loaded / event.total) * 100).toFixed(0);
            mediaForm.get('document_progress').setValue('uploading ' + progress + '%');
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        mediaForm.get('document_progress').setValue('fail');
        return of(`${file.name} upload failed.`);
      })
    );
  }

  onSubmit() {
    if (this.addNew && this.formAddNew.invalid) {
      this.toasterService.warning('Please make sure all data is correct.');
      return;
    } else if (!this.addNew && this.form.invalid) {
      this.toasterService.warning('Please select a team.');
      return;
    } else {
      if (!isNullOrUndefined(this.selectedFile)) {
        this.uploadDocument(this.selectedFile, this.formAddNew).subscribe((event: any) => {
          if (typeof (event) === 'object') {
            this.formAddNew.get('document_progress').setValue('uploaded');
            const mediaUploadedResponse = event.body.data as KazooMediaUploadResponse;
            this.selectedMedia = {
              id: mediaUploadedResponse.id,
              name: mediaUploadedResponse.name,
              media_source: mediaUploadedResponse.media_source,
              language: mediaUploadedResponse.language,
              is_prompt: false,
            };
            this.mediaList.push(this.selectedMedia);
            this.mediaListOptions.push({ label: this.selectedMedia.name, value: this.selectedMedia.id, selected: false });
            this.save();
          }
        });
      } else {
        this.save();
      }
    }
  }

  save() {
    let members = [];
    const array = Array.from(this.teamMembersOrder.keys());
    for (let i = 0; i < array.length; i++) {
      const memberId = array[i];
      const memberFound = this.members.find(member => member.member_id === memberId);
      if (!isNullOrUndefined(memberFound)) {
        (memberFound as any).order = Number(this.teamMembersOrder.get(memberId));
        members.push(memberFound);
      }
    }
    console.log('save team this.data', this.data);

    const pendingData: any = {
      isEditRoot: this.isEditRoot,
      team: this.form.value.teamName,
      members,
      teamData: this.form.value,
      target: this.target,
      src: this.src,
      isIvr: this.isIvr,
      isEdit: this.isEdit,
      nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
    };
    if (!isNullOrUndefined(this.selectedMedia)) {
      pendingData.media = this.selectedMedia;
    }
    if (this.isSubIvr) {
      this.dataHelper.sendPendingSubIvrData(pendingData);
    } else if (this.isIvr && !this.isSubIvr) {
      this.dataHelper.sendPendingIvrData(pendingData);
    } else {
      this.dataHelper.sendPendingData(pendingData);
    }
    this.closeModal(true);
  }

  private subscribeCloseModal() {
    this.subscription = this.bsModalService.onHide.subscribe((reason: string) => {

      if (reason === null) {
        this.callflowService.teamManageChange(null);
        return;
      }
    });
    // this.subscriptionOnHidden = this.bsModalService.onHidden.subscribe(res => {
    //   this.bsModalRef = null;
    //   this.bsModalRef = null;
    // });
  }

  closeModal(noChange = false) {
    if (!noChange) {
      this.bsModalService.setDismissReason('close');
    } else {
      this.bsModalService.setDismissReason(MODAL_CLOSE_REASON.TEAM);
    }
    this.bsModalRef.hide();
    this.isEdit = null;
    this.isEditRoot = null;
    this.data = null;
    this.isOriginal = null;
    this.isSubIvr = null;
    this.isIvr = null;
    this.members = null;
    this.src = null;
    this.target = null;
    this.teamMembersOrder = null;
  }

  resetFormAddNew() {
    this.selectedFile = null;
    // this.addNew = false;
    this.formAddNew.reset();
  }

  clearMediaSelection(form) {
    form.get('media').setValue(null);
    this.data.ringback = null;
    this.selectedMedia = null;
    this.mediaListOptions.map(listItem => {
      listItem.selected = false;
    })
  }

  ngOnDestroy() {
    this.teamMembersOrder = null;
    this.callflowService.teamManageChange(null);
    if (this.subscriptionOnHidden) {
      this.subscriptionOnHidden.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
    if (this.teamManageDoneSubscription) {
      this.teamManageDoneSubscription.unsubscribe();
    }
  }

  getMaxWaitingTime(): string {
    const secondsValue = Number(this.form.get('seconds').value);
    const repeats = Number(this.form.get('repeat').value);
    const totalSeconds = secondsValue * repeats;
    const duration = moment.duration(totalSeconds, 'seconds');
    // const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const formattedTime = `${minutes} ${this.localizationService.translate('min')} ${seconds} ${this.localizationService.translate('sec')}`;
    return formattedTime;
  }
}
