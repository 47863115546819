import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@app/_services/api.service';
import { take, finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-team-name',
  templateUrl: './edit-team-name.component.html',
  styleUrls: ['./edit-team-name.component.scss']
})
export class EditTeamNameComponent implements OnInit, OnDestroy {
  @Input() mode;
  @Input() didNumber;
  @Input() teamId: number;
  @Input() teamName: string = null;
  @Input() popupTitle = "edit_team_name";
  form: FormGroup;
  loading = false;
  subscription$: Subscription;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toasterService: ToastrService,
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
  ) {
  }

  ngOnInit() {
    console.log(this.didNumber);
    this.initForm();
  }

  private initForm() {
    if(this.mode == "number"){
      this.form = this.fb.group({
        name: [this.didNumber.did_name, [Validators.required]],
      });
    }
    else{
      this.form = this.fb.group({
        name: [this.teamName, [Validators.required]],
      });
    }
  }

  onSubmit() {
    if (this.form.invalid) {
      this.toasterService.warning('Please enter team name.');
      return;
    }
    this.loading = true;
    const name = this.form.get('name').value;

    if(this.mode == "number"){
      this.subscription$ = this.apiService.callRenameNumber(this.didNumber.did_id, name)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      ).subscribe((response: any) => {
        if (response.success) {
          this.closeModal();
          this.toasterService.success('Success');
        } else {
          this.toasterService.warning(response.message);
        }
      }, (error) => {
        this.toasterService.error(error.error.message);
      });
    }
    else{
      this.subscription$ = this.apiService.callRenameTeam(this.teamId, name)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      ).subscribe((response: any) => {
        if (response.success) {
          this.closeModal();
          this.toasterService.success('Success');
        } else {
          this.toasterService.warning(response.message);
        }
      }, (error) => {
        this.toasterService.error(error.error.message);
      });
    }
  }

  closeModal(noChange = false) {
    if (noChange) {
      this.bsModalService.setDismissReason('close');
    } else {
      this.bsModalService.setDismissReason('edit-team-name');
    }
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }

}
