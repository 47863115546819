import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PopoverService {
    obsPopovers: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    popovers$: Observable<any> =  this.obsPopovers.asObservable();

    constructor() {}

    public get popovers(): any[] {
        return this.obsPopovers.value;
    }

    addPopover(popover) {
        this.popovers$.pipe(take(1)).subscribe(val => {
            const newArr = [...val, popover];
            this.obsPopovers.next(newArr);
        });
    }

    removePopover(idx) {
        this.popovers$.pipe(take(1)).subscribe(val => {
            const arr = this.obsPopovers.getValue();
            arr.splice(idx, 1);
            this.obsPopovers.next(arr);
        });
    }

    clearPopovers() {
        this.obsPopovers.next([]);
    }

}
