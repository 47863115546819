import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IOption, ISettings, ISettingsBusinessHours} from '@app/@core/interfaces';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {finalize, take} from 'rxjs/operators';
import {ApiService} from '@app/_services/api.service';
import {OptionService} from '@app/_services/option.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {UnsavedChangesService} from '@app/_services/unsaved-changes.service';
import {LocalizationService} from '@app/internationalization/localization.service';
import {AmazingTimePickerService} from 'amazing-time-picker';
import {DAYS} from '@app/pages/teams-and-members/components/team-settings/constants';
import {BusinessHoursItem, CustomWorkingHour, CustomWorkingHoursPayload} from '@app/@core/interfaces/kz.interface';
import {IApiResponse} from '@app/@core/interfaces/api.interface';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {isNullOrUndefined} from 'util';

@Component({
    selector: 'app-custom-working-hours-details',
    templateUrl: './custom-working-hours-details.component.html',
    styleUrls: ['./custom-working-hours-details.component.scss']
})
export class CustomWorkingHoursDetailsComponent implements OnInit {
    @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>(null);
    @Output() canceled: EventEmitter<boolean> = new EventEmitter<boolean>(null);
    @Input() customWorkingItem: CustomWorkingHour;
    @Input() showModal = true;
    @Input() showWorkingHoursName = true;
    options: Map<string, IOption[]> = new Map();
    ready = false;
    private defaultFormValues = null;
    form: FormGroup;
    TimeDataPickerData: ISettingsBusinessHours;
    workingHoursError: string;
    private subscription2: Subscription;
    private hoursSubscription: Subscription;
    days = DAYS;
    timeend: any;
    timefrom: any;
    loading = false;

    constructor(
        private atp: AmazingTimePickerService,
        private apiService: ApiService,
        private unsavedChangesService: UnsavedChangesService,
        private fb: FormBuilder,
        private optionService: OptionService,
        private localizationService: LocalizationService,
        private toasterService: ToastrService,
        private bsModalService: BsModalService,
        private bsModalRef: BsModalRef
    ) {

    }

    ngOnInit() {
        this.initData();
        this.initForm();
    }

    initData() {
        this.ready = false;
        combineLatest(
            this.apiService.getTimezones(),
            this.apiService.callGetCompanySettings()
        ).pipe(
            take(1),
            finalize(() => {
                this.ready = true;

                this.defaultFormValues = this.form.value;
            })
        ).subscribe(([timezoneResponse, companySettings]: [any, any]) => {
            if (timezoneResponse.data) {
                // set timezone_id
                const timezoneOptions = this.optionService.generateIOption(timezoneResponse.data, 'id', 'timezone_name');
                this.options.set('timezone', timezoneOptions);
            }
            if (companySettings.success) {
                const settings: ISettings = companySettings.data;
                if (this.showModal) {
                    this.prepareSettingsDefaultData(settings);
                }
                this.TimeDataPickerData = settings.business_hours;
            }
        });
    }

    initForm() {
        this.form = this.fb.group({
            timezone: [null, [Validators.required]],
            working_hours_name: [null, [Validators.required]],
            workingTimes: this.fb.array([])
        });

        if (this.form.get('working_hours_name') && !this.showWorkingHoursName) {
            this.form.get('working_hours_name').setValidators(null); // Remove the required validation
            this.form.get('working_hours_name').updateValueAndValidity(); // Update the validity state of the field
        }

        this.form.valueChanges.subscribe((v) => {
            this.unsavedChangesService.setUnsavedChanges(true);

            const business_hours_items = this.form.controls['workingTimes'].value;
            const business_hours_itemsArray = [];
            business_hours_items.forEach((x, index) => {
                const from = x.working_time_from || '';
                const fromMeridian = from && x.working_time_from_meridian || '';
                const to = x.working_time_to || '';
                const toMeridian = to && x.working_time_to_meridian || '';
                const beginningTime = moment(`${from} ${fromMeridian}`, 'h:mm a');
                const endTime = moment(`${to} ${toMeridian}`, 'h:mm a');
                if (from != '' && to != '' && !beginningTime.isBefore(endTime)) {
                    this.workingHoursError = 'The starting hour must be prior to the ending hour.';
                } else {
                    this.workingHoursError = null;
                }
            });

        });

        if (this.form && !this.showModal && !isNullOrUndefined(this.customWorkingItem)) {
            this.form.get('timezone').setValue(this.customWorkingItem.timezone.id);
            this.form.get('working_hours_name').setValue(this.customWorkingItem.business_hours_name);
            const workingTimes: BusinessHoursItem[] = this.customWorkingItem.items.map(item => {
                const businessHoursItem: BusinessHoursItem = {
                    id: item.business_hour_id,
                    working_days: item.working_days,
                    days_off: item.days_off,
                    working_time_from: item.working_time_from,
                    working_time_to: item.working_time_to,
                };
                return businessHoursItem;
            });
            // this.form.get('workingTimes').setValue(workingTimes);
            for (let i = 0; i < workingTimes.length; i++) {
                this.addWorkingTimeWithData(workingTimes[i]);
            }
        }

        this.subscription2 = this.unsavedChangesService.canceled$.subscribe(v => {
            this.resetForm();
        });
    }

    getOptions(key: string) {
        return this.options.get(key);
    }

    prepareSettingsDefaultData(settings: ISettings) {
        this.form.patchValue({
            timezone: settings.timezone_id
        });

        if (settings.business_hours) {
            // const business_hours_item = settings.business_hours_items;
            // const formArray = new FormArray([]);
            // business_hours_item.forEach(x => {
            //     this.addWorkingTimeWithData(x);
            // });
            // this.addWorkingTime(settings.business_hours, null, null, null, null, []);
            this.addNewWorkingTime();
        }
        const data = {};
        return data;
    }

    addNewWorkingTime() {
        console.log(this.workingTimesForm().length);
        this.workingTimesForm().push(this.newWorkingtime());
    }

    removeWorkingTime(index: number) {
        this.workingTimes().removeAt(index);
    }

    resetForm() {
        if (!this.defaultFormValues) {
            this.initForm();

        } else {
            this.form.patchValue(this.defaultFormValues, {emitEvent: true});
        }
        this.unsavedChangesService.setUnsavedChanges(false);
    }

    get f() {
        return this.form.controls;
    }

    workingTimesForm(): FormArray {
        return this.form.get('workingTimes') as FormArray;
    }

    workingTimes(): FormArray {
        return this.form.get('workingTimes') as FormArray;
    }

    convertTimeTo24Hour(time) {
        const [timePart, meridian] = time.split(' ');
        let [hours, minutes] = timePart.split(':');

        hours = parseInt(hours, 10);
        minutes = parseInt(minutes, 10);

        if (meridian === 'pm' && hours !== 12) {
            hours += 12;
        } else if (meridian === 'am' && hours === 12) {
            hours = 0;
        }

        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        return formattedTime;
    }


    newWorkingtime(): FormGroup {
        return this.fb.group({
            business_hour_id: [null],
            working_time_from: [null, [Validators.required, Validators.pattern('^(0?[1-9]|1[0-2]):[0-5][0-9]')]],
            working_time_from_meridian: [''],
            working_time_to: [null, [Validators.required, Validators.pattern('^(0?[1-9]|1[0-2]):[0-5][0-9]')]],
            working_time_to_meridian: [''],
            working_days: this.createWorkingDays(this.days)

        });
    }


    addWorkingTimeWithData(data) {

        const selectedWorkingDays: number[] = data.working_days.includes('[') ?
            JSON.parse(data.working_days) : data.working_days.split(',').map(d => Number(d));


        const working_days = this.days;
        const working_daysArray = [];

        working_days.forEach((d) => {
            // working_days[`${d}`].selected = selectedWorkingDays.includes(d) ? true : false;
            const day = [];
            day['selected'] = selectedWorkingDays.includes(d['value']);
            day['label'] = d['label'];
            day['value'] = d['value'];
            working_daysArray.push(day);
        });

        const {
            time: working_time_from,
            meridian: working_time_from_meridian
        } = this.convertTimeTo12Hour(data.working_time_from);

        const {
            time: working_time_to,
            meridian: working_time_to_meridian
        } = this.convertTimeTo12Hour(data.working_time_to);

        this.addWorkingTime(
            data,
            working_time_from,
            working_time_from_meridian,
            working_time_to,
            working_time_to_meridian,
            working_daysArray
        );

    }

    private addWorkingTime(
        data,
        working_time_from: string,
        working_time_from_meridian: string,
        working_time_to: string,
        working_time_to_meridian: string,
        working_daysArray: any[]
    ) {
        const fb = this.fb.group({
            business_hour_id: !isNullOrUndefined(data.business_hour_id) ? data.business_hour_id : data.id,
            working_time_from: [working_time_from, [Validators.required, Validators.pattern('^(0?[1-9]|1[0-2]):[0-5][0-9]')]],
            working_time_from_meridian: working_time_from_meridian,
            working_time_to: [working_time_to, [Validators.required, Validators.pattern('^(0?[1-9]|1[0-2]):[0-5][0-9]')]],
            working_time_to_meridian: working_time_to_meridian,
            working_days: this.setWorkingDaysValue(working_daysArray)
        });
        this.workingTimesForm().push(fb);
    }

    convertTimeTo12Hour(timeStr: string) {
        // Check correct time format and split into components
        let time = timeStr.slice(0, 5).match(/^([01]\d|2[0-3])(:)([0-5]\d)$/) || [timeStr];
        let meridian = 'am';
        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            meridian = +time[0] < 12 ? 'am' : 'pm'; // Set AM/PM
            time[0] = (+time[0] % 12) ? `${+time[0] % 12}` : '12'; // Adjust hours
            if (Number(time[0]) < 10) {
                time[0] = '0' + time[0];
            }
        }
        return {
            time: time.join(''),
            meridian: meridian,
        };
    }

    setWorkingDaysValue(selecedtDays) {
        const arr = selecedtDays.map(hobby => {
            return new FormControl(hobby['selected']);
        });
        return new FormArray(arr);
    }


    opentimefrom(ev: any) {
        this.TimeDataPicker(ev.controls.business_hour_id.value);

        const MyPreference = {
            labels: {
                ok: this.localizationService.translate('ok'),
                cancel: this.localizationService.translate('cancel')
            }
        };

        const amazingTimePicker = this.atp.open({
            changeToMinutes: true,
            time: this.transformtimetohrsec(this.timefrom),
            preference: MyPreference

        });
        amazingTimePicker.afterClose().subscribe(time => {
            const business_hours_items_time = this.form.controls['workingTimes'].value;
            business_hours_items_time.forEach(x => {
                if (x.business_hour_id == ev.controls.business_hour_id.value) {
                    ev.controls.working_time_from.setValue(this.transformtimefrom(time));
                    ev.controls.working_time_from_meridian.setValue(this.transformtimefrommeridian(time));
                }
            });

        });
    }

    transformtimefrom(time: any): any {
        let hour = (time.split(':'))[0];
        let min = (time.split(':'))[1];
        if (parseInt(hour) == 0) {
            hour = 12;
        }
        min = (min + '').length == 1 ? `0${min}` : min;
        hour = hour > 12 ? hour - 12 : hour;
        hour = (hour + '').length == 1 ? `0${hour}` : hour;
        return `${hour}:${min}`;
    }

    transformtimefrommeridian(time: any): any {
        let hour = (time.split(':'))[0];
        let min = (time.split(':'))[1];
        let part = hour >= 12 ? 'pm' : 'am';
        if (parseInt(hour) == 0) {
            hour = 12;
            part = 'am';
        }
        min = (min + '').length == 1 ? `0${min}` : min;
        hour = hour > 12 ? hour - 12 : hour;
        hour = (hour + '').length == 1 ? `0${hour}` : hour;
        return `${part}`;
    }

    transformtimetohrsec(time: any): any {
        if (isNullOrUndefined(time)) {
            return '';
        }
        const hour = (time.split(':'))[0];
        const min = (time.split(':'))[1];
        return `${hour}:${min}`;
    }

    opentimeto(ev: any) {
        this.TimeDataPicker(ev.controls.business_hour_id.value);

        const MyPreference = {
            labels: {
                ok: this.localizationService.translate('ok'),
                cancel: this.localizationService.translate('cancel')
            }
        };

        const amazingTimePicker = this.atp.open({
                changeToMinutes: true,
                time: this.transformtimetohrsec(this.timeend),
                preference: MyPreference
            }
        );


        amazingTimePicker.afterClose().subscribe(time => {
            const business_hours_items_time = this.form.controls['workingTimes'].value;
            business_hours_items_time.forEach(x => {
                if (x.business_hour_id == ev.controls.business_hour_id.value) {
                    ev.controls.working_time_to.setValue(this.transformtimeto(time));
                    ev.controls.working_time_to_meridian.setValue(this.transformtimetomeridian(time));
                }
            });

        });
    }

    transformtimeto(time: any): any {
        let hour = (time.split(':'))[0];
        let min = (time.split(':'))[1];
        if (parseInt(hour) == 0) {
            hour = 12;
        }
        min = (min + '').length == 1 ? `0${min}` : min;
        hour = hour > 12 ? hour - 12 : hour;
        hour = (hour + '').length == 1 ? `0${hour}` : hour;
        return `${hour}:${min}`;
    }

    transformtimetomeridian(time: any): any {
        let hour = (time.split(':'))[0];
        let min = (time.split(':'))[1];
        let part = hour >= 12 ? 'pm' : 'am';
        if (parseInt(hour) == 0) {
            hour = 12;
            part = 'am';
        }
        min = (min + '').length == 1 ? `0${min}` : min;
        hour = hour > 12 ? hour - 12 : hour;
        hour = (hour + '').length == 1 ? `0${hour}` : hour;
        return `${part}`;
    }

    TimeDataPicker(id) {
        // this.TimeDataPickerData.business_hour_id;
        // if (id == this.TimeDataPickerData.business_hour_id) {
        // }
        this.timefrom = this.TimeDataPickerData.working_time_from;
        this.timeend = this.TimeDataPickerData.working_time_to;
        // this.TimeDataPickerData.business_hours_items.map((data) => {
        // });
    }

    createWorkingDays(hobbiesInputs) {
        const arr = hobbiesInputs.map(hobby => {
            return new FormControl(hobby.selected || false);
        });
        return new FormArray(arr);
    }


    closeModal(noChange = false) {
        if (!this.showModal) {
            this.canceled.emit(true);
            return;
        }
        if (noChange) {
            this.bsModalService.setDismissReason('close');
        } else {
            this.bsModalService.setDismissReason('fetch');
        }
        this.bsModalRef.hide();
    }

    onSubmit() {
        console.log('submitted', this.f);
        // this.closeModal(true);
        if (this.form.valid) {
            const businessHoursItems: BusinessHoursItem[] = [];
            const workingHours = this.workingTimes().value as any[];
            const timezone_id = this.f.timezone.value;
            const business_hours_name = this.f.working_hours_name.value;
            const payload = this.prepareWorkingHoursDataToSend(workingHours, timezone_id, business_hours_name);
            console.log('payload', payload);
            // console.log('formData', this.apiService.prepareFormDataForCustomWorkingHours(payload));
            let request: Observable<any>;
            if (this.showModal) {
                request = this.apiService.createCustomWorkingHours(payload);
            } else {
                request = this.apiService.updateCustomWorkingHours(this.customWorkingItem.kz_business_hour_set_uuid, payload);
            }
            request.subscribe((response: IApiResponse<any>) => {
                console.log('response', response);
                if (response.success) {
                    this.toasterService.success('Success');
                    if (this.showModal) {
                        this.closeModal();
                    } else {
                        setTimeout(() => {
                            this.saved.emit(true);
                        }, 500);
                    }
                } else {
                    this.toasterService.warning(response.message);
                }
            }, (error) => {
                this.toasterService.error(error.error.message);
            });
        }
    }

    private prepareWorkingHoursDataToSend(workingHours: any[], timezone_id: any, business_hours_name: any) {
        const payload: CustomWorkingHoursPayload = {
            timezone_id,
            business_hours_name,
            business_hours_items: []
        };
        for (let i = 0; i < workingHours.length; i++) {
            const item = workingHours[i];
            const working_time_from_with_meridian = `${item.working_time_from} ${item.working_time_from_meridian}`;
            const working_time_to_with_meridian = `${item.working_time_to} ${item.working_time_to_meridian}`;
            const businessHourItem: BusinessHoursItem = {
                working_days: (item.working_days as boolean[]).map((workingDay, index) => workingDay == true ? index + 1 : null)
                    .filter(workingDay => workingDay != null),
                days_off: (item.working_days as boolean[]).map((dayOff, index) => dayOff == false ? index + 1 : null)
                    .filter(dayOff => dayOff != null),
                working_time_from: this.convertTimeTo24Hour(working_time_from_with_meridian),
                working_time_to: this.convertTimeTo24Hour(working_time_to_with_meridian)
            };
            const businessHourId = !isNullOrUndefined(item.business_hour_id) ?  item.business_hour_id :
                !isNullOrUndefined(item.id) ? item.id : null;
            if (!this.showModal && !isNullOrUndefined(businessHourId)) {
                businessHourItem.business_hour_id = businessHourId;
            }
            payload.business_hours_items.push(businessHourItem);
        }
        return payload;
    }
}
