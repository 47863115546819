import {Injectable} from '@angular/core';
// import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';

import {Observable, of} from 'rxjs';

import {AuthenticationService} from '@app/_services/authentication.service';
import {environment} from '@environments/environment';
import {LoaderService} from '@app/loader.service';
import {finalize, delay} from 'rxjs/operators';
// import {HttpResponse, HttpErrorResponse} from "@angular/common/http";
import 'rxjs/add/operator/do';
import {Router} from '@angular/router';
import {tap, catchError} from 'rxjs/operators';
import {LocalizationService} from '@app/internationalization/localization.service';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private apiUrl = `${environment.apiUrl}`;
    isLoggedIn = false;
    currentUser: any;
    public currentLang: string = '';

    constructor(private authenticationService: AuthenticationService,
                private loaderService: LoaderService,
                private router: Router,
                private localizationService: LocalizationService
    ) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.isLoggedIn = this.authenticationService.isAuthenticated();
        // this.currentLang = this.localizationService.getMyLanguage();
        this.currentLang = localStorage.getItem('language') || 'en';

        console.log('interceptor language', this.currentLang);

        if (request.headers.get('run-loading') === 'true') {
            this.loaderService.startLoading();
        }

        // add authorization header with jwt token if available
        const currentUser = this.authenticationService.currentUserValue;

        // console.log('request', request);
        // const url = `${this.apiUrl}/${request.url}`;
        const url = `${request.url}`;
        if (currentUser && currentUser.token) {
            request = request.clone({
                url,
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`,
                    localization: this.currentLang
                }
            });
        }


        return next.handle(request).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    this.loaderService.stopLoading();
                }
            }),
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401 || err.status === 403) {
                        this.loaderService.stopLoading();
                        this.authenticationService.logout();
                    }
                }
                this.loaderService.stopLoading();
                return of(err);
            }));
    }
}
