import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {LocalizationService} from '@app/internationalization/localization.service';
import {isNullOrUndefined} from 'util';
import {ALPHA_NUMERIC_REGEX, ALPHA_NUMERIC_VALIDATION_ERROR} from '@app/pages/call-flow/components/modals/alphanumeric-validator';

@Component({
    selector: 'dsk-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

    @Input() control: FormControl;
    @Input() name: string;
    @Input() placeholder: string;
    @Input() type;
    @Input() disabled = false;
    @Input() readonly = false;
    @Input() fullWidth = false;
    @Input() showError = false;
    @Input() styleType = 'dsk-input';
    @Input() noMargin = false;
    @Input() locked = false;
    @Input() maxlength = false;
    @Input() hideLabel = false;
    @Input() phoneMask = '00000000000999999';
    @Input() submitted = false;
    @Output() textValueChanged = new EventEmitter<number>();

    oldValue = null;
    shouldIncreaseMargin = false;

    constructor(private localizationService: LocalizationService
    ) {
    }

    ngOnInit() {
        if (this.type === 'time') {
            this.prepareTimeValue();
        }
    }

    prepareTimeValue() {
        if (this.type === 'time') {
            this.control.valueChanges.subscribe((v: string) => {
                const newChar = v.charAt(v.length - 1);
                const oldLength = this.oldValue ? this.oldValue.length : 0;
                this.oldValue = v;

                // not valid char
                if (!(Number(newChar) >= 0 && Number(newChar) <= 9)) {
                    const newValue = v.slice(0, (v.length - 1));
                    this.control.setValue(newValue);
                }

                if (v.length === 1) {
                    if (Number(newChar) > 1) {
                        this.setControl(`0${newChar}:`);
                    }
                } else if (v.length === 2) {
                    const firstChar = v.charAt(0);
                    if (Number(firstChar) === 1 && Number(newChar) > 2) {
                        const newValue = v.slice(0, (v.length - 1));
                        this.setControl(newValue);
                    } else if (oldLength < 2) {
                        this.setControl(this.control.value + ':');
                    }
                } else if (v.length === 3) {
                    const thirdChar = v.charAt(2);
                    if (thirdChar !== ':') {
                        if (Number(newChar) >= 0 && Number(newChar) <= 5) {
                            const newValue = v.slice(0, 2) + ':' + v.slice(2);
                            this.setControl(newValue);
                        } else {
                            const newValue = v.slice(0, (v.length - 1));
                            this.setControl(newValue);
                        }
                    }
                } else if (v.length === 4) {
                    if (!(Number(newChar) >= 0 && Number(newChar) <= 5)) {
                        const newValue = v.slice(0, (v.length - 1));
                        this.setControl(newValue);
                    }

                } else if (v.length > 5) {
                    const newValue = v.slice(0, 5);
                    this.setControl(newValue);
                }
            });
        }
    }

    setControl(newValue) {
        this.oldValue = newValue;
        this.control.setValue(newValue, {emitEvent: false});
    }

    get getErrorMessage() {
        const errors = [];
        if (this.control.touched || this.type == 'number') {
            if (this.control.hasError('required')) {
                const required = (!this.name ? this.placeholder : this.name) + ' ' + this.localizationService.translate('is_required');
                errors.push(`${required}`);
            }
            if (this.control.hasError('maxlength')) {
                const maxlength = this.localizationService.translate('maximum_length_is') + ' ' + this.control.errors.maxlength.requiredLength;
                errors.push(`${maxlength}`);
            }
            if (this.control.hasError('max')) {
                const max = this.localizationService.translate('maximum_value_should_be') + ' ' + this.control.errors.max.max;
                errors.push(`${max}`);
                // errors.push(`Maximum value should be ${this.control.errors.max.max}`);
            }
            if (this.control.hasError('min')) {
                const min = this.localizationService.translate('minimum_value_should_be') + ' ' + this.control.errors.min.min;
                errors.push(`${min}`);
                // errors.push(`Minimum value should be ${this.control.errors.min.min}`);
            }
            if (this.control.hasError('pattern') && this.control.errors.pattern.requiredPattern === '^[0-9]*$') {
                const only_numbers_is_allowed = this.localizationService.translate('only_numbers_is_allowed');
                errors.push(`${only_numbers_is_allowed}`);
                // only_numbers_is_allowed
                // errors.push(`Only Numbers is allowed`);
            }
            if (this.control.hasError('isExistValidator')) {
                const queue_name_exists = this.localizationService.translate('queue_name_exists');
                errors.push(`${queue_name_exists}`);
            }
        }
        if (this.control.hasError('pattern')) {
            if (this.control.errors.pattern.requiredPattern === '^[A-Za-z- ]*$') {
                const only_letters_a_z_allowed = this.localizationService.translate('only_letters_a_z_allowed');
                errors.push(`${only_letters_a_z_allowed}`);
            }
            if (this.control.errors.pattern.requiredPattern === '/^https:\\/\\/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]+)?\\/?$/') {
                const please_enter_valid_url = this.localizationService.translate('url_format_is_not_valid');
                errors.push(`${please_enter_valid_url}`);
            }
            if (this.control.errors.pattern.requiredPattern === '^[a-zA-Z0-9]+(_[a-zA-Z0-9]+)*$') {
                const space_and_symbols_are_not_allowed = this.localizationService.translate('space_and_symbols_are_not_allowed');
                errors.push(`${space_and_symbols_are_not_allowed}`);
            }
            if (this.control.errors.pattern.requiredPattern === '^[a-zA-Z0-9]+([ ]+[a-zA-Z0-9]+)*[ ]*$') {
                const symbols_are_not_allowed = this.localizationService.translate('symbols_are_not_allowed');
                errors.push(`${symbols_are_not_allowed}`);
            }
        }
        if (this.control.touched || this.type == 'text') {
            if (this.control.hasError('alphaNumericError') /*this.control.errors.pattern.requiredPattern === ALPHA_NUMERIC_REGEX*/) {
                this.shouldIncreaseMargin = true;
                const validationError = ALPHA_NUMERIC_VALIDATION_ERROR.alphaNumericError; // this.localizationService.translate(ALPHA_NUMERIC_VALIDATION_ERROR.alphaNumericError);
                errors.push(`${validationError}`);
            } else {
                this.shouldIncreaseMargin = false;
            }
        }
        if (errors.length == 0) {
            this.shouldIncreaseMargin = false;
        }
        return errors.join(', ');
    }

    get checkIfHasValue() {
        if (isNullOrUndefined(this.control)) {
            return false;
        }
        if (isNullOrUndefined(this.control.value) || (!isNullOrUndefined(this.control.value) && this.control.value.toString().length === 0)) {
            return false;
        }
        return true;
    }

}
