import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
    transform (sec: number, args?: any): string {
        if (sec < 0)  {
            return '00:00:00';
        }
        const h = sec / 3600 ^ 0;
        const m = (sec - h * 3600) / 60 ^ 0;
        const s = Math.trunc(sec - h * 3600 - m * 60);
        return (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s);
    }
}

@Pipe({
    name: 'timeWordFormat'
})
export class TimeWordFormatPipe implements PipeTransform {
    transform (sec: number, args?: any): string {
        if (sec < 0)  {
            return '0 seconds';
        }
        if(sec === 0) {
            return '0 seconds';
        }
        const h = sec / 3600 ^ 0;
        const m = (sec - h * 3600) / 60 ^ 0;
        const s = Math.trunc(sec - h * 3600 - m * 60);
        let format = '';
        if(h) {
            format += `${h}`;
            if(m || s) {
                format += `h `;
            } else {
                if(h === 1) {
                    format += ` hour`;
                } else {
                    format += ` hours`;
                }

            }
        }
        if(m) {
            format += `${m}`;
            if(s) {
                format += 'm ';
            } else {
                if(m === 1) {
                    format += ` minute`;
                } else {
                    format += ` minutes`;
                }
            }
        }
        if(s) {
            if(s === 1) {
                format += `${s} second`;
            } else {
                format += `${s} seconds`;
            }

        }
        return format;
    }
}
