import { IMember } from './../../../../../@core/interfaces/member.interface';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@app/_services/api.service';
import { take, finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IOption } from '@app/@core/interfaces';
import { isNullOrUndefined } from 'util';
import { DataHelperService } from '@app/_services/data.helper.service';
import { MODAL_CLOSE_REASON } from '@app/variables/constants';
import { KazooVMBoxes } from '@app/@core/interfaces/kz.interface';

@Component({
  selector: 'app-vmbox-select',
  templateUrl: './vmbox-select.component.html',
  styleUrls: ['./vmbox-select.component.scss']
})
export class VMBoxSelectComponent implements OnInit, OnDestroy {
  @Input() isEdit = false;
  @Input() isEditRoot = false;
  @Input() data;
  @Input() isOriginal;
  @Input() isSubIvr = false;
  @Input() isIvr = false;
  @Input() src;
  @Input() target;
  @Input() vmBoxName: string = null;
  @Input() vmBoxes: KazooVMBoxes[] = [];
  @Input() popupTitle = 'select_voicemail';
  vmBoxesOptions: IOption[] = [];
  form: FormGroup;
  loading = false;
  subscription$: Subscription;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toasterService: ToastrService,
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private dataHelper: DataHelperService
  ) {
  }

  ngOnInit() {
    this.vmBoxesOptions = this.vmBoxes.map(vmBox => {
      return { label: this.getVMBoxName(vmBox.id), value: vmBox.id, selected: false };
    });
    if (this.isEdit) {

    }
    this.initForm();
  }

  getVMBoxName(id: string) {
    const box = this.vmBoxes.find(boxItem => boxItem.id === id);
    if (!isNullOrUndefined(box)) {
      return box.name;
    }
    return '';
  }

  private initForm() {
    if (this.isEdit) {
      if (this.isOriginal) {
        this.vmBoxName = this.data.id;
      } else {
        this.vmBoxName = this.data.vmBox.id;
      }
    }
    this.form = this.fb.group({
      vmBox: [this.vmBoxName, [Validators.required]]
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.toasterService.warning('Please select a voicemail.');
      return;
    }
    const vmBox = this.vmBoxes.find(boxItem => boxItem.id === this.form.value.vmBox);
    if (!isNullOrUndefined(vmBox)) {
      if (this.isSubIvr) {
        this.dataHelper.sendPendingSubIvrData({
          isEditRoot: this.isEditRoot,
          vmBox,
          vmBoxName: this.getVMBoxName(vmBox.id),
          target: this.target,
          src: this.src,
          isIvr: this.isIvr,
          isEdit: this.isEdit,
          nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
        });
      } else if (this.isIvr && !this.isSubIvr) {
        this.dataHelper.sendPendingIvrData({
          isEditRoot: this.isEditRoot,
          vmBox,
          vmBoxName: this.getVMBoxName(vmBox.id),
          target: this.target,
          src: this.src,
          isIvr: this.isIvr,
          isEdit: this.isEdit,
          nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
        });
      } else {
        this.dataHelper.sendPendingData({
          isEditRoot: this.isEditRoot,
          vmBox,
          vmBoxName: this.getVMBoxName(vmBox.id),
          target: this.target,
          src: this.src,
          isIvr: this.isIvr,
          isEdit: this.isEdit,
          nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
        });
      }
      this.closeModal(true);
    }
  }

  closeModal(noChange = false) {
    if (!noChange) {
      this.bsModalService.setDismissReason('close');
    } else {
      this.bsModalService.setDismissReason(MODAL_CLOSE_REASON.VOICE_MAIL);
    }
    this.bsModalRef.hide();
    this.isEdit = null;
    this.isEditRoot = null;
    this.data = null;
    this.isOriginal = null;
    this.isSubIvr = null;
    this.isIvr = null;
    this.src = null;
    this.target = null;
  }

  ngOnDestroy() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }

}
