import { Component, OnInit, Input, ViewChildren } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthenticationService } from '@app/_services/authentication.service';
import { AccordionPanelComponent } from 'ngx-bootstrap/accordion';


declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    soon: boolean;
    permissions: any;
    queryParams?: any;
    children?: RouteInfo[];
    expanded?: boolean;
    isExternal?: boolean;
}


export const ROUTES: RouteInfo[] = [
    { path: '/numbers', title: 'numbers', icon: 'Numbers.svg', class: '', soon: false, permissions: ['numbers'] },
    {
        path: '/teams-and-members/',
        title: 'teams_and_members',
        icon: 'Teams.svg',
        class: '',
        soon: false,
        permissions: ['teams_members']
    },
    {
        path: '/insights',
        title: 'insights',
        icon: 'Reports.svg',
        class: '',
        soon: false,
        permissions: ['insights'],
        // expanded: false,
        // children: [
            // {
            //     path: '/insights/dashboards',
            //     title: 'dashboards',
            //     icon: null,
            //     class: '',
            //     soon: false,
            //     permissions: ['insights']
            // },
            // {
            //     path: '/insights/reports',
            //     title: 'reports',
            //     icon: null,
            //     class: '',
            //     soon: false,
            //     permissions: ['insights']
            // },
            // {
            //     path: '/insights/superset',
            //     title: 'superset',
            //     icon: null,
            //     class: '',
            //     soon: false,
            //     permissions: ['insights']
            // }
            
        // ]
    },
    {
        path: '/live-activities',
        title: 'live_activities',
        icon: 'Live_Activities.svg',
        class: '',
        soon: false,
        permissions: ['live-activities']
    },
    { path: '/sms', title: 'SMS', icon: 'SMS.svg', class: '', soon: true, permissions: ['sms'] },
    {
        path: '/flow',
        title: 'call_flow',
        icon: 'Call_Flow.svg',
        class: '',
        soon: false,
        permissions: ['caller_id'],
        expanded: false,
        children: [
            {
                path: '/flow',
                title: 'inbound',
                icon: null,
                class: '',
                soon: false,
                permissions: ['caller_id']
            },
            {
                path: '/caller-id',
                title: 'outbound',
                icon: null,
                class: '',
                soon: false,
                permissions: ['caller_id']
            },
        ]
    },
    {
        // todo check permission for queue
        path: '/queues',
        title: 'queues',
        icon: 'Queues_Grey.svg',
        class: '',
        soon: false,
        // todo check correct permission
        permissions: ['caller_id'],
        expanded: false,
        children: [
            {
                path: '/queues',
                title: 'dashboard',
                icon: null,
                class: '',
                soon: false,
                permissions: ['caller_id']
            },
            {
                path: '/breaks',
                title: 'breaks',
                icon: null,
                class: '',
                soon: false,
                permissions: ['caller_id']
            },
        ]
    },
    { path: '/livecalls', title: 'live_calls', icon: 'livecalls.svg', class: '', soon: false, permissions: ['voicemail'] },
    //{ path: '/voicemail', title: 'voicemail', icon: 'voice-message.svg', class: '', soon: false, permissions: ['voicemail'] },
    { path: '/media', title: 'media_manager', icon: 'media.svg', class: '', soon: false, permissions: ['voicemail'] },
    { path: '/integration', title: 'integrations', icon: 'Integration.svg', class: '', soon: false, permissions: ['voicemail'] },
    {
        path: 'https://wiki.talkchief.io/dashboard',
        title: 'help_center',
        icon: 'Tutorials.svg',
        class: '',
        soon: false,
        permissions: ['tutorials'],
        isExternal: true
    },

];
export const AGENT_ROUTES: RouteInfo[] = [
    { path: '/agent-dashboard', title: 'apps', icon: 'Apps.svg', class: '', soon: false, permissions: [] },
    { path: '/agent-queues', title: 'queues', icon: 'Queues_Grey.svg', class: '', soon: false, permissions: [] },
    { path: '/agent-voicemail', title: 'voicemail', icon: 'voice-message.svg', class: '', soon: false, permissions: ['caller_id'] },
    { path: '/agent-settings', title: 'settings', icon: 'Settings.svg', class: '', soon: false, permissions: [] }
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    @ViewChildren('accordion') accordion: AccordionPanelComponent[];
    @Input() isAgent = false;
    public menuItems: any[];
    public isCollapsed = true;
    public isOpen = false;
    public isTrial;

    constructor(private router: Router,
        private permissionsService: NgxPermissionsService,
        private auth: AuthenticationService) {
    }

    ngOnInit() {

        // let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        let currentUser = this.auth.currentUserValue;
        this.isTrial = currentUser['is_trial'] == 1;

        const perm = this.auth.getPermission();
        this.permissionsService.loadPermissions(perm);

        this.menuItems = !this.isAgent ? ROUTES.filter(menuItem => menuItem) : AGENT_ROUTES.filter(menuItem => menuItem);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.menuItems.forEach((item: RouteInfo) => {
                    if (event.url.includes(item.path)) {
                        item.expanded = true;
                    }
                });
            }
            this.isCollapsed = true;
        });
        console.log('here');
        if (this.auth.isProfessionalPlan) {
            const index = this.menuItems.findIndex(route => route.title === 'help_center');
            if (index > -1) {
                const item = {
                    path: '/working-hours',
                    title: 'working_hours',
                    icon: 'time_white.svg',
                    class: '',
                    soon: false,
                    permissions: ['caller_id']
                };
                this.menuItems.splice(index, 0, item);
            }
        }
    }

    logout() {
        this.auth.logout();
    }

    selectSubMenu(event: MouseEvent) {
        event.stopPropagation();
        event.stopImmediatePropagation();
    }

    closeAccordion() {
        if (!this.isAgent) {
            this.menuItems.map(group => {
                group.expanded = false;
            });
        }
    }

    toggleAccordion(selectedItem: RouteInfo) {
        this.menuItems.map((item: RouteInfo) => {
            if (selectedItem.path === item.path) {
                item.expanded = !item.expanded;
            } else {
                item.expanded = false;
            }
        });

    }
}
