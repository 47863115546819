import { IQueue } from './../../../../../@core/interfaces/queue.interface';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@app/_services/api.service';
import { take, finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IOption} from '@app/@core/interfaces';
import { isNullOrUndefined } from 'util';
import { DataHelperService } from '@app/_services/data.helper.service';
import { MODAL_CLOSE_REASON } from '@app/variables/constants';

@Component({
  selector: 'app-queue-select',
  templateUrl: './queue-select.component.html',
  styleUrls: ['./queue-select.component.scss']
})
export class QueueSelectComponent implements OnInit, OnDestroy  {

  @Input() isEdit = false;
  @Input() isEditRoot = false;
  @Input() data;
  @Input() isOriginal;
  @Input() isSubIvr = false;
  @Input() isIvr = false;
  @Input() src;
  @Input() target;
  @Input() queueName: string = null;
  @Input() queues: IQueue[] = [];
  @Input() popupTitle = 'select_queue';
  queuesOptions: IOption[] = [];
  form: FormGroup;
  loading = false;
  subscription$: Subscription;
  selectedQueue: any;

  constructor(
      private fb: FormBuilder,
      private toasterService: ToastrService,
      public bsModalRef: BsModalRef,
      private bsModalService: BsModalService,
      private dataHelper: DataHelperService
  ) {
  }

  ngOnInit() {
    this.queuesOptions = this.queues.map(queue => {
      return { label: this.getQueueName(queue.id), value: queue.id, selected: false };
    });
    this.initForm();
  }

  getQueueName(queue_id: string) {
    const queue = this.queues.find(queueItem => queueItem.id === queue_id);
    let queueName = '';
    if (!isNullOrUndefined(queue)) {
      queueName = queue.name.slice(0, 1).toUpperCase() + queue.name.slice(1);
    }
    return queueName;
  }

    private initForm() {
      let queueIdSelected = null;
      if (this.isEdit) {
        let queueFound: IQueue;
        if (this.isOriginal) {
          queueFound = this.queues.find(queue => queue.id === this.data.id);
          this.queueName = this.getQueueName(queueFound.id);
        }
        // todo complete the else statement after understanding the logic
        else {
          queueFound = this.queues.find(queue => queue.id === this.data.queue.id);
          this.queueName = this.getQueueName(queueFound.id);
        }
        const optionFound = this.queuesOptions.find(option => option.value === queueFound.id);
        if (!isNullOrUndefined(optionFound)) {
          optionFound.selected = true;
        }
        queueIdSelected = queueFound.id;
      }
      this.form = this.fb.group({
        queue: [queueIdSelected, [Validators.required]],
      });
    }

    onSubmit() {
      if (this.form.invalid) {
        this.toasterService.warning('Please select a queue.');
        return;
      }
      const queue = this.queues.find(queueItem => queueItem.id === this.form.value.queue);
      if (!isNullOrUndefined(queue)) {
        if (this.isSubIvr) {
          this.dataHelper.sendPendingSubIvrData({
            isEditRoot: this.isEditRoot,
            queue,
            queueName: this.getQueueName(queue.id),
            target: this.target,
            src: this.src,
            isEdit: this.isEdit,
            nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
          });
        } else if (this.isIvr && !this.isSubIvr) {
          console.log('submit this.isIvr');
          console.log('sex 2', {
            isEditRoot: this.isEditRoot,
            queue,
            queueName: this.getQueueName(queue.id),
            target: this.target,
            src: this.src,
            isEdit: this.isEdit,
            nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
          });
          this.dataHelper.sendPendingIvrData({
            isEditRoot: this.isEditRoot,
            queue,
            queueName: this.getQueueName(queue.id),
            target: this.target,
            src: this.src,
            isEdit: this.isEdit,
            nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
          });
        } else {
          this.dataHelper.sendPendingData({
            isEditRoot: this.isEditRoot,
            queue,
            queueName: this.getQueueName(queue.id),
            target: this.target,
            src: this.src,
            isEdit: this.isEdit,
            nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
          });
        }
      }
      this.closeModal(true);
    }

    closeModal(noChange = false) {
      if (!noChange) {
        this.bsModalService.setDismissReason('close');
      } else {
        this.bsModalService.setDismissReason(MODAL_CLOSE_REASON.QUEUE);
      }
      this.bsModalRef.hide();
      this.isEdit = null;
      this.isEditRoot = null;
      this.data = null;
      this.isOriginal = null;
      this.isSubIvr = null;
      this.isIvr = null;
      this.src = null;
      this.target = null;
    }

    ngOnDestroy() {
      if (this.subscription$) {
        this.subscription$.unsubscribe();
      }
    }

}
