export const DAYS = [
    {
        label: 'Su',
        value: 1,
        selected: false

    },
    {
        label: 'Mo',
        value: 2,
        selected: false

    },
    {
        label: 'Tu',
        value: 3,
        selected: false

    },
    {
        label: 'We',
        value: 4,
        selected: false

    },
    {
        label: 'Th',
        value: 5,
        selected: false

    },
    {
        label: 'Fr',
        value: 6,
        selected: false

    },
    {
        label: 'Sa',
        value: 7,
        selected: false

    },
];
