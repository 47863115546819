import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';
import {CipherParams, ISupportMessageRequest} from '@app/@core/interfaces';
import {Ct} from '@app/@core/interfaces';
import {environment} from '@environments/environment';

@Injectable({
    providedIn: 'root'
})


export class EncrDecrService {

    // ct: any;
    // JsonFormatter = {
    //     stringify: function (cipherParams: CipherParams) {
    //         // create json object with ciphertext
    //         const jsonObj = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
    //
    //
    //         // optionally add iv or salt
    //         if (cipherParams.iv) {
    //             jsonObj.iv = cipherParams.iv.toString();
    //         }
    //         if (cipherParams.salt) {
    //             jsonObj.s = cipherParams.salt.toString();
    //         }
    //         // stringify json object
    //         return JSON.stringify(jsonObj);
    //     },
    //     parse: function (jsonStr) {
    //         // parse json string
    //         const jsonObj = JSON.parse(jsonStr);
    //         // extract ciphertext from json object, and create cipher params object
    //         const cipherParams = CryptoJS.lib.CipherParams.create({
    //             ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct)
    //         });
    //         // optionally extract iv or salt
    //         if (jsonObj.iv) {
    //             cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
    //         }
    //         if (jsonObj.s) {
    //             cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
    //         }
    //         return cipherParams;
    //     }
    // };

    private appCode = `${environment.appCode}`;

    constructor() {
    }

    test() {
        // const data = [{id: 1}, {id: 2}];
        const data = 'hello word';
        // Encrypt
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), this.appCode);
        return ciphertext;


    }

    testDec(ciphertext) {

        const bytes = CryptoJS.AES.decrypt(ciphertext, '123456');
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        return originalText;

        // const data = [{id: 1}, {id: 2}];
        // const data = 'hello word';
        // // Encrypt
        // const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), '123456').toString();
        // return ciphertext;


    }

    set2(keys, value) {
        const ciphertext = CryptoJS.AES.encrypt(value.toString(), keys);
        return ciphertext;

    }

    get jsonFormatter() {
        return {
            stringify: (cipherParams: any) => {
                const jsonObj = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64), iv: null, s: null};
                if (cipherParams.iv) {
                    jsonObj.iv = cipherParams.iv.toString();
                }
                if (cipherParams.salt) {
                    jsonObj.s = cipherParams.salt.toString();
                }
                return JSON.stringify(jsonObj);
            },
            parse: (jsonStr) => {
                const jsonObj = JSON.parse(jsonStr);
                // extract ciphertext from json object, and create cipher params object
                const cipherParams = CryptoJS.lib.CipherParams.create({
                    ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct)
                });
                if (jsonObj.iv) {
                    cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
                }
                if (jsonObj.s) {
                    cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
                }
                return cipherParams;
            }
        };
    }

    /* Method for Encryption */
    encrypt(value: any) {
        value = value instanceof String ? value : JSON.stringify(value);
        const encrypted = CryptoJS.AES.encrypt(value, this.appCode,
            {format: this.jsonFormatter, mode: CryptoJS.mode.CBC}).toString();
        return encrypted;
    }

    /* Method for Decryption */
    decrypt(value: any, key): any {
        const decrypted = CryptoJS.AES.decrypt(value, key, {format: this.jsonFormatter}).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted);
    }


    set(keys, value) {
        var key = CryptoJS.enc.Utf8.parse(keys);
        var iv = CryptoJS.enc.Utf8.parse(keys);
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

        return encrypted.toString();
    }

    // The get method is use for decrypt the value.
    get(keys, value) {
        var key = CryptoJS.enc.Utf8.parse(keys);
        var iv = CryptoJS.enc.Utf8.parse(keys);
        var decrypted = CryptoJS.AES.decrypt(value, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }

}


