import { Injectable } from '@angular/core';
import { random } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesService {

  unsavedChanges: Subject<boolean> = new Subject<boolean>();
  unsavedChanges$ = this.unsavedChanges.asObservable();

  saveLoading: Subject<boolean> = new Subject<boolean>();
  saveLoading$ = this.saveLoading.asObservable();
  
  disableSaveButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  disableSaveButton$ = this.disableSaveButton.asObservable();

  initData: Subject<boolean> = new Subject<boolean>();
  initData$ = this.initData.asObservable();

  canceled: Subject<number> = new Subject<number>();
  canceled$ = this.canceled.asObservable();

  constructor() {}

  setUnsavedChanges(value) {
    this.unsavedChanges.next(value);
  }

  setSaveLoading(value) {
    this.saveLoading.next(value);
  }

  setDisableSaveButton(value) {
    this.disableSaveButton.next(value);
  }

  setInitData(value) {
    this.initData.next(value);
  }
  
  reset() {
    this.canceled.next(random(100));
  }

}
