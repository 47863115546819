import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class OneWayService {

    secretKey = environment.one_way;

    constructor() {

    }

    encrypt(value: string): string {
        return CryptoJS.AES.encrypt(value, this.secretKey).toString();
    }

    decrypt(textToDecrypt: string) {
        return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey).toString(CryptoJS.enc.Utf8);
    }

}
