import { FormControl, ValidationErrors } from '@angular/forms';

export const ALPHA_NUMERIC_REGEX = /^[A-Za-z0-9_ \u0590-\u05FF\u0600-\u06FF\u2000-\u206F\u20AA\u2028\u2029\u202A-\u202E\u2030-\u2046\u2048-\u204D\u206A-\u206F'"\u201C\u201D]*$/; // /^[a-zA-Z0-9_ ]*$/;
const MP3_REGEX = /.*\.mp3$/;
export const ALPHA_NUMERIC_VALIDATION_ERROR = { alphaNumericError: 'Only alphanumeric values, spaces, underscores, single quotes \', and double quotes " are allowed' };
const MP3_MEDIA_VALIDATION_ERROR = { mp3FileError: 'only mp3 files are allowed' };

export function alphaNumericValidator(control: FormControl): ValidationErrors | null {
    return ALPHA_NUMERIC_REGEX.test(control.value) ? null : ALPHA_NUMERIC_VALIDATION_ERROR;
}

export function mp3MediaValidator(control: FormControl): ValidationErrors | null {
    return MP3_REGEX.test(control.value) ? null : MP3_MEDIA_VALIDATION_ERROR;
}
