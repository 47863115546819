import {Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy} from '@angular/core';
import {IOption} from '@app/@core/interfaces';
import {Subscription} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {CallFlowService} from '@app/_services/call-flow.service';
import {LocalizationService} from '@app/internationalization/localization.service';

@Component({
    selector: 'dsk-callflow-add',
    templateUrl: './callflow-add.component.html',
    styleUrls: ['./callflow-add.component.scss']
})
export class CallFlowAddComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('popover') popover;
    @Output() changed = new EventEmitter<string | number>();
    @Input() disabled = false;
    @Input() showAdvancedTimeCondition = true;
    @Input() isParentWebhook = false;
    @Input() options: IOption[] = [];
    selectedOption: IOption;
    @Input() isSelected = false;
    @Input() size = 'medium';
    @Input() placeholder = '';
    @Input() fontSize = '14';
    private addChangedSubscription: Subscription;
    defaultOptions: IOption[] = [];
    constructor(
        public callFlowService: CallFlowService,
        private localizationService: LocalizationService
    ) {
        this.defaultOptions = [
            {
                value: 'temporal_route',
                // label: 'Time Condition',
                label: this.localizationService.translate('time_condition'),
                selected: false,
                icon: 'assets/img/icons/common/Time.svg'
            },
            {
                value: 'ring_group',
                label: this.localizationService.translate('team'),
                selected: false,
                icon: 'assets/img/icons/common/Teams.svg'
            },
            {
                value: 'user',
                label: this.localizationService.translate('member'),
                selected: false,
                icon: 'assets/img/icons/common/user-burnt-yellow.svg'
            },
            {
                value: 'acdc_member',
                label: this.localizationService.translate('queue'),
                selected: false,
                icon: 'assets/img/icons/common/Queues_Green.svg'
            },
            {
                value: 'play',
                // label: 'Media',
                label: this.localizationService.translate('media'),
                selected: false,
                icon: 'assets/img/icons/common/Media.svg'
            },
            {
                value: 'device',
                // label: 'Call Forward',
                label: this.localizationService.translate('call_forward'),
                selected: false,
                icon: 'assets/img/icons/common/Follow_Me_Purple.svg'
            },
            {
                value: 'voicemail',
                label: this.localizationService.translate('voicemail'),
                selected: false,
                icon: 'assets/img/icons/common/Voicemail.svg'
            },
            {
                value: 'callflow',
                label: this.localizationService.translate('callflow'),
                selected: false,
                icon: 'assets/img/icons/common/Call_Flow.svg'
            },
            {
                value: 'menu',
                label: this.localizationService.translate('IVR'),
                selected: false,
                icon: 'assets/img/icons/common/IVR.svg'
            },
        ];
        this.options = [...this.defaultOptions];
        // && !this.isParentWebhook
        if (this.callFlowService.isProfessionalPlan) {
            this.options.push({
                value: 'webhook',
                label: this.localizationService.translate('Webhooks'),
                selected: false,
                icon: 'assets/img/icons/common/webhooks.svg'
            });
            if (this.showAdvancedTimeCondition) {
                this.options.unshift({
                    value: 'temporal_route_advanced',
                    // label: 'Time Condition',
                    label: this.localizationService.translate('time_condition_advanced'),
                    selected: false,
                    icon: 'assets/img/icons/common/Time.svg'
                });
            }
        }
    }

    ngOnInit() {
        this.subscribeToAddChanged();
        if (this.options) {
            this.selectedOption = this.options.find(o => o.selected);
        }
    }

    ngOnChanges(change: SimpleChanges) {
        if (change && change.options && this.options) {
            this.selectedOption = this.options.find(o => o.selected);
        }
        this.options = [...this.defaultOptions];
        const index = this.options.findIndex(item => item.value === 'webhook');
        // && !this.isParentWebhook
        if (this.callFlowService.isProfessionalPlan) {
            if (index === -1) {
                this.options.push({
                    value: 'webhook',
                    label: this.localizationService.translate('Webhooks'),
                    selected: false,
                    icon: 'assets/img/icons/common/webhooks.svg'
                });
            }
        } else {
            if (index > -1) {
                this.options.splice(index, 1);
            }
        }
    }

    onClosePopover() {
        this.selectedOption = null;
    }

    openDropdown() {
        if (!this.disabled) {
            this.popover.toggle();
        }
    }

    onOpenPopover() {
    }

    onSelectOption(option: IOption) {
        this.changed.emit(option.value);
        this.selectedOption = option;
        this.popover.close();
    }

    subscribeToAddChanged() {
        this.addChangedSubscription = this.callFlowService.timeConditionSelected.subscribe(data => {
            // if (this.callFlowService.hideTimeConditionIvrBtns) {
            //   this.options.splice(0, 1);
            //   this.options.splice(-1);
            // } else {
            //   this.options = [...this.defaultOptions];
            // }
        });
    }

    ngOnDestroy() {
        if (!isNullOrUndefined(this.addChangedSubscription) && !this.addChangedSubscription.closed) {
            this.addChangedSubscription.unsubscribe();
        }
    }
}
