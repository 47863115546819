import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '@app/_services/api.service';
import {finalize, take} from 'rxjs/operators';
import {Subscription, combineLatest, Subject} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IMember, IOption} from '@app/@core/interfaces';
import {cloneDeep, fill} from 'lodash';
import {Router} from '@angular/router';
import {LocalizationService} from '@app/internationalization/localization.service';

@Component({
    selector: 'app-assign-members',
    templateUrl: './assign-members.component.html',
    styleUrls: ['./assign-members.component.scss']
})
export class AssignMembersComponent implements OnInit, OnDestroy {

    @Input() teamId: number;
    @Input() queue_id: string;
    @Input() agents = [] as any;
    @Input() action: string;
    @Input() member_status = null;
    // agents_login_manually;
    public onClose: Subject<any>;
    loading = false;
    subscription$: Subscription;
    searchValue: string;
    form: FormGroup;

    teamsFilterOptions: IOption[] = [];
    membersFilterOptions: IOption[] = [];
    membersListOptions: IOption[] = [];
    filteredMembersListOptions: IOption[] = [];
    memberStatusMap: Map<number, any> = new Map();
    selectedMembers = new Set<number>();

    members: IMember[] = [];
    filteredMembers: IMember[] = [];

    showAll = true;
    ready = false;
    skeletonItems = fill(Array(5), 0);
    loginMembers = [];
    logoutMembers = [];
    all_members = '';
    all_teams = '';

    queueSettingAgents = [];

    constructor(
        private fb: FormBuilder,
        private apiService: ApiService,
        private toasterService: ToastrService,
        public bsModalRef: BsModalRef,
        private router: Router,
        private bsModalService: BsModalService,
        private localizationService: LocalizationService
    ) {
        this.initForm();
        this.memberStatusMap.set(1, {label: 'Active', value: 'active'});
        this.memberStatusMap.set(2, {label: 'Inactive', value: 'inactive'});
        this.memberStatusMap.set(3, {label: 'Invite Sent', value: 'invite_sent'});
        this.memberStatusMap.set(4, {label: 'Deleted', value: 'deleted'});

    }

    ngOnInit() {
        this.onClose = new Subject();
        this.initData();

        this.all_members = this.localizationService.translate('all_members');
        this.all_teams = this.localizationService.translate('all_teams');
    }

    initForm() {
        this.form = this.fb.group({
            search: [null],
            team_filter: ['all'],
            member_filter: ['all'],
        });

        this.form.valueChanges.subscribe(value => this.doFiltering());
    }

    initData() {
        this.ready = false;
        combineLatest(
            this.apiService.callGetTeams(),
            // todo change teamid
            this.apiService.callGetCompanyMembers(this.member_status),
        ).pipe(
            take(1),
            finalize(() => this.ready = true),
        ).subscribe(([teamResponse, membersResponse]: [any, any]) => {
            // console.log('queue ID:', this.queue_id);
            if (this.queue_id) {
                this.apiService.getQueueSettings(this.queue_id).pipe(
                    finalize(() => {
                    }),
                ).subscribe((queueResponse: any) => {
                    if (queueResponse.success) {
                        const queueSettingAgents = queueResponse.data.data;
                        // console.log('queueSettingAgents', queueSettingAgents);
                        if(queueSettingAgents && queueSettingAgents.queue_agents) this.queueSettingAgents = queueSettingAgents.queue_agents;
                        // this.agents_login_manually = queueSettingAgents.agents_login_manually;
                    } else {

                    }
                }, (error) => {

                });
            }
            if (teamResponse.success) {
                this.teamsFilterOptions = this.generateIOption(teamResponse.data.teams, 'team_id', 'team_name');
                this.teamsFilterOptions.unshift({
                    selected: true,
                    label: this.all_teams,
                    value: 'all',
                });
            }

            if (membersResponse.success) {
                /*membersResponse.data = membersResponse.data.filter(x => x.member_status_id === 1);*/
                this.members = this.filteredMembers = membersResponse.data;
            }
            // build all agents as non selected
            const allMembersOptions = this.generateIOption(membersResponse.data, 'member_id', 'member_first_name', 'member_last_name');
            this.membersListOptions = this.filteredMembersListOptions = cloneDeep(allMembersOptions);
            this.membersFilterOptions = allMembersOptions;
            this.membersFilterOptions.unshift({
                selected: true,
                label: this.all_members,
                value: 'all',
            });
        });
    }

    onSubmit() {

        const members = Array.from(this.selectedMembers.values());
        if (this.queue_id && this.action === 'manage_agents') {
            // console.log('members value', members);

            // console.log('this.queueSettingAgents value', this.queueSettingAgents);
            // console.log('membersFilterOptions value', this.membersFilterOptions);
            const agents = this.membersFilterOptions.filter(x => members.includes(Number(x.value))).map(v => {
                return v.data.kz_member_uuid;
            });
            // console.log('this.agents value', this.agents);
            // console.log('agents value', agents);

            this.loginMembers = agents.filter(x => !this.agents.includes(x));
            // console.log('loginMembers value', this.loginMembers);

            this.logoutMembers = this.agents.filter(x => !agents.includes(x)).map(v => {
                return v;
            });
            // console.log('logoutMembers value', this.logoutMembers);

            const prepareAssignedAgents = [];

            agents.forEach((id, index) => {
                var i = this.agents.length+index+1;
                prepareAssignedAgents.push({
                    agent_id: id,
                    action: this.loginMembers.includes(id) ? 'login' : '',
                    member_extension: 1,
                    level: 1,
                    position: this.loginMembers.includes(id) ? i:1,
                });
            });

            this.logoutMembers.forEach(member => {
                var member_extension = null;
                var level = 1;
                var position = 1;
                this.queueSettingAgents.map(m=>{
                    if(m.kz_member_uuid==member){
                        member_extension = m.member_extension;
                        position = m.position;
                        level = m.level;
                    }
                })
                prepareAssignedAgents.push({
                    agent_id: member,
                    action: 'delete',
                    member_extension: member_extension,
                    level: level,
                    position: position,
                });
            });


            const newPrepareAssignedAgents = [];
            prepareAssignedAgents.forEach(x => {
                this.queueSettingAgents.forEach(item => {
                    if(item.kz_member_uuid==x.agent_id){
                        newPrepareAssignedAgents.push({
                            agent_id: item.kz_member_uuid,
                            action: this.logoutMembers.includes(item.kz_member_uuid) ? 'delete' : '',
                            member_extension: item.member_extension,
                            level: item.level,
                            position: item.position,
                        });
                    }else{
                        
                    }
                });
                
            });
            this.loginMembers.forEach((member, index) => {
                    // console.log('preId value', member);
                    var member_extension = null;
                    this.membersFilterOptions.map(m=>{
                        if(m.data&&m.data.kz_member_uuid==member){
                            member_extension = m.data.extension_num;
                        }
                    })
                    var i = this.agents.length+index+1;
                    // console.log('member_extension value', member_extension);
                    member_extension != null && newPrepareAssignedAgents.push({
                        agent_id: member,
                        action: 'login',
                        member_extension: member_extension,
                        level: 1,
                        position: this.loginMembers.includes(member) ? i:1,
                    });
            });
            // console.log('prepareAssignedAgents value', prepareAssignedAgents);
            // console.log('newPrepareAssignedAgents value', newPrepareAssignedAgents);
            if (newPrepareAssignedAgents.length > 0) {
                this.apiService.queueAssignMembers(this.queue_id, newPrepareAssignedAgents
                    // , this.agents_login_manually
                    ).pipe(
                    finalize(() => {
                        // this.loading = false;
                    })
                ).subscribe((response_: any) => {
                    if (response_.success) {
                        this.toasterService.success('Success');
                        this.closeModal(false, members);
                    } else {
                        this.toasterService.warning(response_.message);
                    }
                }, (err) => {
                    this.toasterService.error(err.error.message);
                });
            }
        }
        this.closeModal(false, members);
        /*this.loading = true;
        const members = Array.from(this.selectedMembers.values());
        this.subscription$ = this.apiService.callAssignMember(this.teamId, members)
            .pipe(
                finalize(() => {
                  this.loading = false;
                }),
            ).subscribe((response: any) => {
              if (response.success) {
                this.toasterService.success('Success');
                this.closeModal();
              } else {
                this.toasterService.warning(response.message);
              }
            }, (err) => {
              this.toasterService.error(err.error.message);
            });*/

    }

    onClickOption(selectedOptions: IOption) {
        selectedOptions.selected = !selectedOptions.selected;

        if (selectedOptions.selected) {
            this.selectedMembers.add(Number(selectedOptions.value));
        } else {
            this.selectedMembers.delete(Number(selectedOptions.value));
        }

        // If there is not selected then show all.
        if (this.selectedMembers.size === 0) {
            this.showAllMember();
        } else {
            this.doFiltering();
        }

    }

    doFiltering() {
        const search = this.f.search.value && this.f.search.value.toLowerCase();
        const teamId = this.f.team_filter.value;
        const memberId = this.f.member_filter.value;

        this.filteredMembersListOptions = this.membersListOptions.filter((o) => {
            return (!this.showAll ? o.selected : true)
                && (search ? o.label.toLowerCase().includes(search) : true)
                && (teamId && teamId !== 'all' ? o.data.team_id === teamId : true)
                && (memberId && memberId !== 'all' ? o.data.member_id === memberId : true);
        });

    }

    showSelectedMember() {
        this.showAll = false;
        this.doFiltering();
    }

    showAllMember() {
        this.showAll = true;
        this.doFiltering();
    }

    onSearchInputChanged(value: string) {
        this.searchValue = value;
        this.doFiltering();
    }

    generateIOption(items: any[], valueKey: string, labelKey: string, secondLabelKey?: string) {
        let newItems = [];
        if (items) {
            newItems = items.map(i => {
                if (i.kz_member_uuid && this.agents.includes(i.kz_member_uuid)) {
                    this.selectedMembers.add(Number(i[valueKey]));
                }
                return {
                    label: secondLabelKey ? `${i[labelKey]} ${i[secondLabelKey]}` : i[labelKey],
                    value: i[valueKey],
                    selected: i.kz_member_uuid && this.agents.includes(i.kz_member_uuid) ? true : false,
                    data: i,
                } as IOption;
            });
        }
        return newItems;
    }

    getMemberStatusMap(statusId: number) {
        return this.memberStatusMap.get(statusId) || '';
    }

    goToAddNewMemberPage() {
        return this.router.navigate(['/members', 'new']);
    }

    closeModal(noChange = false, members = []) {
        if (noChange) {
            this.onClose.next(false);
            this.bsModalService.setDismissReason('close');
            this.bsModalRef.hide();
        } else {
            const selectedMembers = this.membersFilterOptions.filter(x => members.includes(x.value));
            this.onClose.next(selectedMembers);
            this.bsModalRef.hide();
        }
    }

    get f() {
        return this.form.controls;
    }

    ngOnDestroy() {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }
}
