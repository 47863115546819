import {AfterViewChecked, Component, OnDestroy, OnInit, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from './_services/authentication.service';
import {User} from './_models/user';
import {LoaderService} from './loader.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConsoleToggleService} from './_services/console-toggle.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']

})
// export class AppComponent implements AfterViewChecked, OnDestroy, OnInit {
export class AppComponent implements OnDestroy, OnInit {
    title = 'TalkChief Dashboard';
    currentUser: User;
    loading = false;
    subscription: Subscription;
    lottieConfig = {
        path: 'assets/loader.json',
        renderer: 'canvas',
        autoplay: true,
        loop: true
    };


    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private loaderService: LoaderService,
        private translate: TranslateService,
        private consoleToggleService: ConsoleToggleService
        // private cdRef : ChangeDetectorRef

    ) {

        this.consoleToggleService.disableConsoleInProduction();
        // track loader
        this.subscription = this.loaderService.getLoading().subscribe(loading => this.loading = loading);
        this.authenticationService.currentUser.subscribe(user => {
            this.currentUser = user;


        });
        // translate.addLangs(['en', 'ar', 'hb']);
        // translate.setDefaultLang('ar');
        // translate.use('ar');
    }


    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    public useLanguage(lang: string): void {
        this.translate.setDefaultLang(lang);
    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }

    ngOnInit() {
       // this.updateHelpCrunchUserData();   // ## Yazan changed this 23-5-2023
      
    }

    // TODO
    // ngAfterViewChecked() {
    //     this.subscription = this.loaderService.getLoading().subscribe(loading => {
    //         this.loading = loading;
    //         this.cdRef.detectChanges();
    //     });
    //   }


    private updateHelpCrunchUserData() {
        if (this.currentUser) {
            const companyName = this.currentUser.company_name;
            const phoneNumber = this.currentUser.phone_number || '';
            const userId = this.currentUser.kz_company_uuid || this.currentUser.member_id;
            const userInfo = {
                email: this.currentUser.email,
                name: `${this.currentUser.member_first_name} ${this.currentUser.member_last_name}`,
                phone: `${phoneNumber}`,
                company: `${companyName}`,
                user_id: `${userId}`,
                is_trial: !!this.currentUser.is_trial
            };
            const userData = {
                full_name: `${this.currentUser.member_first_name} ${this.currentUser.member_last_name}`,
                company_name: `${companyName}`,
                company_id: `${this.currentUser.company_id}`,
                phone_number: `${phoneNumber}`,
                user_id: `${userId}`,
                is_trial: !!this.currentUser.is_trial,
                plan_name: this.currentUser.plan_name
            };
        //    HelpCrunch('updateUser', userInfo);
        //    HelpCrunch('updateUserData', userData);
        }
    }


}
