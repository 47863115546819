import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '@app/_services/api.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-numbers-used-by',
  templateUrl: './numbers-used-by.component.html',
  styleUrls: ['./numbers-used-by.component.scss']
})
export class NumbersUsedByComponent implements OnInit {
  usedBy;
  @Input() number;
  ready;

  constructor(
    public bsModalRef: BsModalRef,
    private apiService: ApiService,
    private bsModalService: BsModalService,
  ) {
  }

  ngOnInit() {
    this.initData();
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  initData(){
    this.ready= false;
    this.apiService.getNumberUsedBy(this.number.did_number).pipe(
      finalize(() => {
        this.ready = true;
      }),
    )
    .subscribe((response: any) => {
      if (response.success) {
        this.usedBy = response.data;
      }
    }, (error) => {
      console.log(error.error.message);
    });
  }
}