import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@app/_services/api.service';
import { Subscription, of } from 'rxjs';
import { IOption } from '@app/@core/interfaces';
import { isNullOrUndefined } from 'util';
import { DataHelperService } from '@app/_services/data.helper.service';
import { KazooCellPhoneDeviceRequest, KazooMedia, KazooMediaUpload, KazooMediaUploadResponse } from '@app/@core/interfaces/kz.interface';
import { MODAL_CLOSE_REASON } from '@app/variables/constants';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { IApiResponse } from './../../../../../@core/interfaces/api.interface';
import { alphaNumericValidator } from '../alphanumeric-validator';

@Component({
  selector: 'app-select-media',
  templateUrl: './select-media.component.html',
  styleUrls: ['./select-media.component.scss']
})
export class SelectMediaComponent implements OnInit, OnDestroy {
  @Input() isEdit = false;
  @Input() isEditRoot = false;
  @Input() data;
  @Input() isOriginal;
  @Input() isSubIvr = false;
  @Input() isIvr = false;
  @Input() src;
  @Input() target;
  @Input() popupTitle = 'select_media';
  mediaListback: any;
  mediaList: KazooMedia[] = [];
  mediaListOptions: IOption[] = [];
  form: FormGroup;
  formAddNew: FormGroup;
  loading = false;
  subscription$: Subscription;
  selectedMedia: KazooMedia = null;
  addNew = false;
  selectedFile: File = null;
  mediaplayer = false;
  mediasrc: any;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toasterService: ToastrService,
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private dataHelper: DataHelperService,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.getMediaList();
  }

  getMediaList() {
    // const subscription = this.apiService.getMediaList().subscribe((res: IApiResponse<any>) => {
    const subscription = this.apiService.callGetMediaList().subscribe((res: IApiResponse<any>) => {
      this.mediaList = res.data as KazooMedia[];
      this.mediaListback = res.data;
      this.mediaListOptions = this.mediaList.map<IOption>(media => {
        return { label: media.name, value: media.id, selected: false };
      });

      let mediaSelected = null;
      if (this.isEdit) {
        if (this.isOriginal) {
          mediaSelected = this.data.id;
        } else {
          mediaSelected = this.data.media.id;
        }
        this.selectedMedia = this.mediaList.find(mediaItem => mediaItem.id === mediaSelected);
        this.form.get('media').setValue(mediaSelected);
      }
      if (!isNullOrUndefined(subscription) && !subscription.closed) {
        subscription.unsubscribe();
      }
    }, error => {
      if (!isNullOrUndefined(subscription) && !subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }

  private initForm() {
    this.form = this.fb.group({
      media: [null, [Validators.required]]
    });
    this.formAddNew = this.fb.group({
      name: [null, [Validators.required, alphaNumericValidator]],
      media: [null, [Validators.required]],
      document_progress: new FormControl(null)
    });
    this.form.get('media').valueChanges.subscribe(value => {
      if (this.mediasrc != '') {
        this.mediaplayer = false;
        this.mediasrc = '';
        this.changeDetector.detectChanges();
      }
      if (!isNullOrUndefined(value)) {
        if (this.addNew) {
          this.resetFormAddNew();
          this.toggleAddNew();
        }
        const mediaSelected = this.mediaList.find(media => media.id === value);
        if (!isNullOrUndefined(mediaSelected)) {
          this.selectedMedia = mediaSelected;
          const medialink = this.mediaListback.find(media => media.id === value);
          this.mediasrc = medialink.link;
          this.mediaplayer = true;
        }
      }
    });
  }

  toggleAddNew() {
    this.addNew = !this.addNew;
    if (this.addNew) {
      this.form.get('media').setValidators(null);
      this.form.get('media').setValue(null);
      if (!this.isEdit) {
        this.selectedMedia = null;
      }
    } else {
      this.form.get('media').setValidators(Validators.required);
      if (this.isEdit) {
        let mediaSelected = null;
        if (this.isOriginal) {
          mediaSelected = this.data.id;
        } else {
          mediaSelected = this.data.media.id;
        }
        this.selectedMedia = this.mediaList.find(mediaItem => mediaItem.id === mediaSelected);
        this.form.get('media').setValue(mediaSelected);
      }
    }
  }

  fileSelected(file: File) {
    this.formAddNew.get('document_progress').setValue('selected');
    this.selectedFile = file;
  }

  uploadDocument(file: File, mediaForm: FormGroup) {
    if (mediaForm.invalid) {
      this.toasterService.warning('Please fill all required fields.');
      return;
    }

    let name = file.name;
    let type = file.type;
    let size = file.size;
    let sizeInMB = Math.round(size / 1024);
    if (sizeInMB > 5000) {
      this.toasterService.warning('The media file exceeds maximum size limit (5 MB).');
      mediaForm.get('media').setValue(null);
      mediaForm.get('document_progress').setValue('invlaid');
      return of(`${file.name} upload failed.`);
    }
    const payload: KazooMediaUpload = {
      media: file,
      name: this.formAddNew.value.name
    };
    return this.apiService.uploadMedia(payload).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = ((event.loaded / event.total) * 100).toFixed(0);
            mediaForm.get('document_progress').setValue('uploading ' + progress + '%');
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        mediaForm.get('document_progress').setValue('fail');
        return of(`${file.name} upload failed.`);
      })
    );
  }

  onSubmit() {
    if (!isNullOrUndefined(this.selectedFile)) {
      this.uploadDocument(this.selectedFile, this.formAddNew).subscribe((event: any) => {
        if (typeof (event) === 'object' && event.body && event.body.status === 'success') {
          this.formAddNew.get('document_progress').setValue('uploaded');
          const mediaUploadedResponse = event.body.data as KazooMediaUploadResponse;
          this.selectedMedia = {
            id: mediaUploadedResponse.id,
            name: mediaUploadedResponse.name,
            media_source: mediaUploadedResponse.media_source,
            language: mediaUploadedResponse.language,
            is_prompt: false,
          };
          this.mediaList.push(this.selectedMedia);
          this.mediaListOptions.push({ label: this.selectedMedia.name, value: this.selectedMedia.id, selected: false });
          this.save();
        } else if (event && event.body && event.body.success !== true) {
          this.formAddNew.get('document_progress').setValue('fail');
        }
      });
    } else {
      this.save();
    }
  }

  save() {
    if (this.addNew && this.formAddNew.invalid) {
      this.toasterService.warning('Please make sure all data is correct.');
      return;

    } else if (!this.addNew && this.form.invalid) {
      this.toasterService.warning('Please select a team.');
      return;
    } else {
      if (!isNullOrUndefined(this.selectedMedia)) {
        if (this.isSubIvr) {
          this.dataHelper.sendPendingSubIvrData({
            isEditRoot: this.isEditRoot,
            media: this.selectedMedia,
            target: this.target,
            src: this.src,
            isIvr: this.isIvr,
            isEdit: this.isEdit,
            nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
          });
        } else if (this.isIvr && !this.isSubIvr) {
          this.dataHelper.sendPendingIvrData({
            isEditRoot: this.isEditRoot,
            media: this.selectedMedia,
            target: this.target,
            src: this.src,
            isIvr: this.isIvr,
            isEdit: this.isEdit,
            nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
          });
        } else {
          this.dataHelper.sendPendingData({
            isEditRoot: this.isEditRoot,
            media: this.selectedMedia,
            target: this.target,
            src: this.src,
            isIvr: this.isIvr,
            isEdit: this.isEdit,
            nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
          });
        }
        this.closeModal(true);
      }
    }
  }

  resetFormAddNew() {
    this.selectedFile = null;
    // this.addNew = false;
    this.formAddNew.reset();
  }

  closeModal(noChange = false) {
    if (!noChange) {
      this.bsModalService.setDismissReason('close');
    } else {
      this.bsModalService.setDismissReason(MODAL_CLOSE_REASON.MEDIA);
    }
    this.bsModalRef.hide();
    this.isEdit = null;
    this.isEditRoot = null;
    this.data = null;
    this.isOriginal = null;
    this.isSubIvr = null;
    this.isIvr = null;
    this.src = null;
    this.target = null;
  }

  ngOnDestroy() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }

}
