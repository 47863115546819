import { NgModule, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './components/forms/input/input.component';
import { DateRangePickerComponent } from './components/forms/date-range-picker/date-range-picker.component';
import { NgbPopoverModule, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule, MaskService } from 'ngx-mask';
import { SelectComponent } from './components/forms/select/select.component';
import { LoadingComponent } from './components/loading/loading.component';
import { JpTimeMaskModule } from '@julianobrasil/timemask';
import { ButtonComponent } from './components/forms/button/button.component';
import { TelInputComponent } from './components/forms/tel-input/tel-input.component';
import { SelectAltComponent } from './components/select/select.component';
import { NumberDropdownComponent } from './components/number-dropdown/number-dropdown.component';
import { HighSpanScoreModalComponent } from './components/high-span-score-modal/high-span-score-modal.component';
import { UploaderComponent } from './components/forms/uploader/uploader.component';
import { CallFlowAddComponent } from './components/callflow-add/callflow-add.component';
import { MediaUploaderComponent } from '@app/pages/call-flow/components/media-uploader/media-uploader.component';
import { SelectAltWithSelectedComponent } from './components/select-with-selected/select-with-selected.component';
import { CalendarRangePickerComponent } from './components/forms/calendar-range-picker/calendar-range-picker.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { AssignMembersComponent } from './components/assign-members/assign-members.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { PipeModule } from '@app/@shared/pipes/pipe.module';
// import { EditTeamNameComponent } from '@app/pages/teams-and-members/components/edit-team-name/edit-team-name.component';
// import {NgxPermissionsModule} from 'ngx-permissions';
import { AmazingTimePickerModule } from 'amazing-time-picker';

import { HttpClient } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    CustomWorkingHoursDetailsComponent
} from '@app/@shared/components/custom-working-hours-details/custom-working-hours-details.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');

}
const COMPONENTS = [
    InputComponent,
    DateRangePickerComponent,
    SelectComponent,
    LoadingComponent,
    ButtonComponent,
    ProgressBarComponent,
    TelInputComponent,
    SelectAltComponent,
    NumberDropdownComponent,
    HighSpanScoreModalComponent,
    UploaderComponent,
    CallFlowAddComponent,
    MediaUploaderComponent,
    SelectAltWithSelectedComponent,
    CalendarRangePickerComponent,
    // EditTeamNameComponent
    CalendarRangePickerComponent,
    DropdownMenuComponent,
    CustomWorkingHoursDetailsComponent
];

@NgModule({
    imports: [
        ReactiveFormsModule,
        CommonModule,
        FormsModule,
        NgbPopoverModule,
        NgbButtonsModule,
        NgxMaskModule.forRoot(),
        JpTimeMaskModule,
        AmazingTimePickerModule,
        AngularMyDatePickerModule,
        PipeModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
        // NgxPermissionsModule.forChild(),
    ],
    declarations: [
        ...COMPONENTS,
        AssignMembersComponent,
        ProgressBarComponent,
        DropdownMenuComponent
    ],
    exports: [
        ...COMPONENTS,
        PipeModule

    ],
    entryComponents: [
        HighSpanScoreModalComponent,
        CustomWorkingHoursDetailsComponent
    ]
})
export class SharedModule {
}
