import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'customMask'
})
export class CustomMaskPipe implements PipeTransform {
    transform(didNumber: any, prfix?: any): string {

        console.log(didNumber);
        let maskString = '00000000000999999';
        let country: any;

        let value = didNumber.toString().trim().replace(/^\+/, '');
        if (value.match(/[^0-9]/)) {
            return didNumber;
        }

        console.log('value', value[0]);
        console.log('prfix', prfix);

        if (value.length >= 10) {
            // if (prfix !== '') {
            //     country = prfix;
            // } else {
            //     country = value[0];
            // }
            country = value[0];
        } else {
            return didNumber;
        }

        console.log('country', country);

        if (country == 1) {
            console.log('country', country);
            maskString = '0-000-000-99999';
            return maskString;
        } else {
            console.log('maskString', maskString);
            return maskString;
        }


    }
}

