import {NgModule} from '@angular/core';
import {TimeFormatPipe, TimeWordFormatPipe} from '@app/@shared/pipes/time.pipe';
import {CustomMaskPipe} from '@app/@shared/pipes/custom-mask.pipe';


@NgModule({
    imports: [],
    declarations: [TimeFormatPipe, TimeWordFormatPipe, CustomMaskPipe
    ],
    exports: [
        TimeFormatPipe,
        TimeWordFormatPipe,
        CustomMaskPipe
    ]

})
export class PipeModule {
}
