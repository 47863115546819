import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-high-span-score-modal',
  templateUrl: './high-span-score-modal.component.html',
  styleUrls: ['./high-span-score-modal.component.scss']
})
export class HighSpanScoreModalComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  goToNumbersPage() {
    this.bsModalRef.hide();
    this.router.navigate(['numbers']);
  }

}
