import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { IOption } from '@app/@core/interfaces';
import { FormControl } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { isUndefined } from 'util';

@Component({
  selector: 'dsk-number-dropdown',
  templateUrl: './number-dropdown.component.html',
  styleUrls: ['./number-dropdown.component.scss']
})
export class NumberDropdownComponent implements OnInit, OnChanges {

  @ViewChild('popover') popover;
  @Output() changed = new EventEmitter<string | number>();
  @Input() options: IOption[] = [];
  @Input() disabled = false;
  @Input() placeholder = '';
  selectedOption: IOption;
  @Input() control: FormControl;
  search: string;
  filteredOptions: IOption[] = [];
  @Input() noBorder =  false;
  @Input() fontSize = '14';
  @Input() width = 320;
  @Input() invalid =  false;

  constructor() { }

  ngOnInit() {
    if (this.control === undefined) {
      this.control = new FormControl();
    }
    this.setDefaultValue();
  }

  private setDefaultValue() {
    if (this.options) {
      this.filteredOptions = cloneDeep(this.options);
      this.selectedOption = this.filteredOptions.find(o => o.selected);
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (change && change.options) {
      this.setDefaultValue();
    }
  }

  markOptionAsSelected(selectedOption: IOption) {
    this.options.forEach(o => {
      if (o.value === selectedOption.value) {
        o.selected = true;
      } else {
        o.selected = false;
      }
    });
  }

  onSelectOption(option: IOption) {
    this.changed.emit(option.value);
    this.control.setValue(option.value);
    this.selectedOption = option;
    this.markOptionAsSelected(option);
    this.popover.close();
  }

  onHiddenDropdown() {
    this.filteredOptions = this.options;
  }

  onSearchInputChanged(search) {
    this.search = search;
    if (this.search) {
      this.filteredOptions = this.options.filter((o) => {
        return o.label.toLowerCase().includes(this.search.toLowerCase()) ||
          `${o.data.did_number}`.toLowerCase().includes(this.search.toLowerCase());
      });
    } else {
      this.filteredOptions = this.options;
    }
  }
}
