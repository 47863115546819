import {Injectable, Optional, SkipSelf} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocalizationServiceConfig} from './localization-config.service';
import {ApiService} from '@app/_services/api.service';
import {environment} from '@environments/environment';

/**
 * Class representing the translation service.
 */
@Injectable()
export class LocalizationService {
    private _localeId: string = 'en'; // default
    private _currLang: string = 'en'; // default
    private assestUrl = 'https://api.talkchief.io/';

    /**
     * @constructor
     * @param {LocalizationService} singleton - the localization service
     * @param {LocalizationServiceConfig} config - the localization config
     * @param {TranslateService} translateService - the translate service
     */
    constructor(
        @Optional() @SkipSelf() private singleton: LocalizationService,
        private config: LocalizationServiceConfig,
        private translateService: TranslateService,
        private apiService: ApiService
    ) {
        if (this.singleton) {
            throw new Error(
                'LocalizationService is already provided by the root module'
            );
        }
        this._localeId = this.config.locale_id;
        this._currLang = this.translateService.currentLang;
    }


    public initService(): Promise<void> {
        // language code same as file name.
        // this._localeId = localStorage.getItem('language') || 'en';
        this._localeId = localStorage.getItem('language') || 'en';
        localStorage.setItem('language', this._localeId);
        console.log('_localeId', this._localeId);
        return this.useLanguage(this._localeId);
    }

    public getMemberLanguage() {
        this.apiService.getMemberLanguage().subscribe((response: any) => {
            if (response.success) {
                this._localeId = response.data.lang;
            } else {
                this._localeId = 'en';
            }
            this.useLanguage(this._localeId);
        });
    }

    public switchMemberLang(lang: string) {
        this.apiService.switchMemberLang(lang).subscribe((response: any) => {
            // if (response.success) {
            //     this._localeId = response.data.lang;
            // } else {
            //     this._localeId = 'en';
            // }
            // this.useLanguage(this._localeId);
        });
    }


    public useLanguage(lang: string): Promise<any> {
        this.translateService.setDefaultLang(lang);
        return this.translateService
            .use(lang)
            .toPromise()
            .then(r => {
                this.switchMemberLang(lang);
                return r;
            })
            .catch(() => {
                throw new Error('LocalizationService.init failed');
            });
    }

    public getLanguages() {
        return [
            {
                'name': 'English',
                'code': 'en',
                'flag': this.assestUrl + '/public/images/flags/united-states-of-america.svg'
            },
            {
                'name': 'Hebrew',
                'code': 'hebrew',
                'flag': this.assestUrl + '/public/images/flags/israel.svg'

            },
            {
                'name': 'Spanish',
                'code': 'es',
                'flag': this.assestUrl + '/public/images/flags/spain.svg'

            }
        ];
    }

    public getMyLanguage() {
        const cc = this.translateService.currentLang;
        console.log('getMyLanguage', cc);

        return cc;
    }


    public translate(key: string | string[], interpolateParams?: object): string {
        return this.translateService.instant(key, interpolateParams) as string;
    }
}
