import {IApiResponse} from './../../../../../@core/interfaces/api.interface';
import {IMember} from './../../../../../@core/interfaces/member.interface';
import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '@app/_services/api.service';
import {take, finalize} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {IOption} from '@app/@core/interfaces';
import {isNullOrUndefined} from 'util';
import {DataHelperService} from '@app/_services/data.helper.service';
import {KazooTimeCondition, KazooTimeConditionDetails, KazooTimeConditionDetailsV2} from '@app/@core/interfaces/kz.interface';
import {MODAL_CLOSE_REASON} from '@app/variables/constants';
import {DAYS} from '@app/pages/teams-and-members/components/team-settings/constants';
import {alphaNumericValidator} from '../alphanumeric-validator';

@Component({
    selector: 'app-working-hours',
    templateUrl: './working-hours.component.html',
    styleUrls: ['./working-hours.component.scss']
})
export class WorkingHoursComponent implements OnInit, OnDestroy {
    @Input() isEdit = false;
    @Input() data;
    @Input() isOriginal;
    @Input() isIvr = false;
    @Input() isEditRoot = false;
    @Input() isSubIvr = false;
    @Input() src;
    @Input() target;
    @Input() popupTitle = 'working_hours';
    teams: KazooTimeCondition[] = [];
    teamsOptions: IOption[] = [];
    form: FormGroup;
    loading = false;
    subscription$: Subscription;
    selectedTeam: KazooTimeConditionDetails = null;
    selectedTeamStartTime: string = null;
    selectedTeamEndTime: string = null;
    days = DAYS;
    selectedTimeCondition: KazooTimeCondition;
    workingTimes: any;
    business_hours_itemsArray: any = [];

    constructor(
        private fb: FormBuilder,
        private apiService: ApiService,
        private toasterService: ToastrService,
        public  bsModalRef: BsModalRef,
        private bsModalService: BsModalService,
        private dataHelper: DataHelperService
    ) {
    }

    ngOnInit() {
        console.log('this.data', this.data);
        console.log('this.bsModalService', this.bsModalService);
        console.log('this.bsModalRef', this.bsModalRef);
        this.getTimeConditions();
        this.initForm();
    }

    getTimeConditions() {
        const subscription = this.apiService.getTimeConditions().subscribe((res: IApiResponse<KazooTimeCondition[]>) => {
            this.teams = res.data;
            this.teamsOptions = this.teams.map<IOption>(team => {
                return {label: team.name, value: team.kz_business_hour_set_uuid, selected: false};
            });
            if (this.isEdit) {
                const teamKzBusinessUuid = isNullOrUndefined(this.data.team) ? this.data.id : this.data.team.kz_business_hour_set_uuid;
                const isCompanyWorkingHours = !isNullOrUndefined(this.data.isCompanyWorkingHours) ? this.data.isCompanyWorkingHours : false;
                if (isCompanyWorkingHours) {
                    this.form.get('chooseCompanyWorkingHours').setValue(isCompanyWorkingHours);
                }
                const foundTeam = this.teams.find(team => team.kz_business_hour_set_uuid === teamKzBusinessUuid);
                if (!isNullOrUndefined(foundTeam)) {
                    const id = foundTeam.kz_business_hour_set_uuid;
                    // this.getTimeConditionsById(this.data.id);
                    setTimeout(() => {
                        this.form.get('team').setValue(id);
                        this.getTimeConditionsById(id);
                    }, 200);
                }
            }
            if (!isNullOrUndefined(subscription) && !subscription.closed) {
                subscription.unsubscribe();
            }
        }, error => {
            if (!isNullOrUndefined(subscription) && !subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }

    getTimeConditionsById(teamId) {
        this.selectedTeam = null;
        const subscription = this.apiService.getTimeConditionsById(teamId).subscribe((res: any) => {
            if (res.success) {
                this.selectedTeam = res.data;
                this.workingTimes = this.prepearDatesSets(res.data.working_times);
                const optionItem = this.teamsOptions.find(option => option.value === res.data.kz_business_hour_set_uuid);
                if (!isNullOrUndefined(optionItem)) {
                    optionItem.selected = true;
                    // this.form.get('team').setValue(optionItem.value);
                }
            }
            if (!isNullOrUndefined(subscription) && !subscription.closed) {
                subscription.unsubscribe();
            }
        }, error => {
            if (!isNullOrUndefined(subscription) && !subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }

    private initForm() {
        let team = null;
        let chooseCompanyWorkingHours = null;
        if (this.isEdit) {
            if (this.isOriginal) {
                team = this.data.timezone;
                chooseCompanyWorkingHours = false;
            } else {
                team = !isNullOrUndefined(this.data.timeCondition) ? this.data.timeCondition.gmtzone : this.data.team.kz_business_hour_set_uuid;
                chooseCompanyWorkingHours = false;
            }
        }
        this.form = this.fb.group({
            team: [team, [Validators.required, alphaNumericValidator]],
            chooseCompanyWorkingHours: [chooseCompanyWorkingHours]
        });
        this.form.get('team').valueChanges.subscribe(value => {
            if (!isNullOrUndefined(value)) {
                this.getTimeConditionsById(value);
                this.selectedTimeCondition = this.getSelectedTeam(value);
            }
        });
        this.form.get('chooseCompanyWorkingHours').valueChanges.subscribe(value => {
            if (!isNullOrUndefined(value)) {
                const company = this.teams.find(team => team.is_company_working_hours === 1);
                if (!isNullOrUndefined(company)) {
                    this.teamsOptions = this.teams.map<IOption>(team => {
                        return {label: team.name, value: team.kz_business_hour_set_uuid, selected: false};
                    });
                    // this.getTimeConditionsById(company.business_hour_id);
                    this.selectedTimeCondition = company;
                    this.form.get('team').setValue(company.kz_business_hour_set_uuid);
                }
            }
        });

    }


    prepearDatesSets(times) {
        this.business_hours_itemsArray = [];
        times.forEach(x => {
            const working_time_from = this.formatTime(x.working_time_from);
            const working_time_to = this.formatTime(x.working_time_to);
            const working_days = this.formatDays(x.working_days);
            const payload = {
                working_time_from: working_time_from,
                working_time_to: working_time_to,
                working_days: working_days,
            };
            this.business_hours_itemsArray.push(payload);
        });
    }


    formatTime(time) {
        time = time.split(':');
        time = new Date().setHours(Number(time[0]), Number(time[1]), Number(time[2]));
        time = new Date(time).toISOString();
        return time;
    }

    formatDays(days) {
        // const days = (<any>res.data).working_days as string;
        days = (<any>days) as string;
        days = JSON.parse(days);
        return days;

    }

    getSelectedTeam(teamId) {
        const teamfound = this.teams.find(team => team.kz_business_hour_set_uuid === teamId);
        if (!isNullOrUndefined(teamfound)) {
            return teamfound;
        }
        return null;
    }

    onSubmit() {
        if (this.form.invalid) {
            this.toasterService.warning('Please select a team.');
            return;
        }
        if (!isNullOrUndefined(this.selectedTeam)) {
            if (this.isSubIvr) {
                this.dataHelper.sendPendingSubIvrData({
                    isEditRoot: this.isEditRoot,
                    isIvr: this.isIvr,
                    team: this.selectedTeam,
                    timeCondition: this.selectedTimeCondition,
                    chooseCompanyWorkingHours: this.form.value.chooseCompanyWorkingHours,
                    target: this.target,
                    src: this.src,
                    isEdit: this.isEdit,
                    isAdvancedTimeCondition: this.bsModalRef.content.isAdvancedTimeCondition,
                    nodeId: !isNullOrUndefined(this.data) &&
                        !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                });
            } else if (this.isIvr && !this.isSubIvr) {
                this.dataHelper.sendPendingIvrData({
                    isEditRoot: this.isEditRoot,
                    isIvr: this.isIvr,
                    team: this.selectedTeam,
                    timeCondition: this.selectedTimeCondition,
                    chooseCompanyWorkingHours: this.form.value.chooseCompanyWorkingHours,
                    target: this.target,
                    src: this.src,
                    isEdit: this.isEdit,
                    isAdvancedTimeCondition: this.bsModalRef.content.isAdvancedTimeCondition,
                    nodeId: !isNullOrUndefined(this.data) &&
                        !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                });
            } else {
                this.dataHelper.sendPendingData({
                    team: this.selectedTeam,
                    timeCondition: this.selectedTimeCondition,
                    chooseCompanyWorkingHours: this.form.value.chooseCompanyWorkingHours,
                    target: this.target,
                    src: this.src,
                    isEdit: this.isEdit,
                    isAdvancedTimeCondition: this.bsModalRef.content.isAdvancedTimeCondition,
                    nodeId: !isNullOrUndefined(this.data) &&
                        !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                });
            }
            this.closeModal(true);
        }
    }

    closeModal(noChange = false) {
        if (!noChange) {
            this.bsModalService.setDismissReason('close');
        } else {
            this.bsModalService.setDismissReason(MODAL_CLOSE_REASON.WORKING_HOURS);
        }
        this.bsModalRef.hide();
    }

    ngOnDestroy() {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }

}
