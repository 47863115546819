import {IApiResponse} from './../../../../../@core/interfaces/api.interface';
import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '@app/_services/api.service';
import {combineLatest, Subscription} from 'rxjs';
import {IOption} from '@app/@core/interfaces';
import {isNullOrUndefined} from 'util';
import {DataHelperService} from '@app/_services/data.helper.service';
import {KazooCellPhoneDevice, KazooCellPhoneDeviceRequest, KazooCreateCellPhoneDeviceResponse} from '@app/@core/interfaces/kz.interface';
import {MODAL_CLOSE_REASON} from '@app/variables/constants';
import {alphaNumericValidator} from '../alphanumeric-validator';
import {DeleteNumberModalComponent} from '@app/pages/numbers/components/delete-number-modal/delete-number-modal.component';
import {DeleteCellphoneModalComponent} from '@app/pages/call-flow/components/delete-cellphone-modal/delete-cellphone-modal.component';
import {EditCellphoneModalComponent} from '@app/pages/call-flow/components/edit-cellphone-modal/edit-cellphone-modal.component';

@Component({
    selector: 'app-call-forward',
    templateUrl: './call-forward.component.html',
    styleUrls: ['./call-forward.component.scss']
})
export class CallForwardComponent implements OnInit, OnDestroy {
    @Input() isEdit = false;
    @Input() isEditRoot = false;
    @Input() data;
    @Input() isOriginal;
    @Input() isSubIvr = false;
    @Input() isIvr = false;
    @Input() src;
    @Input() target;
    @Input() popupTitle = 'call_forward';
    numbers: KazooCellPhoneDevice[] = [];
    numberOptions: IOption[] = [];
    form: FormGroup;
    formAddNew: FormGroup;
    loading = false;
    subscription$: Subscription;
    selectedCellPhone: KazooCellPhoneDevice = null;
    addNew = false;
    modalRef?: BsModalRef;
    followMeStatusChecked = false;
    followMeStatus = 0;
    require_keypress = false;
    allow_recording = false;
    countriesOptions: IOption[] = [];
    flag;
    timeout: any;

    constructor(
        private fb: FormBuilder,
        private apiService: ApiService,
        private toasterService: ToastrService,
        public bsModalRef: BsModalRef,
        public bsModalRef2: BsModalRef,
        public bsModalRef3: BsModalRef,
        private bsModalService: BsModalService,
        private dataHelper: DataHelperService
    ) {
    }

    ngOnInit() {
        console.log('data', this.data);
        this.initForm();
        this.getCellPhoneDevices();

        this.initData();

        this.watchCountryFlag();
    }

    initData() {
        combineLatest(
            this.apiService.getCountries(),
        ).subscribe(([countriesResponse]: [any]) => {
            if (countriesResponse.success) {
                this.countriesOptions = this.generateIOption(countriesResponse.data, 'country_id', 'country_name');
            }
        }, (err) => {
            this.toasterService.error(err.error.message);
        });
    }

    generateIOption(items: any[], valueKey: string, labelKey: string) {
        let newItems = [];
        if (items) {
            newItems = items.map(i => {
                return {
                    label: i[labelKey],
                    value: i[valueKey],
                    selected: false,
                    data: i,
                } as IOption;
            });
        }
        return newItems;
    }

    getCellPhoneDevices(deviceId?: any) {
        const subscription = this.apiService.getCellPhoneDevices().subscribe((res: IApiResponse<any>) => {
            this.numbers = res.data.devices as KazooCellPhoneDevice[];
            this.numberOptions = this.numbers.map<IOption>(cellphone => {
                return {
                    label: cellphone.device_name,
                    value: cellphone.device_id, selected: false,
                    phone_number: cellphone.phone_number
                };
            });
            let numberSelected = null;
            if (this.isEdit || deviceId) {
                if (this.isOriginal) {
                    numberSelected = this.data.id;
                    this.timeout = this.data.timeout
                } else {
                    numberSelected = this.data.cellPhone.device_id;
                    this.timeout = this.data.cellPhone.timeout
                }
                const num = this.numbers.find(numberItem => numberItem.device_id === numberSelected);
                if (!isNullOrUndefined(num)) {
                    this.selectedCellPhone = num;
                }
                if (deviceId) {
                    numberSelected = deviceId;
                }
                this.form.get('number').setValue(numberSelected);
                this.form.get('timeout').setValue(this.timeout);
            }
            if (!isNullOrUndefined(subscription) && !subscription.closed) {
                subscription.unsubscribe();
            }
        }, error => {
            if (!isNullOrUndefined(subscription) && !subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }

    createCellPhoneDevices() {
        const payload: KazooCellPhoneDeviceRequest = {
            device_name: this.formAddNew.value.nickname,
            phone_number: this.formAddNew.value.follow_me_num,
            country_prefix: this.formAddNew.value.follow_me_country_prefix,
            // follow_me_num: this.formAddNew.value.follow_me_num,
            // follow_me_status: this.followMeStatus,
            require_keypress: this.formAddNew.value.require_keypress,
            call_recording: this.formAddNew.value.allow_recording
        };
        const subscription = this.apiService.createCellPhoneDevices(payload).subscribe((res: IApiResponse<any>) => {
            const response = res.data.data as KazooCreateCellPhoneDeviceResponse;
            this.selectedCellPhone = {
                device_id: response.id,
                kz_owner_id: response.username,
                device_type: response.device_type,
                device_name: response.name,
                phone_number: response.call_forward.number,
                is_active: response.call_forward.enabled ? 1 : 0,
                call_recording: this.formAddNew.value.allow_recording,
                country_flag: this.flag,
                country_prefix: response.country_prefix,
                flags: [],
                require_keypress: response.call_forward.require_keypress
            };
            if (!isNullOrUndefined(this.selectedCellPhone)) {
                if (this.isSubIvr) {
                    this.dataHelper.sendPendingSubIvrData({
                        cellPhone: this.selectedCellPhone,
                        timeout: this.formAddNew.value.timeout,
                        target: this.target,
                        src: this.src,
                        isEdit: this.isEdit,
                        nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                    });
                } else if (this.isIvr && !this.isSubIvr) {
                    this.dataHelper.sendPendingIvrData({
                        cellPhone: this.selectedCellPhone,
                        timeout: this.formAddNew.value.timeout,
                        target: this.target,
                        src: this.src,
                        isEdit: this.isEdit,
                        nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                    });
                } else {
                    this.dataHelper.sendPendingData({
                        cellPhone: this.selectedCellPhone,
                        timeout: this.formAddNew.value.timeout,
                        target: this.target,
                        src: this.src,
                        isEdit: this.isEdit,
                        nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                    });
                }
                this.closeModal(true);
            }
            if (!isNullOrUndefined(subscription) && !subscription.closed) {
                subscription.unsubscribe();
            }
        }, error => {
            if (!isNullOrUndefined(subscription) && !subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }

    private initForm() {
        this.form = this.fb.group({
            number: [null, [Validators.required]],
            timeout: [null, [Validators.required, Validators.min(30), Validators.max(600)]],
        });
        this.formAddNew = this.fb.group({
            nickname: [null, [Validators.required, alphaNumericValidator]],
            // number: [null, [Validators.required]],
            // follow_me_status: [this.followMeStatus],
            follow_me_num: [null, [Validators.required, Validators.minLength(9), Validators.maxLength(13)]],
            follow_me_country_prefix: [null, [Validators.required]],
            timeout: [null, [Validators.required, Validators.min(30), Validators.max(600)]],
            countryId: [null, [Validators.required]],
            require_keypress: [this.require_keypress],
            allow_recording: [this.allow_recording],
        });
        this.form.get('number').valueChanges.subscribe(value => {
            if (!isNullOrUndefined(value)) {
                const num = this.numbers.find(number => number.device_id === value);
                if (!isNullOrUndefined(num)) {
                    this.selectedCellPhone = num;
                }
            }
        });
    }

    toggleAddNew() {
        this.addNew = !this.addNew;
        if (!this.addNew) {
            this.formAddNew.clearValidators();
            this.formAddNew.reset();
            this.flag = null;
            this.followMeStatus = 0;
        }
    }

    onSubmit() {
        if (this.addNew) {
            if (this.formAddNew.valid) {
                this.createCellPhoneDevices();
            } else {
                this.toasterService.warning('Please make sure all data is correct.');
            }
            return;
        }
        if (this.form.invalid) {
            this.toasterService.warning('Please select a team.');
            return;
        }
        if (!isNullOrUndefined(this.selectedCellPhone)) {
            if (this.isSubIvr) {
                this.dataHelper.sendPendingSubIvrData({
                    isEditRoot: this.isEditRoot,
                    cellPhone: this.selectedCellPhone,
                    timeout: this.form.value.timeout,
                    target: this.target,
                    isIvr: this.isIvr,
                    src: this.src,
                    isEdit: this.isEdit,
                    nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                });
            } else if (this.isIvr && !this.isSubIvr) {
                this.dataHelper.sendPendingIvrData({
                    isEditRoot: this.isEditRoot,
                    cellPhone: this.selectedCellPhone,
                    timeout: this.form.value.timeout,
                    target: this.target,
                    src: this.src,
                    isIvr: this.isIvr,
                    isEdit: this.isEdit,
                    nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                });
            } else {
                this.dataHelper.sendPendingData({
                    isEditRoot: this.isEditRoot,
                    cellPhone: this.selectedCellPhone,
                    timeout: this.form.value.timeout,
                    target: this.target,
                    src: this.src,
                    isIvr: this.isIvr,
                    isEdit: this.isEdit,
                    nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                });
            }
            this.closeModal(true);
        }
    }

    closeModal(noChange = false) {
        console.log('noChange');
        this.bsModalRef.hide();

        if (!noChange) {
            this.bsModalService.setDismissReason('close');
        } else {
            this.bsModalService.setDismissReason(MODAL_CLOSE_REASON.CALL_FORWARD);
        }
        this.bsModalRef.hide();
        this.isEdit = null;
        this.isEditRoot = null;
        this.data = null;
        this.isOriginal = null;
        this.isSubIvr = null;
        this.isIvr = null;
        this.src = null;
        this.target = null;
    }

    deleteDevice(device) {

        const initialState = {
            device,
        };
        this.bsModalRef3 = this.bsModalService.show(DeleteCellphoneModalComponent,
            {initialState, class: 'dsk-modal delete-cellphone-modal', ignoreBackdropClick: true}
        );
        this.bsModalRef3.content.deleteEvent.subscribe(response => {
            this.selectedCellPhone = null;
            this.initForm();
            this.getCellPhoneDevices();
        });
    }


    editDevice(device) {
        const initialState = {
            device,
            id: 'edit_device'
        };
        this.bsModalRef2 = this.bsModalService.show(
            EditCellphoneModalComponent,
            {
                initialState,
                class: 'dsk-modal edit-cellphone-modal',
                backdrop: 'static',
            }
        );
        this.bsModalRef2.content.editEvent.subscribe(response => {
            this.selectedCellPhone = {
                device_id: response.device_id,
                kz_owner_id: null,
                device_type: response.device_type,
                device_name: response.device_name,
                phone_number: response.phone_number,
                is_active: response.is_active,
                call_recording: response.call_recording,
                country_flag: response.country_flag,
                country_prefix: response.country_prefix,
                flags: response.flags,
                require_keypress: response.require_keypress
            };
            let deviceId = device.device_id;
            this.numbers = [];
            this.numberOptions = [];
            this.getCellPhoneDevices(deviceId);
        });
    }

    toggleVisibility(e) {
        this.followMeStatusChecked = e.target.checked;

        if (this.followMeStatusChecked) {
            this.followMeStatus = 1;
        } else {
            this.followMeStatus = 0;
        }
    }

    toggleRequire_keypress(e) {
        this.require_keypress = e.target.checked;
        if (this.require_keypress && this.require_keypress === true) {
            this.require_keypress = false;
        } else {
            this.require_keypress = false;
        }
    }

    toggleAllowRecording(e) {
            this.allow_recording = e.target.checked;
            if (this.allow_recording && this.allow_recording === true) {
                this.allow_recording = false;
            } else {
                this.allow_recording = false;
            }
        }


    watchCountryFlag() {
        this.formAddNew.get('countryId').valueChanges.subscribe(value => {
            const selectedCountry = this.countriesOptions.find((o) => o.value === value);
            if (!!selectedCountry) {
                this.flag = selectedCountry.data.flag;
                this.formAddNew.get('follow_me_country_prefix').setValue(selectedCountry.data.country_prefix);
                // this.memberInfo.follow_me_country_prefix = selectedCountry.data.country_prefix;
                // this.memberInfo.follow_me_status = this.followMeStatus;
                // this.setDefaultData(this.memberInfo);
            }
        });

        this.formAddNew.get('follow_me_country_prefix').valueChanges.subscribe(value => {
            const selectedCountry = this.countriesOptions.find((o) => o.data && o.data.country_prefix === `${value}`);
            const currenctCountryValue = this.formAddNew.get('countryId').value;
            if (selectedCountry && !currenctCountryValue) {
                this.formAddNew.get('countryId').setValue(selectedCountry.value);
            }
        });
    }



    ngOnDestroy() {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }

}
