import {isNullOrUndefined} from 'util';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {IOption} from '@app/@core/interfaces';

@Component({
  selector: 'dsk-select-with-selected',
  templateUrl: './select-with-selected.component.html',
  styleUrls: ['./select-with-selected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectAltWithSelectedComponent implements OnInit, OnChanges {

  @ViewChild('popover') popover;
  @Output() changed = new EventEmitter<string | number>();
  @Input() placement = 'bottom';
  @Input() disabled = false;
  @Input() options: IOption[] = [];
  selectedOption: IOption;
  @Input() selectedValue: string | number;
  @Input() isSelected = false;
  @Input() size = 'small';
  @Input() placeholder = '';
  @Input() fontSize = '14';
  @Input() isDarkMode = false;
  @Input() isOrder = false;
  constructor() { }

  ngOnInit() {
    if (this.selectedValue) {
      this.selectedOption = {
        label: this.selectedValue.toString(),
        value: this.selectedValue,
        selected: true
      };
    }
    if (this.options && this.selectedValue) {
      const foundOption = this.options.find(o => o.value.toString() === this.selectedValue.toString());
      if (!isNullOrUndefined(foundOption)) {
        foundOption.selected = true;
      }
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (change && change.options && isNullOrUndefined(change.options.previousValue)) {
      this.options.map(item => item.selected = false);
    }
    if (change && change.options && this.options && !this.isOrder) {
      this.selectedOption = this.options.find(option => option.selected);
    }
    if (change && change.selectedValue && this.selectedOption && !isNullOrUndefined(this.selectedValue)) {
      this.selectedOption = {
        label: this.selectedValue.toString(),
        value: this.selectedValue,
        selected: true
      };
    }
  }

  onClosePopover() {

  }

  openDropdown() {
    if (!this.disabled) {
      this.popover.toggle();
    }
  }

  markOptionAsSelected(selectedOption: IOption) {
    this.options.forEach(o => {
      if (o.value === selectedOption.value) {
        o.selected = true;
      } else {
        o.selected = false;
      }
    });
  }

  onOpenPopover() {
  }

  onSelectOption(option: IOption) {
    this.selectedOption = option;
    this.markOptionAsSelected(option);
    this.popover.close();
    this.changed.emit(option.value);
  }

}
