import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dsk-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  text = "We are building your workspace, Please hold on!";
  @Input() type;
  constructor() { }

  ngOnInit() {
    if(this.type == "replaceNumber"){
      this.text = "We are replacing your number with a new fresh one, hold on!";
    }
  }

}
