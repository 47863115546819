import { isNullOrUndefined } from 'util';
import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddChange, TreeItem } from '@app/@core/interfaces/tree-item.interface';
import { orderBy } from 'lodash';
import {KazooIvrRequest, KazooTimeCondition, KazooVMBoxes} from '@app/@core/interfaces/kz.interface';
import { IMember, IQueue } from '@app/@core/interfaces';
import { ApiService } from './api.service';
import {AuthenticationService} from '@app/_services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class CallFlowService {
    public hideTimeConditionIvrBtns = false;
    public isSelectedTemporalRouteTreeType = false;

    private _addChange: BehaviorSubject<AddChange> = new BehaviorSubject<AddChange>(null);
    public addChanged: Observable<AddChange> = this._addChange.asObservable();

    private _treeChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public treeChanged: Observable<boolean> = this._treeChange.asObservable();

    private _ivrDeletionInsideTimeCondition: BehaviorSubject<TreeItem> = new BehaviorSubject<TreeItem>(null);
    public ivrDeletionInsideTimeCondition: Observable<TreeItem> = this._ivrDeletionInsideTimeCondition.asObservable();

    private _onIvrItemAddClicked: BehaviorSubject<TreeItem> = new BehaviorSubject<TreeItem>(null);
    public onIvrItemAddClicked: Observable<TreeItem> = this._onIvrItemAddClicked.asObservable();

    private _timeConditionSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public timeConditionSelected: Observable<boolean> = this._timeConditionSelected.asObservable();

    private _refreshIvrTree: BehaviorSubject<{ status: boolean, item: TreeItem }> = new BehaviorSubject<{ status: boolean, item: TreeItem }>(null);
    public refreshIvrTreeEvent: Observable<{ status: boolean, item: TreeItem }> = this._refreshIvrTree.asObservable();

    private _editClicked: BehaviorSubject<TreeItem> = new BehaviorSubject<TreeItem>(null);
    public editClicked: Observable<TreeItem> = this._editClicked.asObservable();

    private _editDone: BehaviorSubject<TreeItem> = new BehaviorSubject<TreeItem>(null);
    public editDone: Observable<TreeItem> = this._editDone.asObservable();

    private _editIvrDone: BehaviorSubject<TreeItem> = new BehaviorSubject<TreeItem>(null);
    public editIvrDone: Observable<TreeItem> = this._editIvrDone.asObservable();

    private _teamManageDone: BehaviorSubject<Map<number, number>> = new BehaviorSubject<Map<number, number>>(null);
    public teamManageDone: Observable<Map<number, number>> = this._teamManageDone.asObservable();

    allTeams: KazooTimeCondition[] = [];
    allMembers: IMember[];
    allQueues: IQueue[];
    vmBoxesList: KazooVMBoxes[];

    subIvrToWorkOn: TreeItem[] = [];
    subIvrSelectedIndexToWorkOn: number;
    isProfessionalPlan: boolean;
    showWebhookInAddMenu = true;
    isAdvancedTimeCondition = false;

    constructor(private api: ApiService, private auth: AuthenticationService) {
        this.getCurrentUser();
    }

    private getCurrentUser() {
        this.auth.asyncLocalStorage.getItem('currentUser').then(val => {
            if (!isNullOrUndefined(val)) {
                const currentUser = JSON.parse(val);
                this.isProfessionalPlan = currentUser.plan_name === 'Professional';
            }
        });
    }

    addChange(type: any, target: any, src: string) {
        const changeData: AddChange = { type, target, src };
        this._addChange.next(changeData);
    }

    timeConditionChange(value: boolean, isSelectedTemporalRouteTreeType:boolean) {
        this.hideTimeConditionIvrBtns = value;
        this.isSelectedTemporalRouteTreeType = isSelectedTemporalRouteTreeType;
        this._timeConditionSelected.next(value);
    }

    sendEditClickd(value: TreeItem) {
        this._editClicked.next(value);
    }

    sendEditDone(value: TreeItem) {
        this._editDone.next(value);
    }

    sendEditIvrDone(value: TreeItem) {
        this._editIvrDone.next(value);
    }

    ivrDeletedInsideTimeCondition(value: TreeItem) {
        this._ivrDeletionInsideTimeCondition.next(value);
    }

    sendTreeChange(value: boolean) {
        this._treeChange.next(value);
    }

    sendRefreshIvrTree(status: boolean, item: TreeItem) {
        this._refreshIvrTree.next({ status, item });
    }

    teamManageChange(value: Map<number, number>) {
        this._teamManageDone.next(value);
    }

    ivrItemAddClicked(treeItem: TreeItem) {
        this._onIvrItemAddClicked.next(treeItem);
    }

    saveIvr(ivrData){
        const ivr:KazooIvrRequest ={
            data : {
                name: ivrData.name,
                timeout: ivrData.timeout,
                interdigit_timeout: ivrData.interdigit_timeout,
                max_extension_length: 1,
                retries: Number(ivrData.retries) + 1,
                media: {greeting:ivrData.media.id},
                hunt: false,
                allow_record_from_offnet: true,
                suppress_media: false,
            }
        };
        if (!isNullOrUndefined(ivrData.id)) {
            ivr.data.id = ivrData.id;
            return this.api.updateIvr(ivr,ivrData.id);
        }
        return this.api.createIvr(ivr);
    }

    prepareDataToSend(treeData: TreeItem) {
        let object;
        if (isNullOrUndefined(treeData.data)) {
            return this.prepareDataNoChange(treeData);
        }
        // return null;
        switch (treeData.type) {
            case 'user':
                object = {
                    data: {
                        id: treeData.data.member.kz_member_uuid,
                        timeout: treeData.data.keepRingingFor,
                        can_call_self: false,
                        delay: 0,
                        strategy: 'simultaneous'
                    },
                    module: 'user'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;
                // todo check the queue_uuid
            case 'acdc_member':
            case 'queue':
                console.log('GOT QUEUE');
                object = {
                    data: {
                        id: treeData.data.queue.id,
                        is_queue: true,
                    },
                    module: 'device'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;
            case 'temporal_route':
                object = {
                    data: {
                        timezone: !isNullOrUndefined(treeData.data) && !isNullOrUndefined(treeData.data.timeCondition) &&
                            !treeData.isAdvancedTimeCondition ? treeData.data.timeCondition.gmtzone :
                            (!isNullOrUndefined(treeData.data) && treeData.isAdvancedTimeCondition ? treeData.data.timezone : null),
                        name: !isNullOrUndefined(treeData.data) && !isNullOrUndefined(treeData.data.timeCondition) ?
                            treeData.data.timeCondition.name : null,
                        data: {
                            id: !isNullOrUndefined(treeData.data) && !isNullOrUndefined(treeData.data.timeCondition) ?
                                treeData.data.timeCondition.kz_business_hour_set_uuid : null,
                            kz_business_hour_uuid: !isNullOrUndefined(treeData.data) && !isNullOrUndefined(treeData.data.timeCondition) ?
                                treeData.data.timeCondition.kz_business_hour_uuid : null,
                            isCompanyWorkingHours: !isNullOrUndefined(treeData.data) && !isNullOrUndefined(treeData.data.timeCondition) ?
                                treeData.data.chooseCompanyWorkingHours : null
                        },
                        isAdvancedTimeCondition: treeData.isAdvancedTimeCondition
                        // rules: []
                    },
                    module: 'temporal_route'
                };
                if (treeData.isAdvancedTimeCondition) {
                    delete object.data.name;
                    delete object.data.data;
                    delete object.data.isCompanyWorkingHours;
                }
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    if (!treeData.isAdvancedTimeCondition) {
                        object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                    }
                    if (treeData.isAdvancedTimeCondition) {
                        for (let i = 0; i < treeData.children.length; i++) {
                            const child = treeData.children[i];
                            object.children[(child.ivrActionNumber as string)] = this.prepareDataToSend(child);
                            if (
                                !isNullOrUndefined(object.children[(child.ivrActionNumber as string)]) &&
                                JSON.stringify(object.children[(child.ivrActionNumber as string)].children) === '{}'
                            ) {
                                delete object.children[(child.ivrActionNumber as string)].children;
                            }
                        }
                    }
                }
                if (!isNullOrUndefined(treeData.children1) && treeData.children1.length > 0) {
                    if (!object.children) {
                        object.children = {};
                    }
                    object.children[treeData.data.timeCondition.kz_business_hour_set_uuid] = this.prepareDataToSend(treeData.children1[0]);
                    if (JSON.stringify(object.children[treeData.data.timeCondition.kz_business_hour_set_uuid].children) === '{}') {
                        delete object.children[treeData.data.timeCondition.kz_business_hour_set_uuid].children;
                    }
                }
                return object;
            case 'menu':
                object = {
                    data: !isNullOrUndefined(treeData.originalData) ?
                        { ...treeData.originalData } :
                        {
                            id: treeData.data.id,
                            timeout: treeData.data.timeout,
                            interdigit_timeout: treeData.data.interdigit_timeout,
                            retries: treeData.data.retries,
                            name: treeData.data.name,
                            media: {
                                exit_media: true,
                                greeting: !isNullOrUndefined(treeData.data.media.id) ? treeData.data.media.id : treeData.data.media.greeting,
                                invalid_media: true,
                                transfer_media: true
                            },
                        },
                    module: 'menu'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    for (let i = 0; i < treeData.children.length; i++) {
                        const child = treeData.children[i];
                        object.children[(child.ivrActionNumber as string)] = this.prepareDataToSend(child);
                    }
                }
                return object;
            case 'play':
                object = {
                    data: {
                        id: treeData.data.media.id,
                        endless_playback: false,
                        terminators: [
                            '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'
                        ]
                    },
                    module: 'play'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;
            case 'device':
                console.log('GOT DEVICE');
                object = {
                    data: {
                        id: treeData.data.cellPhone.device_id,
                        timeout: Number(treeData.data.timeout),
                        phone_number: treeData.data.cellPhone.phone_number,
                        can_call_self: false,
                        delay: 0,
                        dial_strategy: 'simultaneous'
                    },
                    module: 'device'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;
            case 'ring_group':
                const timeOut = treeData.data.teamData.ringStrategy === 'single' ?
                    (treeData.data.members as any[]).length * treeData.data.teamData.seconds : treeData.data.teamData.seconds;
                const endPoints = [];
                // [0].data.members[0].kz_member_uuid
                treeData.data.members = orderBy(treeData.data.members, ['order'], 'asc');
                for (let i = 0; i < treeData.data.members.length; i++) {
                    const element = treeData.data.members[i];
                    endPoints.push({
                        endpoint_type: 'user',
                        id: element.kz_member_uuid,
                        delay: 0,
                        timeout: treeData.data.teamData.seconds
                    });
                }
                object = {
                    data: {
                        name: treeData.data.team,
                        endpoints: endPoints,
                        strategy: treeData.data.teamData.ringStrategy,
                        timeout: timeOut,
                        repeats: treeData.data.teamData.repeat,
                        // repeats: treeData.data.teamData.ringStrategy === 'single' ?  (treeData.data.members as any[]).length : 1,
                        ignore_forward: true
                    },
                    module: 'ring_group'
                };
                if (treeData.data.media) {
                    object.data.ringback = treeData.data.media.id;
                }
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;
            case 'voicemail':
                object = {
                    data: {
                        id: treeData.data.vmBox.id,
                        action: 'compose',
                        callerid_match_login: false,
                        interdigit_timeout: 2000,
                        max_message_length: 500,
                        single_mailbox_login: false
                    },
                    module: 'voicemail'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;

            case 'webhook':
                object = {
                    data: {
                        custom_data: treeData.data.custom_data,
                        format: 'json',
                        http_verb: 'post',
                        retries: 4,
                        uri: 'http://router.talkchief.io/callflow_webhook/send',
                    },
                    module: 'webhook'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;
            case 'callflow' :
                console.log('11111', treeData.data);
                object = {
                    data: {
                        id: treeData.data.id,
                    },
                    module: 'callflow'
                };
                // object.children = {};
                return object;
            default:
                break;
        }

    }

    prepareDataNoChange(treeData: TreeItem) {
        console.log('prepareDataNoChange', treeData);
        let object;
        switch (treeData.type) {
            case 'user':
                object = {
                    data: {
                        id: treeData.originalData.id,
                        timeout: treeData.originalData.timeout,
                        can_call_self: false,
                        delay: 0,
                        strategy: 'simultaneous'
                    },
                    module: 'user'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;
            case 'acdc_member':
            case 'queue':
                console.log('GOT QUEUE');
                object = {
                    data: {
                        id: treeData.originalData.id,
                        is_queue: true,
                    },
                    module: 'device'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;
            case 'temporal_route':
                object = {
                    data: {
                        timezone: !isNullOrUndefined(treeData.originalData) ? treeData.originalData.timezone : null,
                        name: !isNullOrUndefined(treeData.originalData) ? treeData.originalData.name : null,
                        data: {
                            id: !isNullOrUndefined(treeData.originalData) ? treeData.originalData.id : null,
                            kz_business_hour_uuid: !isNullOrUndefined(treeData.originalData) ?
                                treeData.originalData.kz_business_hour_uuid : null,
                            isCompanyWorkingHours: !isNullOrUndefined(treeData.originalData) ?
                                treeData.originalData.isCompanyWorkingHours : null
                        },
                        isAdvancedTimeCondition: treeData.isAdvancedTimeCondition
                        // rules: []
                    },
                    module: 'temporal_route'
                };
                if (treeData.isAdvancedTimeCondition) {
                    delete object.data.name;
                    delete object.data.data;
                    delete object.data.isCompanyWorkingHours;
                }
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    if (!treeData.isAdvancedTimeCondition) {
                        object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                    }
                    if (treeData.isAdvancedTimeCondition) {
                        for (let i = 0; i < treeData.children.length; i++) {
                            const child = treeData.children[i];
                            object.children[(child.ivrActionNumber as string)] = this.prepareDataToSend(child);
                            if (
                                !isNullOrUndefined(object.children[(child.ivrActionNumber as string)]) &&
                                JSON.stringify(object.children[(child.ivrActionNumber as string)].children) === '{}'
                            ) {
                                delete object.children[(child.ivrActionNumber as string)].children;
                            }
                        }
                    }
                }
                if (!isNullOrUndefined(treeData.children1) && treeData.children1.length > 0) {
                    if (!object.children) {
                        object.children = {};
                    }
                    if (!isNullOrUndefined(treeData.originalData.kz_business_hour_uuid)) {
                        object.children[treeData.originalData.kz_business_hour_uuid] = this.prepareDataToSend(treeData.children1[0]);
                    }
                }
                return object;
            case 'menu':
                object = {
                    data: {
                        ...treeData.originalData
                    },
                    module: 'menu'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    for (let i = 0; i < treeData.children.length; i++) {
                        const child = treeData.children[i];
                        object.children[(child.ivrActionNumber as string)] = this.prepareDataToSend(child);
                    }
                }
                return object;
            case 'play':
                object = {
                    data: {
                        id: treeData.originalData.id,
                        endless_playback: false,
                        terminators: [
                            '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'
                        ]
                    },
                    module: 'play'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;
            case 'device':
                console.log('GOT DEVICE');
                object = {
                    data: {
                        id: treeData.originalData.id,
                        phone_number: treeData.originalData.phone_number,
                        timeout: treeData.originalData.timeout,
                        can_call_self: false,
                        delay: 0,
                        dial_strategy: 'simultaneous'
                    },
                    module: 'device'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;
            case 'ring_group':
                object = {
                    data: {
                        name: treeData.originalData.name,
                        endpoints: treeData.originalData.endpoints,
                        strategy: treeData.originalData.strategy,
                        timeout: treeData.originalData.timeout,
                        repeats: treeData.originalData.repeats,
                        ignore_forward: true
                    },
                    module: 'ring_group'
                };
                if (treeData.originalData.ringback) {
                    object.data.ringback = treeData.originalData.ringback;
                }
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;
            case 'voicemail':
                object = {
                    data: {
                        id: treeData.originalData.id,
                        action: 'compose',
                        callerid_match_login: false,
                        interdigit_timeout: 2000,
                        max_message_length: 500,
                        single_mailbox_login: false
                    },
                    module: 'voicemail'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;
            case 'webhook':
                object = {
                    data: {
                        custom_data: treeData.originalData.custom_data,
                        format: 'json',
                        http_verb: 'post',
                        retries: 4,
                        uri: 'http://router.talkchief.io/callflow_webhook/send',
                    },
                    module: 'webhook'
                };
                if (!isNullOrUndefined(treeData.children) && treeData.children.length > 0) {
                    object.children = {};
                    object.children['_'] = this.prepareDataToSend(treeData.children[0]);
                }
                return object;
            case 'callflow' :
                console.log('22222', treeData.originalData);
                object = {
                    data: {
                        id: treeData.originalData.id,
                    },
                    module: 'callflow'
                };
                // object.children = {};
                return object;
            default:
                break;
        }
    }
}
