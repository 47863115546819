import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loading = new BehaviorSubject<boolean>(false);
  preventStopLoaderObs = new BehaviorSubject<boolean>(true);
  preventStopLoader = true;
  constructor() {
    this.preventStopLoaderObs.asObservable().subscribe(loading => this.preventStopLoader = loading);
  }

  startLoading() {
    this.loading.next(true);
  }

  stopLoading() {
    if (this.preventStopLoader) {
      this.loading.next(false);
    }
  }

  startPreventStopLoader() {
    this.preventStopLoaderObs.next(false);
  }

  stopPreventStopLoader() {
    this.preventStopLoaderObs.next(true);
  }

  getLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

}
