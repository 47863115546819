import { IMember } from './../../../../../@core/interfaces/member.interface';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@app/_services/api.service';
import { take, finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IOption } from '@app/@core/interfaces';
import { isNullOrUndefined } from 'util';
import { DataHelperService } from '@app/_services/data.helper.service';
import { MODAL_CLOSE_REASON } from '@app/variables/constants';
import { KazooVMBoxes } from '@app/@core/interfaces/kz.interface';

@Component({
    selector: 'app-webhooks',
    templateUrl: './webhooks.component.html',
    styleUrls: ['./webhooks.component.scss']
})
export class WebhooksComponent implements OnInit, OnDestroy {
    @Input() isEdit = false;
    @Input() isEditRoot = false;
    @Input() data;
    @Input() isOriginal;
    @Input() isSubIvr = false;
    @Input() isIvr = false;
    @Input() src;
    @Input() target;
    @Input() popupTitle = 'Webhooks';
    vmBoxesOptions: IOption[] = [];
    form: FormGroup;
    loading = false;
    subscription$: Subscription;
    controls: { value: string, showDelete: boolean }[] = [];

    constructor(
        private fb: FormBuilder,
        private apiService: ApiService,
        private toasterService: ToastrService,
        public bsModalRef: BsModalRef,
        private bsModalService: BsModalService,
        private dataHelper: DataHelperService
    ) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm() {
        this.form = this.fb.group({
            destination_url: [
                (
                    isNullOrUndefined(this.data) ? null :
                    isNullOrUndefined(this.data.custom_data) ? null :
                        this.data.custom_data.destination_url
                ),
                Validators.compose([
                    Validators.required,
                    Validators.pattern(/^https:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]+)?\/?$/)
                ]
            )]
        });
        if (this.isEdit) {
            this.getFormControls(this.data.custom_data);
        }
    }

    getFormControls(customData) {
        const keys = Object.keys(customData).filter(item => item != 'destination_url');
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            this.addNewFormControl(key, customData);

        }
    }

    addNewFormControl(key?: string, customData?: any) {
        const lastItemsCount = (this.controls.length / 2).toString();
        this.form.addControl(lastItemsCount,
            new FormControl(
                isNullOrUndefined(customData) ? null : key , Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]+(_[a-zA-Z0-9]+)*$')])
            )
        );
        this.controls.push({value: lastItemsCount, showDelete: false});
        this.form.addControl(`${lastItemsCount+'_value'}`,
            new FormControl(
                isNullOrUndefined(customData) ? null : customData[key] , Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]+([ ]+[a-zA-Z0-9]+)*[ ]*$')])
            )
        );
        this.controls.push({ value: `${lastItemsCount + '_value'}`, showDelete: false});
    }

    onSubmit() {
        if (this.form.invalid) {
            this.toasterService.warning('Please select a voicemail.');
            return;
        }
        let customData: any = {};
        const keys = Object.keys(this.form.value).filter(item => item != 'destination_url');
        for (let i = 0; i < keys.length; i = i+2) {
            const key = keys[i];
            const key2 = keys[i+1];
            customData[this.form.value[key]] = this.form.value[key2];
        }
        customData['destination_url'] = this.form.value['destination_url'];
        if (this.isSubIvr) {
            this.dataHelper.sendPendingSubIvrData({
                isEditRoot: this.isEditRoot,
                target: this.target,
                src: this.src,
                isIvr: this.isIvr,
                isEdit: this.isEdit,
                nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1,
                custom_data: customData
            });
        } else if (this.isIvr && !this.isSubIvr) {
            this.dataHelper.sendPendingIvrData({
                isEditRoot: this.isEditRoot,
                target: this.target,
                src: this.src,
                isIvr: this.isIvr,
                isEdit: this.isEdit,
                nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1,
                custom_data: customData
            });
        } else {
            this.dataHelper.sendPendingData({
                isEditRoot: this.isEditRoot,
                target: this.target,
                src: this.src,
                isIvr: this.isIvr,
                isEdit: this.isEdit,
                nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1,
                custom_data: customData
            });
        }
        this.closeModal(true);
    }

    closeModal(noChange = false) {
        if (!noChange) {
            this.bsModalService.setDismissReason('close');
        } else {
            this.bsModalService.setDismissReason(MODAL_CLOSE_REASON.WEBHOOK);
        }
        this.bsModalRef.hide();
        this.isEdit = null;
        this.isEditRoot = null;
        this.data = null;
        this.isOriginal = null;
        this.isSubIvr = null;
        this.isIvr = null;
        this.src = null;
        this.target = null;
    }

    ngOnDestroy() {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }

    deleteRow(controlNumber: string) {
        const i = Number(controlNumber.split('_')[0]);
        const index1 = this.controls.findIndex(item => item.value === controlNumber.split('_')[0]);
        this.controls.splice(index1, 1);
        const index2 = this.controls.findIndex(item => item.value === controlNumber);
        this.controls.splice(index2, 1);
        if (!isNullOrUndefined(this.form.get(controlNumber.split('_')[0]))) {
            this.form.get(controlNumber.split('_')[0].toString()).setValue(null);
            this.form.get(controlNumber).setValue(null);

            this.form.get(controlNumber.split('_')[0].toString()).updateValueAndValidity();
            this.form.get(controlNumber).updateValueAndValidity();

            setTimeout(()=> {
                this.form.removeControl(controlNumber.split('_')[0].toString());
                this.form.removeControl(controlNumber);
            }, 150)
        }
    }

    onMouseEnter(controlNumber: string) {
        let rowIndex;
        if (controlNumber.search('_value') > -1) {
            rowIndex = this.controls.findIndex(item => item.value === controlNumber);
        } else {
            rowIndex = this.controls.findIndex(item => item.value === `${controlNumber}_value`);
        }
        this.controls[rowIndex].showDelete = true;
    }

    onMouseLeave(controlNumber: string) {
        let rowIndex;
        if (controlNumber.search('_value') > -1) {
            rowIndex = this.controls.findIndex(item => item.value === controlNumber);
        } else {
            rowIndex = this.controls.findIndex(item => item.value === `${controlNumber}_value`);
        }
        this.controls[rowIndex].showDelete = false;
    }
}
