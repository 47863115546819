import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'dsk-media-uploader',
    templateUrl: './media-uploader.component.html',
    styleUrls: ['./media-uploader.component.scss']
})
export class MediaUploaderComponent implements OnInit {

    @Input() control: FormControl;
    @Input() placeholder: string;
    @Input() key: string;
    @Input() progress: string;
    @Input() fullWidth = false;
    @Output() uploaded: EventEmitter<File> = new EventEmitter<File>();
    @Input() enable = true;
    @Input() maxSize = 2;
    @Input() btnTitle = 'Select File';
    // @Input() accept = 'audio/*';
    @Input() accept = '.mp3';

    get isUploading() {
        return this.progress.includes('uploading');
        // return true;
    }
    submitted = false;
    file: File = null;

    constructor(private toasterService: ToastrService) {

        if (this.progress === 'invlaid') {
            this.removeFile();
        }

    }

    ngOnInit() {
    }

    onFileChange(event) {
        const files: FileList = event.target.files;
        this.file = files.item(0);
        this.uploaded.emit(this.file);
    }

    onFileClicked(event) {
        if (!this.enable) {
            event.preventDefault();
            this.toasterService.warning('First please choose a document type to able to upload a document.');
        }
    }

    onClickUpload(fileInput) {
        fileInput.click();
    }

    removeFile() {
        this.progress = null;
        this.file = null;
        this.control.setValue(null);
    }
}
