import {Component, OnInit, Input, OnDestroy, Output, EventEmitter, HostListener, OnChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl, FormArray} from '@angular/forms';
import {ApiService} from '@app/_services/api.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {combineLatest, Subscription, of} from 'rxjs';
import {take, finalize, map, catchError} from 'rxjs/operators';
import {IOption, IAvailableDIDRequest, IAvailableDID, IUploadDocument} from '@app/@core/interfaces';
import {OptionService} from '@app/_services/option.service';
import {cloneDeep} from 'lodash';
import {HttpEventType, HttpErrorResponse} from '@angular/common/http';
import {IPurchaseDID} from '@app/@core/interfaces/api.interface';
import {Router} from '@angular/router';

@Component({
    selector: 'app-buy-number-modal',
    templateUrl: './buy-number-modal.component.html',
    styleUrls: ['./buy-number-modal.component.scss']
})
export class BuyNumberModalComponent implements OnInit, OnDestroy {

    @Input() teamId: number;
    loading = false;
    ready = false;
    subscription$: Subscription;
    searchValue: string;
    form: FormGroup;
    flag;
    defaultFlag = '/assets/img/icons/common/flag_alt.png';
    options: Map<string, IOption[]> = new Map();
    isStateEnabled = false;
    selectedCountry: string;
    selectedCity: string;
    selectedNumberType: string;
    noNumberAvailable = false;
    percent = 16;
    step = 1;
    availableDID: IAvailableDID;

    selectedAssignedTeams: string[] = [];
    onlyCountryField = false;
    @Output() refresh = new EventEmitter<boolean>();


    constructor(private fb: FormBuilder,
                private apiService: ApiService,
                private toasterService: ToastrService,
                public bsModalRef: BsModalRef,
                private optionService: OptionService,
                private bsModalService: BsModalService,
                private router: Router) {
        this.initData();
        this.initForm();
        this.watchForm();
    }

    ngOnInit() {
    }

    private initForm() {
        this.form = this.fb.group({
            firstStep: this.fb.group({
                did_type: [null, [Validators.required]],
                country_id: [null, [Validators.required]],
                state_id: [null],
                city_id: [null],
                city_name: [null],
                company_name: [null, [Validators.required]],
                did_id: [null],
                sku_id: [null],
                did: [null],
                country_prefix: [null],
            }),
            secondStep: this.fb.group({
                // fullname: [null, [Validators.required]],
                full_name: [null],
                phone_number: [null],
                // phone_number: [null, [Validators.required]],
            }),
            documents: this.fb.array([]),
            thirdStep: this.fb.group({
                team_id: [null],
                did_name: [null, [Validators.required]],
            })
        });
    }

    get getAttachmentFormGroup() {
        return new FormGroup({
            document_id: new FormControl('document_id_1', Validators.required),
            doc: new FormControl(null, Validators.required),
            document_progress: new FormControl(null),
        });
    }

    private initData() {
        combineLatest(
            this.apiService.getDIDType(),
            this.apiService.getDIDCountries(),
            this.apiService.callGetOnBoardingEndpoint(),
            this.apiService.callGetDIDDocumentsTypes(),
            this.apiService.callGetTeams()
        ).pipe(take(1))
            .subscribe(([didTypesResponse, didCountriesResponse, onBoardingResponse, didDocumentTypesResponse, teamsResponse]
                            : [any, any, any, any, any]) => {
                if (didTypesResponse.data) {
                    const types = this.optionService.generateIOption(didTypesResponse.data, 'did_type_id', 'did_type');
                    this.options.set('didType', types);
                }
                if (didCountriesResponse.data) {
                    const countries = this.optionService.generateIOption(didCountriesResponse.data, 'country_id', 'country_name');
                    this.options.set('country', countries);
                }
                if (onBoardingResponse.data) {
                    this.f1.company_name.setValue(onBoardingResponse.data.company_name);
                }
                if (didDocumentTypesResponse.data) {
                    const documentTypes = this.optionService.generateIOption(didDocumentTypesResponse.data, 'id', 'document_type');
                    this.options.set('documentType', documentTypes);
                }
                if (teamsResponse.success) {
                    const teams = this.optionService.generateIOption(teamsResponse.data.teams, 'team_id', 'team_name');
                    this.options.set('teams', teams);
                }
            });
    }

    onGetAvailableDid() {
        if (this.form.get('firstStep').invalid) {
            this.toasterService.warning('Please enter all required fields.');
        }
        this.clearAvailableDidData();
        this.loading = true;
        const payload = this.preparedAvaliabeDIdData();

        this.apiService.getAvailableDID(payload)
            .pipe(
                finalize(() => {
                    this.loading = false;
                })
            ).subscribe((response: any) => {
            if (response == null) {
                this.toasterService.warning('Something went wrong! please contact the admin.');
                return;
            }
            if (response.success) {
                this.noNumberAvailable = false;
                this.availableDID = response.data;
                this.setAvailableDidData(this.availableDID);


                this.goNext(!this.availableDID.needs_registration);


                if (this.availableDID.needs_registration) {
                    this.addAnotherDocument();
                }
            } else {
                if (response.error_code === 408) {
                    this.noNumberAvailable = true;
                } else {
                    this.toasterService.warning(response.message);
                }
            }
        }, (err) => {
            this.toasterService.error(err.error.message);
        });
    }

    onSubmit() {
        this.loading = true;
        const DIDPayload = this.getPreparedDIDPayload();
        this.subscription$ = this.apiService.callPurchaseDID(DIDPayload)
            .pipe(
                finalize(() => {
                    this.loading = false;
                }),
            ).subscribe((response: any) => {
                if (response.success) {
                    this.toasterService.success('Success');
                    this.closeModal();
                    // this.router.navigate(['/numbers']);

                } else {
                    this.toasterService.warning(response.message);
                }
            }, (err) => {
                this.toasterService.error(err.error.message);
            });

    }

    getPreparedDIDPayload(): IPurchaseDID {
        const teamId = this.f3.team_id.value;
        const payload: IPurchaseDID = {
            did_id: this.f1.did_id.value,
            did_name: this.f3.did_name.value,
            full_name: this.f2.full_name.value,
            phone_number: this.f2.phone_number.value,
            sku_id: this.f1.sku_id.value,
            team_id: teamId,
            source: 'inside'
        };
        return payload;
    }

    setAvailableDidData(data: IAvailableDID) {
        let formattedDid = data.did;
        const country_prefix = this.f1.country_prefix.value;
        if (data.did) {
            formattedDid = data.did.replace(country_prefix, '');
        }
        this.f1.did.setValue(formattedDid);
        this.f1.did_id.setValue(data.did_id);
        this.f1.sku_id.setValue(data.sku_id);
    }

    clearAvailableDidData() {
        const data: IAvailableDID = {
            did_id: null,
            did: null,
            sku_id: null,
            deskwaves_monthly_price: null,
            deskwaves_setup_price: null,
            needs_registration: null,
            restrictions: null,
            source: 'inside'
        };
        this.setAvailableDidData(data);
    }

    preparedAvaliabeDIdData(): IAvailableDIDRequest {
        const country_id = this.f1.country_id.value;
        const selectedCountry = this.options.get('country').find((o) => o.value === country_id);
        const country_name = selectedCountry.label;
        const country_iso = selectedCountry.data.country_iso;
        const city_id = this.f1.city_id.value;
        const city_name = this.f1.city_name.value;
        const type_id = this.f1.did_type.value;
        const company_id = this.f1.company_name.value;
        const selectedDidType = this.options.get('didType').find((o) => o.value === type_id);
        const type_name = selectedDidType.label;
        const source = 'inside';
        const payload: IAvailableDIDRequest = {
            country_id,
            country_iso,
            country_name,
            city_id,
            city_name,
            type_id,
            type_name,
            company_id,
            source
        };
        return payload;
    }

    uploadDocument(file: File, attachmentGroup: FormGroup, index) {
        if (attachmentGroup.invalid) {
            this.toasterService.warning('Please fill all required fields.');
            return;
        }


        const name = file.name;
        const type = file.type;
        const size = file.size;
        const sizeInMB = Math.round(size / 1024);
        console.log(sizeInMB);
        if (sizeInMB > 2000) {
            this.toasterService.warning('The attachment file exceeds maximum size limit (2 MB).');
            attachmentGroup.get('doc').setValue(null);
            attachmentGroup.get('document_progress').setValue('invlaid');
            return of(`${file.name} upload failed.`);
        }


        const payload: IUploadDocument = {
            doc: file,
            did_number: this.f1.country_prefix.value + this.f1.did.value,
            document_id: attachmentGroup.get('document_id').value,
        };
        this.apiService.callUploadDocument(payload).pipe(
            map(event => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        attachmentGroup.get('document_progress').setValue('uploading');
                        break;
                    case HttpEventType.Response:
                        return event;
                }
            }),
            catchError((error: HttpErrorResponse) => {
                attachmentGroup.get('document_progress').setValue('fail');
                return of(`${file.name} upload failed.`);
            })
        ).subscribe((event: any) => {
            console.log('event upload response', event);
            if (typeof (event) === 'object') {
                console.log(event.body);
                attachmentGroup.get('document_progress').setValue('uploaded');
            } else {
                if (event !== undefined && event.success === false) {
                    attachmentGroup.get('document_progress').setValue('fail');
                    return of(`${file.name} upload failed.`);
                }
            }
        }, (err) => {
            console.log('erro', err);
            // this.errors = JSON.parse(JSON.stringify(response.errors));
            attachmentGroup.get('document_progress').setValue('fail');
            return of(`${file.name} upload failed.`);
            // this.toasterService.error(err.error.message);
        });
    }

    get generateAttachmentFormGroup() {
        return cloneDeep(this.getAttachmentFormGroup);
    }

    addAnotherDocument() {
        (<FormArray>this.form.get('documents')).push(this.generateAttachmentFormGroup);
    }

    removeDocument(index: number) {
        (<FormArray>this.form.get('documents')).removeAt(index);
    }

    goNext(skipSecondStep = false) {
        if (this.step === 1) {
            if (this.form.get('firstStep').valid) {
                if (skipSecondStep) {
                    this.step = 3;
                } else {
                    this.step = 2;
                }
            }
        } else if (this.step === 2) {
            if (this.form.get('secondStep').valid) {
                this.step = 3;
            }
        }
        this.updatePercent();
    }

    updatePercent() {
        switch (this.step) {
            case 1:
                this.percent = 16;
                break;
            case 2:
                this.percent = 66;
                break;
            case 3:
                this.percent = 97;
                break;
            default:
                this.percent = 100;
                break;
        }
    }

    watchForm() {
        this.f1.country_id.valueChanges.subscribe(value => {
            const selectedCountry = this.options.get('country').find((o) => o.value === value);
            if (selectedCountry) {
                this.selectedCountry = selectedCountry.label;
                this.f1.country_prefix.setValue(selectedCountry.data.country_prefix);
                this.flag = selectedCountry.data.flag;
                this.isStateEnabled = selectedCountry.data.country_iso === 'US' || selectedCountry.data.country_iso === 'CA';
                if (this.isStateEnabled) {
                    this.callGetState();
                } else {
                    this.callGetCity();
                }
            }
        });

        this.f1.state_id.valueChanges.subscribe(value => {
            const selectedstate = this.options.get('state').find((o) => o.value === value);
            if (selectedstate) {
                this.callGetCity();
            }
        });

        this.f1.city_id.valueChanges.subscribe(value => {
            const selectedCity = this.options.get('city').find((o) => o.value === value);
            if (selectedCity) {
                this.selectedCity = selectedCity.label;
                this.f1.city_name.setValue(selectedCity.data.city_name);
            }
        });

        this.f1.did_type.valueChanges.subscribe(value => {
            const selectedDidType = this.options.get('didType').find((o) => o.value === value);
            if (selectedDidType) {
                this.selectedNumberType = selectedDidType.label;
                let validators = null;
                if (!this.selectedNumberType.includes('Local')) {
                    this.onlyCountryField = true;
                    validators = null;
                } else {
                    this.onlyCountryField = false;
                    validators = Validators.required;
                }
                this.f1.city_id.setValidators(validators);
                this.f1.city_id.updateValueAndValidity({
                    emitEvent: false,
                });

                // let teamValidators = null;
                // if (this.selectedNumberType.includes('Toll-free')) {
                //     teamValidators = null;
                // } else {
                //     teamValidators = Validators.required;
                // }
                const teamValidators = Validators.required;
                this.f3.team_id.setValidators(teamValidators);
                this.f3.team_id.updateValueAndValidity({
                    emitEvent: false,
                });
            }
        });

        this.f3.team_id.valueChanges.subscribe(value => {
            const selectedTeam = this.options.get('teams').find((o) => o.value === value);
            if (selectedTeam) {
                this.selectedAssignedTeams = [selectedTeam.label];
            }
        });
    }

    callGetState() {
        this.options.set('state', []);
        this.options.set('city', []);
        this.f1.state_id.setValue(null);
        this.f1.city_id.setValue(null);
        this.f1.city_name.setValue(null);
        const country_id = this.f1.country_id.value;
        this.apiService.getDIDStates(country_id).subscribe((response: any) => {
            if (response.success) {
                const states = this.optionService.generateIOption(response.data, 'state_id', 'state_name');
                this.options.set('state', states);
            } else {
                this.toasterService.warning(response.message);
            }
        }, (err) => {
            this.toasterService.error(err.error.message);
        });
    }

    callGetCity() {
        this.options.set('city', []);
        this.f1.city_id.setValue(null);
        this.f1.city_name.setValue(null);
        const country_id = this.f1.country_id.value;
        const state_id = this.f1.state_id.value;
        const selectedCountry = this.options.get('country').find((o) => o.value === country_id);
        const countryName = selectedCountry.label;
        this.apiService.getDIDCities(country_id, countryName, state_id).subscribe((response: any) => {
            if (response.success) {
                const cities = this.optionService.generateIOption(response.data, 'city_id', 'city_name');
                this.options.set('city', cities);
            } else {
                // this.toasterService.warning(response.message);
            }
        }, (err) => {
            this.toasterService.error(err.error.message);
        });
    }

    closeModal(noChange = false) {
        if (noChange) {
            this.bsModalService.setDismissReason('close');
        }
        this.bsModalRef.hide();
    }

    get documentTypeOptions() {
        return this.options.get('documentType');
    }

    get f1() {
        return (<FormGroup>this.form.get('firstStep')).controls;
    }

    get f2() {
        return (<FormGroup>this.form.get('secondStep')).controls;
    }

    get f3() {
        return (<FormGroup>this.form.get('thirdStep')).controls;
    }

    get attachmentFormGroups(): FormGroup[] {
        return (<FormArray>this.form.get('documents')).controls as FormGroup[];
    }

    get getFlag() {
        return this.flag || this.defaultFlag;
    }

    // close() {
    //     this.router.navigate(['/numbers']);
    // }


    private subscribeCloseModal() {
        this.bsModalService.onHide.subscribe((reason: string) => {
            console.log(reason);
            if (reason === null) {
                this.refresh.emit(true);
            }
        });
    }


    ngOnDestroy() {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }
}
