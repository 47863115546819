import { Component, OnInit, Input, OnDestroy, ElementRef, ViewChild, OnChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-play-media',
  templateUrl: './play-media.component.html',
  styleUrls: ['./play-media.component.scss']
})
export class PlayMediaComponent implements OnInit, OnChanges {
  @ViewChild('player') player: ElementRef;
  @Input() src;
  isPlaying = false;
  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
  ) { }

  ngOnInit() {
    const interval = setInterval(() => {
      if (this.player.nativeElement.readyState === 4) {
        this.initProgressBar();
        clearInterval(interval);
      }
    });
  }
  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    //Write your code here
    console.log(this.src, "mohi");
    this.ngOnInit();

  }
  initProgressBar() {
    const player: any = document.getElementById('player');
    if (!isNullOrUndefined(player)) {
      const length = player.duration;
      const current_time = player.currentTime;

      // calculate total length of value
      const totalLength = this.calculateTotalValue(length);
      document.getElementById('end-time').innerHTML = totalLength;

      // calculate current value time
      const currentTime = this.calculateCurrentValue(current_time);
      document.getElementById('start-time').innerHTML = currentTime;

      const progressbar: any = document.getElementById('seek-obj');
      if (player.duration > 0) {
        progressbar.value = (player.currentTime / player.duration);
      }

      if (player.currentTime == player.duration) {
        document.getElementById('play-btn').className = '';
        this.isPlaying = false;
      }
    }
  }

  seek(event, player, progressbar) {
    if (player.duration > 0) {
      console.log('event', event);
      let percent = event.layerX / event.target.clientWidth;
      player.currentTime = percent * player.duration;
      document.getElementById('start-time').innerHTML = this.calculateCurrentValue(percent * player.duration);
      // const currentTime = percent * player.duration;
      progressbar.value = percent / 100;
    }
  }



  togglePlay() {
    let playerContainer = document.getElementById('player-container');
    let player: any = document.getElementById('player');
    let isPlaying = false;
    let playBtn = document.getElementById('play-btn');
    if (player.paused === false) {
      player.pause();
      isPlaying = false;
      document.getElementById('play-btn').className = '';
      this.isPlaying = false;
    } else {
      player.play();
      document.getElementById('play-btn').className = 'pause';
      this.isPlaying = true;
      isPlaying = true;
    }
  }

  calculateTotalValue(length) {
    let minutes = Math.floor(length / 60);
    let seconds_int = length - minutes * 60;
    let seconds_str = parseInt(seconds_int.toString(), 10).toString();
    let seconds = seconds_str.substr(0, 2);
    let time = (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds_int < 10 ? '0' + seconds : seconds);

    return time;
  }

  calculateCurrentValue(currentTime) {
    let current_hour = Math.floor(Number(currentTime / 3600) % 24);
    let current_minute = Math.floor(Number((currentTime / 60)) % 60);
    let current_seconds_long = currentTime % 60;
    let current_seconds = Number(current_seconds_long.toFixed());
    let current_time = (current_minute < 10 ? '0' + current_minute : current_minute) + ':' +
      (current_seconds < 10 ? '0' + current_seconds : current_seconds);
    return current_time;
  }

  closeModal() {
    this.bsModalService.setDismissReason('close-play-audio');
    this.bsModalRef.hide();
  }

}
