import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Input() text;
  @Input() icon;
  @Input() boldText;
  @Input() title;
  @Input() confirmBtnText;
  @Input() btnStatus;
  @Input() link;
  @Input() type;
  @Output() response: EventEmitter<any> = new EventEmitter();
  cancelBtnTitle = "cancel";

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
  ) {
  }

  ngOnInit() {
    if(this.type == "paymentMethod"){
      this.cancelBtnTitle = "close";
    }
  }

  closeModal(response) {
    this.bsModalService.setDismissReason(response + this.type);
    this.bsModalRef.hide();
  }
}
