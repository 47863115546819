import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { IDeactivateSettings } from '@app/@core/interfaces';
import { ApiService } from '@app/_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IDeactivateAccount } from '@app/@core/interfaces/api.interface';

@Component({
  selector: 'app-deactivate-reasons-modal',
  templateUrl: './deactivate-reasons-modal.component.html',
  styleUrls: ['./deactivate-reasons-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DeactivateReasonsModalComponent implements OnInit {
  title = "Why are you cancelling your subscription?";
  icon = "/assets/img/icons/common/sad-face-in-rounded-square.svg";
  ready;
  selectedReason; 
  loading = false;
  deactivationReasons;
  form: FormGroup;
  
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toasterService: ToastrService,
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      msg: ''
    });
    this.initData();
  }

  get reasonsForm() {
    return (<FormGroup>(this.form)).controls;
  }

  initData() {
    this.ready = false;
    combineLatest(
      this.apiService.callGetCompanyCancellationReasons(),
    )
      .pipe(
        finalize(() => this.ready = true)
      ).subscribe(([
          deactivateResponse
        ]: [
          any
          ]) => {
        if (deactivateResponse.success) {
          this.deactivationReasons = deactivateResponse.data;
        } else {
          this.toasterService.warning(deactivateResponse.message);
        }

      }, (err) => {
        console.log(err)
      });
  }

  onSubmit() {
    this.loading = true;
    const payload:IDeactivateAccount = {
      cancellation_reason_id: this.selectedReason,
      cancellation_notes: this.reasonsForm.msg.value
    };
    this.apiService.callDeactivateAccount(payload)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      ).subscribe((response: any) => {
        if (response.success) {
          this.toasterService.success('Success');
          this.bsModalService.setDismissReason('deactivate');
          this.bsModalRef.hide();
        } else {
          this.toasterService.warning(response.message);
        }
      }, (error) => {
        this.toasterService.error(error.error.message);
      });
  }

  closeModal(noChange = false) {
    if (noChange) {
      this.bsModalService.setDismissReason('close');
    }
    this.bsModalRef.hide();
  }

  selectReason(reasonId){
    this.selectedReason = reasonId;
  }
}
