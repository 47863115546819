import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'dsk-tel-input',
  templateUrl: './tel-input.component.html',
  styleUrls: ['./tel-input.component.scss']
})
export class TelInputComponent implements OnInit {

  @Input() group: FormGroup;
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() fullWidth = false;
  @Input() flag;
  @Input() submitted = false;
  @Input() useMask = true;

  defaultFlag = '/assets/img/icons/common/flag.svg';

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    if (!this.group) {
      this.group = this.fb.group({
        follow_me_num: [null, [Validators.required, Validators.minLength(9), Validators.maxLength(13)]],
        follow_me_country_prefix: [null, [Validators.required]],
      });
    }
  }

  get getFlag() {
    return this.flag || this.defaultFlag;
  }

}
