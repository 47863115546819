import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@app/_services/api.service';
import { Subscription, of } from 'rxjs';
import { IOption, IMember } from '@app/@core/interfaces';
import { isNullOrUndefined } from 'util';
import { DataHelperService } from '@app/_services/data.helper.service';
import { KazooMedia, KazooMediaUpload, KazooMediaUploadResponse } from '@app/@core/interfaces/kz.interface';
import { MODAL_CLOSE_REASON } from '@app/variables/constants';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { IApiResponse } from './../../../../../@core/interfaces/api.interface';
import { CallFlowService } from '@app/_services/call-flow.service';
import { alphaNumericValidator } from '../alphanumeric-validator';
import { LoaderService } from '@app/loader.service';

@Component({
  selector: 'app-ivr-settings',
  templateUrl: './ivr-settings.component.html',
  styleUrls: ['./ivr-settings.component.scss']
})
export class IVRSettingsComponent implements OnInit, OnDestroy {
  @Input() isEdit = false;
  @Input() isIvr = false;
  @Input() isSubIvr = false;
  @Input() isEditRoot = false;
  @Input() data;
  @Input() isOriginal;
  @Input() src;
  @Input() target;
  @Input() popupTitle = 'IVR';
  mediaList: KazooMedia[] = [];
  mediaListOptions: IOption[] = [];
  form: FormGroup;
  formAddNew: FormGroup;
  loading = false;
  subscription$: Subscription;
  selectedMedia: KazooMedia = null;
  addNew = false;
  selectedFile: File = null;
  mediaListback: any;
  mediaplayer = false;
  mediasrc: any;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toasterService: ToastrService,
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private dataHelper: DataHelperService,
    private callflowService: CallFlowService,
    private loaderService: LoaderService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.isEdit && !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.id)) {
      this.loaderService.startLoading();
      this.apiService.getIvrById(this.data.id).subscribe((res: any) => {
        if (res.success && !isNullOrUndefined(res.data) && !isNullOrUndefined(res.data.ivr) && !isNullOrUndefined(res.data.ivr.data)) {
          const responseData = res.data.ivr.data;
          if (isNullOrUndefined(this.data.media)) {
            this.data.media = {};
          }
          if (responseData.media.greeting) {
            this.data.media.greeting = responseData.media.greeting;
          } else {
            if (responseData.media.id) {
              this.data.media.greeting = responseData.media.id;
            }
          }
          this.data.name = responseData.name;
          this.data.retries = Number(responseData.retries) - 1;
          this.data.timeout = responseData.timeout;
          this.data.interdigit_timeout = responseData.interdigit_timeout;
          // responseData.name: "IVR_Testing2",
          // responseData.timeout: 10000,
          // responseData.interdigit_timeout: 2000,
          // responseData.max_extension_length: 4,
          // responseData.retries: 3,
          // responseData.media: {
          //     "greeting": "895c75509a8c9af85804a5f0eb0b7dbb"
          // },
          // responseData.hunt: false,
          // responseData.allow_record_from_offnet: true,
          // responseData.suppress_media: false,
          // responseData.id: "17e9bce985d234ec99dbe2e83204b77b"
          this.fillIvrSettings();
        }
        this.loaderService.stopLoading();
      }, error => {
        this.loaderService.stopLoading();
        console.log('error', error);
      });
    }
    this.getMediaList();
    this.initForm();
  }

  onRingstrategyChanged(event) {
    const value = event.target.value;
    this.form.get('ringStrategy').setValue(value);
  }

  getMediaList() {
    const subscription = this.apiService.callGetMediaList().subscribe((res: IApiResponse<any>) => {
      this.mediaList = res.data as KazooMedia[];
      this.mediaListback = res.data;
      this.mediaListOptions = this.mediaList.map<IOption>(media => {
        return { label: media.name, value: media.id, selected: false };
      });
      this.fillIvrSettings();
      if (!isNullOrUndefined(subscription) && !subscription.closed) {
        subscription.unsubscribe();
      }
    }, error => {
      if (!isNullOrUndefined(subscription) && !subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }

  private fillIvrSettings() {
    if (this.isEdit) {
      if (!isNullOrUndefined(this.data.media)) {
        if (!isNullOrUndefined(this.data.media.greeting)) {
          this.form.get('media').setValue(this.data.media.greeting);
        } else {
          if (!isNullOrUndefined(this.data.media.id)) {
            this.form.get('media').setValue(this.data.media.id);
          }
        }
        this.form.get('name').setValue(this.data.name);
        this.form.get('misdialRetries').setValue(this.data.retries);
        this.form.get('timeoutForDialing').setValue((this.data.timeout / 1000));
        this.form.get('enterDigitTimeout').setValue((this.data.interdigit_timeout / 1000));
      }
    }
  }

  private initForm() {
    this.formAddNew = this.fb.group({
      name: [null, [Validators.required]],
      media: [null],
      document_progress: new FormControl(null)
    });
    this.form = this.fb.group({
      name: [null, [Validators.required, alphaNumericValidator]],
      misdialRetries: [5, [Validators.required, Validators.min(1), Validators.max(9)]],
      timeoutForDialing: [10, [Validators.required, Validators.min(1), Validators.max(10)]],
      enterDigitTimeout: [1, [Validators.required, Validators.min(1), Validators.max(10)]],
      media: [null, [Validators.required]],
    });
    this.form.get('media').valueChanges.subscribe(value => {
      if (this.mediasrc != '') {
        this.mediaplayer = false;
        this.mediasrc = '';
        this.changeDetector.detectChanges();
      }
      if (!isNullOrUndefined(value)) {
        if (this.addNew) {
          this.resetFormAddNew();
          this.toggleAddNew();
        }
        const mediaSelected = this.mediaList.find(media => media.id === value);
        if (!isNullOrUndefined(mediaSelected)) {
          this.selectedMedia = mediaSelected;
          const medialink = this.mediaListback.find(media => media.id === value);
          this.mediasrc = medialink.link;
          this.mediaplayer = true;
        }
      }
    });
  }

  toggleAddNew() {
    this.addNew = !this.addNew;
    if (this.addNew) {
      // this.form.get('media').setValidators(null);
      this.form.get('media').setValue(null);
      if (!this.isEdit) {
        this.selectedMedia = null;
      }
    } else {
      // this.form.get('media').setValidators(Validators.required);
      if (this.isEdit) {
        if (!isNullOrUndefined(this.data.media.greeting)) {
          this.form.get('media').setValue(this.data.media.greeting);
        } else {
          if (!isNullOrUndefined(this.data.media.id)) {
            this.form.get('media').setValue(this.data.media.id);
          }
        }
        // let mediaSelected = null;
        // if (this.isOriginal) {
        //   mediaSelected = this.data.id;
        // } else {
        //   mediaSelected = this.data.media.id;
        // }
        // this.selectedMedia = this.mediaList.find(mediaItem => mediaItem.id === mediaSelected);
        // this.form.get('media').setValue(mediaSelected);
      }
    }
  }

  fileSelected(file: File) {
    this.formAddNew.get('document_progress').setValue('selected');
    this.selectedFile = file;
  }

  uploadDocument(file: File, mediaForm: FormGroup) {
    if (mediaForm.invalid) {
      this.toasterService.warning('Please fill all required fields.');
      return;
    }

    let name = file.name;
    let type = file.type;
    let size = file.size;
    let sizeInMB = Math.round(size / 1024);
    if (sizeInMB > 5000) {
      this.toasterService.warning('The media file exceeds maximum size limit (5 MB).');
      mediaForm.get('media').setValue(null);
      mediaForm.get('document_progress').setValue('invlaid');
      return of(`${file.name} upload failed.`);
    }
    const payload: KazooMediaUpload = {
      media: file,
      name: this.formAddNew.value.name
    };
    return this.apiService.uploadMedia(payload).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = ((event.loaded / event.total) * 100).toFixed(0);
            mediaForm.get('document_progress').setValue('uploading ' + progress + '%');
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        mediaForm.get('document_progress').setValue('fail');
        return of(`${file.name} upload failed.`);
      })
    );
  }


  onSubmit() {
    if (!isNullOrUndefined(this.selectedFile)) {
      this.uploadDocument(this.selectedFile, this.formAddNew).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          this.formAddNew.get('document_progress').setValue('uploaded');
          const mediaUploadedResponse = event.body.data as KazooMediaUploadResponse;
          this.selectedMedia = {
            id: mediaUploadedResponse.id,
            name: mediaUploadedResponse.name,
            media_source: mediaUploadedResponse.media_source,
            language: mediaUploadedResponse.language,
            is_prompt: false,
          };
          this.mediaList.push(this.selectedMedia);
          this.mediaListOptions.push({ label: this.selectedMedia.name, value: this.selectedMedia.id, selected: false });
          this.save();
        }
      });
    } else {
      this.save();
    }
  }

  save() {
    if (this.addNew && this.formAddNew.invalid) {
      this.toasterService.warning('Please make sure all data is correct.');
      return;
    } else if (!this.addNew && this.form.invalid) {
      this.toasterService.warning('Please select a team.');
      return;
    } else {
      let pendingData: any = {
        isEditRoot: this.isEditRoot,
        ivrSettings: this.form.value,
        timeout: (this.form.value.timeoutForDialing * 1000),
        interdigit_timeout: (this.form.value.enterDigitTimeout * 1000),
        retries: this.form.value.misdialRetries,
        name: this.form.value.name,
        media: {
          exit_media: true,
          greeting: this.form.value.media,
          invalid_media: true,
          transfer_media: true
        },
        isIvr: this.isIvr,
        target: this.target,
        src: this.src,
        nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1,
        isEdit: this.isEdit
      };
      if (!isNullOrUndefined(this.selectedMedia)) {
        pendingData.media = this.selectedMedia;
      }
      if (!isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.id)) {
        pendingData.id = this.data.id;
      }
      this.loaderService.startLoading();
      this.callflowService.saveIvr(pendingData).subscribe((res: any) => {
        if (!isNullOrUndefined(res) && res.success) {
          const response = !isNullOrUndefined(res.data) && !isNullOrUndefined(res.data.data) ? res.data.data :
            (!isNullOrUndefined(res.data) && !isNullOrUndefined(res.data.ivr) ? res.data.ivr : null);
          if (!isNullOrUndefined(response) && !isNullOrUndefined(response.id)) {
            if (!isNullOrUndefined(pendingData.id) && !isNullOrUndefined(pendingData.id)) {
              delete pendingData.id;
            }
            pendingData = {...pendingData, id: response.id};
          }
        }
        if (this.isSubIvr) {
          this.dataHelper.sendPendingSubIvrData(pendingData);
        } else if (this.isIvr && !this.isSubIvr) {
          this.dataHelper.sendPendingIvrData(pendingData);
        } else {
          this.dataHelper.sendPendingData(pendingData);
        }
        this.loaderService.stopLoading();
        this.closeModal(true);
      }, error => {
        this.loaderService.stopLoading();
        console.log('error', error);
      });
    }
  }

  resetFormAddNew() {
    this.selectedFile = null;
    // this.addNew = false;
    this.formAddNew.reset();
  }


  closeModal(noChange = false) {
    if (!noChange) {
      this.bsModalService.setDismissReason('close');
    } else {
      this.bsModalService.setDismissReason(MODAL_CLOSE_REASON.IVR);
    }
    this.isEdit = null;
    this.isIvr = null;
    this.isSubIvr = null;
    this.isEditRoot = null;
    this.data = null;
    this.isOriginal = null;
    this.src = null;
    this.target = null;
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    this.isEdit = null;
    this.isIvr = null;
    this.isSubIvr = null;
    this.isEditRoot = null;
    this.data = null;
    this.isOriginal = null;
    this.src = null;
    this.target = null;
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }
}
