import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as AWS from 'aws-sdk';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-play-audio',
  templateUrl: './play-audio.component.html',
  styleUrls: ['./play-audio.component.scss']
})
export class PlayAudioModalComponent implements OnInit {
  @ViewChild('player') player: ElementRef;
  @Input() src;

  isPlaying = false;
  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
  ) {
  }

  ngOnInit() {
    const interval = setInterval(() => {
      if (this.player.nativeElement.readyState === 4) {
        this.initProgressBar();
        clearInterval(interval);
      }
    });
  }

  initProgressBar() {
    let player: any = document.getElementById('player');
    let length = player.duration;
    let current_time = player.currentTime;

    // calculate total length of value
    let totalLength = this.calculateTotalValue(length);
    document.getElementById('end-time').innerHTML = totalLength;

    // calculate current value time
    let currentTime = this.calculateCurrentValue(current_time);
    document.getElementById('start-time').innerHTML = currentTime;

    let progressbar: any = document.getElementById('seek-obj');
    if (player.duration > 0) {
      progressbar.value = (player.currentTime / player.duration);
    }

    if (player.currentTime == player.duration) {
      document.getElementById('play-btn').className = '';
      this.isPlaying = false;
    }
  }

  seek(event, player, progressbar) {
    if (player.duration > 0) {
      console.log('event', event);
      let percent = event.layerX / event.target.clientWidth;
      player.currentTime = percent * player.duration;
      document.getElementById('start-time').innerHTML = this.calculateCurrentValue(percent * player.duration);
      // const currentTime = percent * player.duration;
      progressbar.value = percent / 100;
    }
  }

  download() {
    //"bucket":"talkchief" "key":"AKIA4T5TJKEAIW5VUUIM" "secret":"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"
    // var s3 = new AWS.S3();
    // s3.config.update({
    //   credentials: {
    //     accessKeyId: 'AKIA4T5TJKEACHARYYLY',  /* required */
    //     secretAccessKey: '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', /* required */
    //   }
    // });

    // // s3
    // const src = (this.src as string).split('.com/')[1];
    // console.log('src', src);
    // s3.getObject({ Bucket: 'talkchief', Key: src }, (err, data) => {
    //   if (err) {
    //     console.log(err, err.stack); // an error occurred
    //   } else {
    //     console.log(data);
    //     const arr = src.split('/');

    //     FileSaver.saveAs(new Blob([new Uint8Array(data.Body as any, data.ContentLength)]), arr[arr.length - 1]);
    //   }
    // });

  }

  togglePlay() {
    let playerContainer = document.getElementById('player-container');
    let player: any = document.getElementById('player');
    let isPlaying = false;
    let playBtn = document.getElementById('play-btn');
    if (player.paused === false) {
      player.pause();
      isPlaying = false;
      document.getElementById('play-btn').className = '';
      this.isPlaying = false;
    } else {
      player.play();
      document.getElementById('play-btn').className = 'pause';
      this.isPlaying = true;
      isPlaying = true;
    }
  }

  calculateTotalValue(length) {
    let minutes = Math.floor(length / 60);
    let seconds_int = length - minutes * 60;
    let seconds_str = parseInt(seconds_int.toString(),10).toString();
    let seconds = seconds_str.substr(0, 2);
    let time = (minutes < 10 ? '0'+minutes : minutes) + ':' + (seconds_int < 10 ? '0'+seconds : seconds);

    return time;
  }

  calculateCurrentValue(currentTime) {
    let current_hour = Math.floor(Number(currentTime / 3600) % 24);
    let current_minute = Math.floor(Number((currentTime / 60)) % 60);
    let current_seconds_long = currentTime % 60;
    let current_seconds = Number(current_seconds_long.toFixed());
    let current_time = (current_minute < 10 ? '0' + current_minute : current_minute) + ':' +
      (current_seconds < 10 ? '0' + current_seconds : current_seconds);
    return current_time;
  }

  closeModal() {
    this.bsModalService.setDismissReason('close-play-audio');
    this.bsModalRef.hide();
  }
}
