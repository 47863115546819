import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UnsavedChangesModalComponent } from '@app/@shared/components/unsaved-changes-modal/unsaved-changes-modal.component';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

    canDeactive = new Subject<boolean>();
    canDeactive$: Observable<boolean>;

    constructor(
        private bsModalService: BsModalService,
        public bsModalRef: BsModalRef,
    ) {
        this.subscribeCloseModal();
        this.canDeactive$ = this.canDeactive.asObservable();
    }

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        if (component.canDeactivate()) {
            return true;
        } else {
            this.showUnsavedChangesModal();
            return this.canDeactive$;
        }
    }

    private subscribeCloseModal() {
        this.bsModalService.onHide.subscribe((reason: string) => {
            if (reason === 'leave') {
                this.canDeactive.next(true);
            }
        });
    }

    showUnsavedChangesModal() {
        return this.bsModalService.show(UnsavedChangesModalComponent, {
            ignoreBackdropClick: true,
            class: 'dsk-modal unsaved-changes-modal'
        });
    }
}
