import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-progress-bar',
  template: `
      <div>
        <div class="progress-bar-container">
          <div [ngStyle]="{'margin-left': setMargin()}" class="oval {{color}}" style="position: absolute;top: -2px;" ></div>
        </div>
        <span class="progress-level">0</span>
        <span class="progress-level-right">100</span>
      </div>
  `,
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() progress;
  total = 100;
  color: string;
  constructor() { }
  ngOnInit() {
    // this.progress = Number(this.progress);
    //if we don't have progress, set it to 0.
    if(isNaN(this.progress)) {
      this.progress = 100;
    }
    //if we don't have a total aka no requirement, it's 100%.
    if(this.total === 0) {
      this.total = this.progress;
    } else if(!this.total) {
      this.total = 100;
    }
    //if the progress is greater than the total, it's also 100%.
    if(this.progress > this.total) {
      this.progress = 100;
      this.total = 100;
    }
    if(this.progress >= 97) {
      this.progress = 97;
    }
    if(this.progress < 60) {
      this.color = 'red';
    } else if(this.progress < 80) {
      this.color = 'yellow';
    } else {
      this.color = 'green';
    }
  }

  setMargin() {
    const styles = {
      'margin-left': this.progress + '%',
    }
    if (this.progress >= 97) {
      this.progress = 97;
    }
    if (this.progress < 60) {
      this.color = 'red';
    } else if (this.progress < 80) {
      this.color = 'yellow';
    } else {
      this.color = 'green';
    }
    return this.progress + '%';
  }
}
