import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ISupportMessageRequest } from '@app/@core/interfaces';
import { ApiService } from '@app/_services/api.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-request-custom-report',
  templateUrl: './request-custom-report.component.html',
  styleUrls: ['./request-custom-report.component.scss']
})
export class RequestCustomReportComponent implements OnInit {
  form: FormGroup;
  sentSuccessfully = false;
  ready = false;
  remainingChar = 5000;

  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {

    this.form = this.fb.group({
      message: this.fb.group({
        subject: ['', [Validators.required]],
        body: ['', [Validators.required]]
      })
    });
  }

  ngOnInit() { }

  get messageForm() {
    return (<FormGroup>(this.form.get('message'))).controls;
  }

  resetMessage() {
    this.messageForm.subject.setValue('');
    this.messageForm.body.setValue('');
    this.messageForm.subject.markAsUntouched();
    this.messageForm.body.markAsUntouched();
    this.remainingChar = 5000;
  }

  onSubmit() {
    var payload: ISupportMessageRequest = {
      subject: `Custom Report Request:  ${this.messageForm.subject.value}`,
      message: this.messageForm.body.value
    };

    this.apiService.callSupportMessage(payload)
      .subscribe((response: any) => {
        if (response.success) {
          this.sentSuccessfully = true;
          this.toastr.success('Your message has been sent successfully');
        }
        else {
          this.toastr.error(response.message);
        }
      }, (err) => {
        this.toastr.error(err.error.message);
      });
  }

  msgBodyChanged() {
    this.remainingChar = 5000 - this.messageForm.body.value.length;
  }

  closeModal($event) {
    this.bsModalService.setDismissReason('close-play-audio');
    this.bsModalRef.hide();
  }
}
