import { IMember } from './../../../../../@core/interfaces/member.interface';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@app/_services/api.service';
import { take, finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IOption } from '@app/@core/interfaces';
import { isNullOrUndefined } from 'util';
import { DataHelperService } from '@app/_services/data.helper.service';
import { MODAL_CLOSE_REASON } from '@app/variables/constants';

@Component({
  selector: 'app-member-select',
  templateUrl: './member-select.component.html',
  styleUrls: ['./member-select.component.scss']
})
export class MemberSelectComponent implements OnInit, OnDestroy {
  @Input() isEdit = false;
  @Input() isEditRoot = false;
  @Input() data;
  @Input() isOriginal;
  @Input() isSubIvr = false;
  @Input() isIvr = false;
  @Input() src;
  @Input() target;
  @Input() memberName: string = null;
  @Input() members: IMember[] = [];
  @Input() popupTitle = 'select_member';
  membersOptions: IOption[] = [];
  form: FormGroup;
  loading = false;
  subscription$: Subscription;
  selectedMember: any;

  constructor(
    private fb: FormBuilder,
    private toasterService: ToastrService,
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private dataHelper: DataHelperService
  ) {
  }

  ngOnInit() {
    this.membersOptions = this.members.map(member => {
      return { label: this.getMemberName(member.member_id), value: member.member_id, selected: false };
    });
    this.initForm();
  }

  getMemberName(member_id: number) {
    const member = this.members.find(memberItem => memberItem.member_id === member_id);
    let memberName = '';
    if (!isNullOrUndefined(member)) {
      memberName = member.member_first_name.slice(0, 1).toUpperCase() + member.member_first_name.slice(1) +
        ' ' + member.member_last_name.slice(0, 1).toUpperCase() + member.member_last_name.slice(1);
    }
    // console.log('memberName', memberName);
    return memberName;
  }

  private initForm() {
    let keepRingingFor = 30;
    let memberIdSelected = null;
    if (this.isEdit) {
      let memberFound: IMember;
      if (this.isOriginal) {
        memberFound = this.members.find(member => member.kz_member_uuid === this.data.id);
        this.memberName = this.getMemberName(memberFound.member_id);
        keepRingingFor = this.data.timeout;
      } else {
        memberFound = this.members.find(member => member.kz_member_uuid === this.data.member.kz_member_uuid);
        this.memberName = this.getMemberName(memberFound.member_id);
        keepRingingFor = this.data.keepRingingFor;
      }
      const optionFound = this.membersOptions.find(option => option.value === memberFound.member_id);
      if (!isNullOrUndefined(optionFound)) {
        optionFound.selected = true;
      }
      memberIdSelected = memberFound.member_id;
    }
    this.form = this.fb.group({
      member: [memberIdSelected, [Validators.required]],
      keepRingingFor: [keepRingingFor, [Validators.required, Validators.min(15), Validators.max(60)]]
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.toasterService.warning('Please select a member.');
      return;
    }
    const member = this.members.find(memberItem => memberItem.member_id === this.form.value.member);
    if (!isNullOrUndefined(member)) {
      if (this.isSubIvr) {
        this.dataHelper.sendPendingSubIvrData({
          isEditRoot: this.isEditRoot,
          member,
          keepRingingFor: this.form.value.keepRingingFor,
          memberName: this.getMemberName(member.member_id),
          target: this.target,
          src: this.src,
          isIvr: this.isIvr,
          isEdit: this.isEdit,
          nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
        });
      } else if (this.isIvr && !this.isSubIvr) {
        this.dataHelper.sendPendingIvrData({
          isEditRoot: this.isEditRoot,
          member,
          keepRingingFor: this.form.value.keepRingingFor,
          memberName: this.getMemberName(member.member_id),
          target: this.target,
          src: this.src,
          isIvr: this.isIvr,
          isEdit: this.isEdit,
          nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
        });
      } else {
        this.dataHelper.sendPendingData({
          isEditRoot: this.isEditRoot,
          member,
          keepRingingFor: this.form.value.keepRingingFor,
          memberName: this.getMemberName(member.member_id),
          target: this.target,
          src: this.src,
          isIvr: this.isIvr,
          isEdit: this.isEdit,
          nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
        });
      }
    }
    this.closeModal(true);
  }

  closeModal(noChange = false) {
    if (!noChange) {
      this.bsModalService.setDismissReason('close');
    } else {
      this.bsModalService.setDismissReason(MODAL_CLOSE_REASON.MEMBER);
    }
    this.bsModalRef.hide();
    this.isEdit = null;
    this.isEditRoot = null;
    this.data = null;
    this.isOriginal = null;
    this.isSubIvr = null;
    this.isIvr = null;
    this.src = null;
    this.target = null;
  }

  ngOnDestroy() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }

}
