export const ICONS = {
    TEAM: 'assets/img/icons/common/Teams.svg',
    WORKING_HOURS: 'assets/img/icons/common/Time.svg',
    USER: 'assets/img/icons/common/user-burnt-yellow.svg',
    QUEUE: 'assets/img/icons/common/Queues_Green.svg',
    CALL_FORWARD: 'assets/img/icons/common/Follow_Me_Purple.svg',
    VOICE_MAIL: 'assets/img/icons/common/Voicemail.svg',
    MEDIA: 'assets/img/icons/common/Media.svg',
    IVR: 'assets/img/icons/common/IVR.svg',
    WEBHOOK: 'assets/img/icons/common/webhooks.svg',
    CALL_FLOW: 'assets/img/icons/common/Call_Flow.svg',
};
export const ICONS_COLORS = {
    TEAM: 'jade-green',
    WORKING_HOURS: 'azure',
    USER: 'green',
    QUEUE: 'jade-green',
    CALL_FORWARD: 'purple',
    VOICE_MAIL: 'purple',
    MEDIA: 'azure',
    IVR: 'coral',
    WEBHOOK: 'green',
    CALL_FLOW: 'teal',
};
export const MODAL_CLOSE_REASON = {
    MEMBER: 'select-member',
    QUEUE: 'select-queue',
    WORKING_HOURS: 'select-working-hours',
    CALL_FORWARD: 'select-cellphone',
    MEDIA: 'select-media',
    TEAM: 'select-team',
    IVR: 'ivr-settings',
    VOICE_MAIL: 'select-vmbox',
    WEBHOOK: 'webhook',
    WORKING_HOURS_ADVANCED: 'working-hours-advanced',
    CALL_FLOW: 'callFlow-select',
};
