import {Injectable} from '@angular/core';
import {IOption} from '@app/@core/interfaces';
import {LocalizationService} from '@app/internationalization/localization.service';

@Injectable({
    providedIn: 'root'
})
export class OptionService {

    constructor(private localizationService: LocalizationService
    ) {
    }

    generateIOption(items: any[], valueKey: string, labelKey: string, iconKey?: string) {
        let newItems: IOption[] = [];
        if (items) {
            newItems = items.map(i => {
                return {
                    // label: i[labelKey],
                    label: this.localizationService.translate(i[labelKey]),
                    value: i[valueKey],
                    selected: false,
                    icon: i[iconKey],
                    data: i,
                } as IOption;
            });
        }
        return newItems;
    }

}
