import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-download-app-popup',
  templateUrl: './download-app-popup.component.html',
  styleUrls: ['./download-app-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DownloadAppPopupComponent implements OnInit {
  form: FormGroup;
  @Input() applink;
  @Input() showSecondPart = false;

  constructor(private fb: FormBuilder,
    public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      userName: [{value: this.applink.userName,disabled:true}],
      password: [{value: "*****************",disabled:true}],
      domain: [{value: this.applink.domain,disabled:true}],
      proxy: [{value: this.applink.proxy,disabled:true}]
    });
  }
  
  closeModal() {
    this.bsModalRef.hide();
  }
}
