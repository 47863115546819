import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IOnBoardingRequest, IPurchaseDID, IDeactivateAccount, ICallsCDRRequest} from '@app/@core/interfaces/api.interface';
import {environment} from '@environments/environment';
import {EncrDecrService} from '@app/_services/encr-decr-service';
// import {LocalizationService} from '@app/internationalization/localization.service';

import {
    IAvailableDIDRequest,
    ITeamSettingsRequest,
    IMemberInfo,
    IMemberFollowMe,
    ITeamCallerIdRequest,
    IMemberRequest,
    ITerminateNumber,
    ISettingsRequest,
    ICompanyChangePasswordRequest,
    IUploadDocument,
    IWalletRequest,
    ISupportMessageRequest,
    IPaymentMethodRequest,
    IVoiceMailRequest
} from '@app/@core/interfaces';
import {IMemberSettingsRequest} from '../@core/interfaces/member.interface';
import {KazooCellPhoneDeviceRequest, KazooMediaUpload, KazooFlowRequest, KazooIvrRequest} from '@app/@core/interfaces/kz.interface';
import {stringify} from 'querystring';
import {IMediasRequest, MediaUpload} from '@app/@core/interfaces/media.interface';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private runLoadingHeader = {
        headers: {
            'run-loading': 'true',
        }
    };

    private apiUrl = `${environment.apiUrl}`;
    encryptIsActive: boolean = environment.encryptIsActive;

    constructor(private http: HttpClient, private EncrDecr: EncrDecrService) {
    }

    generateFormData(payload: any) {
        // debugger;
        const formData = new FormData();
        Object.keys(payload).forEach(key => {
            const value = Array.isArray(payload[key]) ? JSON.stringify(payload[key]) : payload[key];
            formData.append(key, value);
        });
        return formData;
    }


    generateEncryptedFormData(payload: any) {
        console.log('encryptIsActive', this.encryptIsActive);
        if (this.encryptIsActive == true) {
            /**
             * encryption
             */
            const testEncrypt = this.EncrDecr.encrypt(payload);
            const base64Data = btoa(testEncrypt);
            const formData = new FormData();
            formData.append('app_request', `${base64Data}`);
            return formData;

        } else {
            return payload;
        }
    }  


    chargeTopup(amount: number) {
        const url = this.apiUrl + `/subscriptions/charge`;
        const formData = new FormData();
        const payload = {'amount': amount};
        const data = this.generateEncryptedFormData(payload);
        return this.http.post(url, data, this.runLoadingHeader);
    }

    callSupportMessage(payload: ISupportMessageRequest) {
        const url = this.apiUrl + `/company/support`;
        const data = this.generateEncryptedFormData(payload);
        return this.http.post(url, data);
    }


    switchMemberLang(payload) {
        const url = this.apiUrl + `/members/lang_switch`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    getMemberLanguage() {
        const url = this.apiUrl + `/members/language`;
        return this.http.get(url);
    }

    getCountries() {
        const url = this.apiUrl + `/countries`;
        return this.http.get(url, this.runLoadingHeader);
    }

    getIndustries() {
        const url = this.apiUrl + `/industries`;
        return this.http.get(url);
    }

    getTimezones() {
        const url = this.apiUrl + `/timezones`;
        return this.http.get(url);
    }

    getCities(countryId) {
        const url = this.apiUrl + `/countries/${countryId}/cities`;
        return this.http.get(url);
    }

    getCitiesByCountryAndState(countryId, stateId) {
        const url = this.apiUrl + `/countries/${countryId}/states/${stateId}/cities`;
        return this.http.get(url);
    }


    getCitiesByCountry(countryId) {
        const url = this.apiUrl + `/countries/${countryId}/cities`;
        return this.http.get(url);
    }

    getStates(countryId) {
        const url = this.apiUrl + `/countries/${countryId}/states`;
        return this.http.get(url);
    }

    callOnBoardingEndpoint(payload: IOnBoardingRequest) {
        const url = this.apiUrl + `/on_boarding`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData);
    }

    callGetOnBoardingEndpoint() {
        const url = this.apiUrl + `/on_boarding`;
        return this.http.get(url, this.runLoadingHeader);
    }

    callGetTeams() {
        const url = this.apiUrl + '/teams';
        return this.http.get(url, this.runLoadingHeader);
    }

    callCreateNewTeam(teamName: string, teamExtension: string, did_id: string, timezone: string) {
        const url = this.apiUrl + `/teams`;

        // const formData = new FormData();
        // formData.append('did_id', `${did_id}`);
        // formData.append('team_name', `${teamName}`);
        // formData.append('team_extension', `${teamExtension}`);
        const payload = {did_id: did_id, team_name: teamName, team_extension: teamExtension, team_timezone_id: timezone};
        const formData = this.generateEncryptedFormData(payload);

        return this.http.post(url, formData);
    }

    callDeleteTeam(teamIdToDelete: number, newTeamId: number) {
        const url = this.apiUrl + `/teams/${teamIdToDelete}` + (newTeamId ? `/${newTeamId}` : '');
        return this.http.delete(url);
    }

    callGetTeamMembers(teamId: number) {
        const url = this.apiUrl + `/teams/${teamId}/members`;
        return this.http.get(url);
    }

    callGetTeamNumbers(teamId: number) {
        const url = this.apiUrl + `/teams/${teamId}/numbers`;
        return this.http.get(url);
    }

    callMakeNumberAsDefault(teamId: number, did_id: string) {
        const url = this.apiUrl + `/teams/${teamId}/default_number`;
        const payload = {did_id: did_id, team_id: teamId};
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callRemoveNumberFromTeam(teamId: number, did_id: string) {
        const url = this.apiUrl + `/teams/${teamId}/remove_number`;
        const payload = {did_id: did_id, team_id: teamId};
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callGetTeamCallerId(teamId: number) {
        const url = this.apiUrl + `/teams/${teamId}/team_caller_id`;
        return this.http.get(url, this.runLoadingHeader);
        // return this.http.get(url);
    }

    callUpdateTeamCallerId(teamId: number, payload: ITeamCallerIdRequest) {
        const url = this.apiUrl + `/teams/${teamId}/team_caller_id`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData);
    }

    callGetTeamMetadata(teamId: number) {
        const url = this.apiUrl + `/teams/${teamId}`;
        return this.http.get(url);
    }

    callRemoveSupervisor(teamId: number, memberId: number) {
        const url = this.apiUrl + `/teams/${teamId}/remove_supervisor`;
        const formData = new FormData();
        formData.append('member_id', `${memberId}`);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    callMakeSupervisor(teamId: number, memberId: number) {
        const url = this.apiUrl + `/teams/${teamId}/add_supervisor`;
        const payload = {member_id: memberId};
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    // callMemberTeam(newTeamId: number, memberId: number) {
    //   const url = `teams/change_member_team/${newTeamId}/${memberId}`;
    //   return this.http.get(url);
    // }

    callRenameTeam(teamId: number, newTeamName: string) {
        const url = this.apiUrl + `/teams/${teamId}`;
        const formData = new FormData();
        formData.append('team_name', `${newTeamName}`);
        return this.http.post(url, formData);
    }

    callRenameNumber(did_id: number, newDidName: string) {
        // const formData = new FormData();
        // formData.append('did_id', `${did_id}`);
        // formData.append('did_name', `${newDidName}`);
        const url = this.apiUrl + `/did/${did_id}/rename`;
        const payload = {did_id: did_id, did_name: newDidName};
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callUpdateTeamExt(teamId: number, newTeamExt: string) {
        const url = this.apiUrl + `/teams/${teamId}`;
        const formData = new FormData();
        formData.append('team_ext', `${newTeamExt}`);
        return this.http.post(url, formData);
    }

    callUpdateMemberExt(memberId: number, newMemberExt: string) {
        const url = this.apiUrl + `/members/${memberId}/extension`;
        const payload = {alias_extension: newMemberExt};
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    getDIDCountries() {
        const url = this.apiUrl + `/vendor/countries`;
        return this.http.post(url, {}, this.runLoadingHeader);
    }

    getOnBoardingDIDCountries() {
        const url = this.apiUrl + `/vendor/did_onboarding_countries`;
        return this.http.post(url, {}, this.runLoadingHeader);
    }


    getDIDCities(countryId, countryName, state) {
        const url = this.apiUrl + `/vendor/cities`;
        const formData = new FormData();
        formData.append('country_id', `${countryId}`);
        formData.append('country_name', `${countryName}`);
        formData.append('state_id', `${state}`);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    getDIDStates(countryId) {
        const url = this.apiUrl + `/vendor/states`;
        const formData = new FormData();
        formData.append('country_id', `${countryId}`);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    getDIDType() {
        const url = this.apiUrl + `/vendor/did_types`;
        return this.http.post(url, {});
    }

    getextensionlist() {
        const url = this.apiUrl + `/company/extensions`;
        return this.http.post(url, {});
    }

    getAvailableDID(payload: IAvailableDIDRequest) {
        const url = this.apiUrl + `/vendor/get_available_did`;
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callPurchaseDID(payload: IPurchaseDID) {
        const url = this.apiUrl + `/vendor/purchase_did`;
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callGetDIDDocumentsTypes() {
        const url = this.apiUrl + `/did/did_documents_type`;
        return this.http.get(url);
    }

    callStoreTeamSettings(teamId: number, payload) {
        const url = this.apiUrl + `/teams/${teamId}/settings`;
        // const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, payload);
    }

    callGetTeamSettings(teamId: number) {
        const url = this.apiUrl + `/teams/${teamId}/settings`;
        return this.http.get(url);
    }


    callGetMediaLink(media_id: string) {
        const url = this.apiUrl + `/media/${media_id}/link`;
        return this.http.get(url);
    }

    callGetHoldMusic() {
        const url = this.apiUrl + `/media/hold_music`;
        return this.http.get(url);
    }

    callUpdateHoldMusic(payload: IMediasRequest) {
        const url = this.apiUrl + `/media/hold_music`;
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    calluploadMedia(payload: MediaUpload) {
        const url = this.apiUrl + `/voip/media`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData, {reportProgress: true, observe: 'events'});
    }


    callDeleteMedia(media_id: string) {
        const url = this.apiUrl + `/media/${media_id}`;
        return this.http.delete(url, this.runLoadingHeader);
    }

    callRenameMedia(media_id: number, newmediaName: string) {
        const url = this.apiUrl + `/media/${media_id}/rename`;
        const payload = {name: newmediaName};
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callGetLiveCalls(company_id: string, role_id: number, team_id: number) {
        const url = this.apiUrl + `/monitor/active_calls`;

        // const formData = new FormData();
        // formData.append('kz_company_uuid', `${company_id}`);
        // formData.append('role_id', `${role_id}`);
        // formData.append('team_id', `${team_id}`);
        const payload = {kz_company_uuid: `${company_id}`, role_id: role_id, team_id: team_id};
        return this.http.post(url, payload);
    }

    callGetMonitorCalls(agent_id: string, user_name: string, domain: string, monitormode: string, autoanswer: string, actionperform: string) {
        const url = this.apiUrl + `/monitor/monitor_call`;

        const payload = {
            agent_call_uuid: `${agent_id}`,
            requester_username: `${user_name}`,
            domain: `${domain}`,
            monitor_mode: `${monitormode}`,
            auto_answer: `${autoanswer}`,
            where_to_spy: `${actionperform}`
        };
        return this.http.post(url, payload);
    }

    callGetIntegrationList() {
        const url = this.apiUrl + '/crms';
        return this.http.get(url, this.runLoadingHeader);
    }

    callGetIntegrationListInformation(crm_id: number) {
        const url = this.apiUrl + `/crms/${crm_id}`;
        return this.http.get(url, this.runLoadingHeader);
    }

    callActivateNewIntegration(crm_id: number, usertoken: string, DomainName: string, APIKey: string, Crmtoken: string, autodefaulttoggle: number, autoleadstoggle: number) {
        const url = this.apiUrl + `/crms/${crm_id}/activate`;

        const formData = new FormData();
        if (crm_id == 1) {
            formData.append('connectivity_parameters[domain]', `${DomainName}`);
            formData.append('connectivity_parameters[token]', `${Crmtoken}`);
            formData.append('auth_token', `${usertoken}`);
            formData.append('is_default', `${autodefaulttoggle}`);
            formData.append('create_lead', `${autoleadstoggle}`);
        } else if (crm_id == 3) {
            formData.append('connectivity_parameters[api_key]', `${APIKey}`);
            formData.append('auth_token', `${APIKey}`);
            formData.append('is_default', `${autodefaulttoggle}`);
            formData.append('create_lead', `${autoleadstoggle}`);
        } else {
            formData.append('connectivity_parameters[token]', `${Crmtoken}`);
            formData.append('auth_token', `${usertoken}`);
            formData.append('is_default', `${autodefaulttoggle}`);
            formData.append('create_lead', `${autoleadstoggle}`);
        }
        return this.http.post(url, formData);
    }


    callUpdateIntegration(crm_id: number, usertoken: string, DomainName: string, APIKey: string, Crmtoken: string, autodefaulttoggle: number, autoleadstoggle: number) {
        const url = this.apiUrl + `/crms/${crm_id}/update`;

        const formData = new FormData();
        if (crm_id == 1) {
            formData.append('connectivity_parameters[domain]', `${DomainName}`);
            formData.append('connectivity_parameters[token]', `${Crmtoken}`);
            formData.append('auth_token', `${usertoken}`);
            formData.append('is_default', `${autodefaulttoggle}`);
            formData.append('create_lead', `${autoleadstoggle}`);
        } else if (crm_id == 3) {
            formData.append('connectivity_parameters[api_key]', `${APIKey}`);
            formData.append('auth_token', `${APIKey}`);
            formData.append('is_default', `${autodefaulttoggle}`);
            formData.append('create_lead', `${autoleadstoggle}`);
        } else {
            formData.append('connectivity_parameters[token]', `${Crmtoken}`);
            formData.append('auth_token', `${usertoken}`);
            formData.append('is_default', `${autodefaulttoggle}`);
            formData.append('create_lead', `${autoleadstoggle}`);
        }
        return this.http.post(url, formData);
    }

    callEnableIntegration(crm_id: number) {
        const url = this.apiUrl + `/crms/${crm_id}/enable`;
        return this.http.post(url, {}, this.runLoadingHeader);
    }

    callDisableIntegration(crm_id: number) {
        const url = this.apiUrl + `/crms/${crm_id}/disable`;
        return this.http.post(url, {}, this.runLoadingHeader);
    }

    handleMemberCrmStatus(crm_id: number, memberId, action) {
        const url = this.apiUrl + `/crms/${crm_id}/member_handle`;
        const payload = {member_id: memberId, action: action};
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData);
    }

    callDeleteIntegration(crm_id: number) {
        const url = this.apiUrl + `/crms/${crm_id}`;
        return this.http.delete(url, this.runLoadingHeader);
    }

    callGetCompanyMembers(status = null) {
        let url = this.apiUrl + '/company/members';
        if (status) {
            url += `?status=${status}`;
        }
        return this.http.get(url);
    }


    callGetCompanyActiveMembers(status = 1) {
        const url = this.apiUrl + '/company/members?status=' + status;
        return this.http.get(url);
    }

    callGetCompanyQueues() {
        const url = this.apiUrl + '/queues';
        return this.http.get(url);
    }

    callGetCompanySettings() {
        const url = this.apiUrl + `/company/settings`;
        return this.http.get(url, this.runLoadingHeader);
    }

    callGetCompanyCancellationReasons() {
        const url = this.apiUrl + `/company/cancellation_reasons`;
        return this.http.get(url, this.runLoadingHeader);
    }

    callStoreSettings(payload: ISettingsRequest) {
        const url = this.apiUrl + `/company/settings`;
        const formData = this.generateEncryptedFormData(payload);
        // const formData = this.generateFormData(payload);
        return this.http.post(url, formData);
    }

    callDeactivateAccount(payload: IDeactivateAccount) {
        const url = this.apiUrl + `/company/company_deactivate`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    callGetDeactivateSettings() {
        const url = this.apiUrl + `/company/settings_deactivate`;
        return this.http.get(url);
    }

    callGetCompanyNumbers() {
        const url = this.apiUrl + '/company/numbers';
        return this.http.get(url, this.runLoadingHeader);
    }

    getCallFlows() {
        const url = this.apiUrl + `/voip/callflows`;
        return this.http.get(url, this.runLoadingHeader);
    }

    callGetCompanyActiveNumbersOutBoundList() {
        const url = this.apiUrl + '/company/active_numbers_outbound_list';
        return this.http.get(url, this.runLoadingHeader);
    }


    // callGetCompanyNumbersList() {
    //     const url = this.apiUrl + '/company/numbers_list';
    //     return this.http.get(url);
    // }

    callGetCompanyActiveNumbersList() {
        const url = this.apiUrl + '/company/active_numbers_list';
        return this.http.get(url);
    }


    callEditMemberPersonalInfo(memberId: number, payload: IMemberInfo) {
        const url = this.apiUrl + `/members/${memberId}`;
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callGetMemberSettings(memberId: number) {
        const url = this.apiUrl + `/members/${memberId}/settings`;
        return this.http.get(url, this.runLoadingHeader);
    }

    callUpdateMemberSettings(memberId: number, payload: IMemberSettingsRequest) {
        const url = this.apiUrl + `/members/${memberId}/settings`;
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callDeactivateMember(memberId: number) {
        const url = this.apiUrl + `/members/${memberId}/deactivate`;
        return this.http.get(url);
    }

    callClearSavedVmboxMessagesMember(memberId: number) {
        const url = this.apiUrl + `/members/${memberId}/settings/clear_saved_vmbox_messages`;
        return this.http.delete(url);
    }

    callActivateMember(memberId: number) {
        const url = this.apiUrl + `/members/${memberId}/activate`;
        return this.http.get(url);
    }

    callDeleteMember(memberId: number) {
        const url = this.apiUrl + `/members/${memberId}`;
        return this.http.delete(url);
    }

    callGetMemberFollowMe(memberId: number) {
        const url = this.apiUrl + `/members/${memberId}/follow_me`;
        return this.http.get(url);
    }

    callCancelInvite(memberId: number) {
        const url = this.apiUrl + `/members/${memberId}/cancel_invitation`;
        return this.http.get(url);
    }

    callResendInvite(memberId: number) {
        const url = this.apiUrl + `/members/${memberId}/resend_invitation_email`;
        return this.http.get(url);
    }

    callGetInvitationLink(memberId: number) {
        const url = this.apiUrl + `/members/${memberId}/get_invitation_link`;
        return this.http.get(url);
    }

    CallGetMemberExtensionDetails(memberId: number) {
        const url = this.apiUrl + `/members/${memberId}/extensions_details`;
        return this.http.get(url);

    }


    callResetPassword(memberId: number, newPassword: string) {
        const url = this.apiUrl + `/members/${memberId}/change_password`;
        // const formData = new FormData();
        // formData.append('new_password', `${newPassword}`);
        // formData.append('confirm_password', `${newPassword}`);
        const payload = {new_password: newPassword, confirm_password: newPassword};
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callUpdateMemberFollowMe(memberId: number, payload: IMemberFollowMe) {
        const url = this.apiUrl + `/members/${memberId}/follow_me`;
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callPrependNumberUpdate(number, prependNumber, status) {
        const url = this.apiUrl + `/numbers/prepend_number`;
        if (status) {
            const payload = {number: number, prepend: prependNumber, status: 1};
            const formData = this.generateEncryptedFormData(payload);
            return this.http.post(url, formData);
        } else {
            const payload = {number: number, prepend: prependNumber, status: 0};
            const formData = this.generateEncryptedFormData(payload);
            return this.http.post(url, formData);
        }

    }

    callGetPrependNumber(number) {
        const url = this.apiUrl + `/numbers/prepend_number/${number}`;
        return this.http.get(url);
    }


    callGetNotAssignedMember(teamId: number) {
        const url = this.apiUrl + `/teams/${teamId}/assign_members`;
        return this.http.get(url);
    }

    callAssignMember(teamId: number, membersIds: number[]) {
        const url = this.apiUrl + `/teams/${teamId}/assign_members`;
        // const formData = new FormData();
        // membersIds.forEach((id, index) => {
        //     formData.append(`members[${index}]`, `${id}`);
        // });
        // payload=
        const ids = [];
        membersIds.forEach((id, index) => {
            ids.push(id);
        });
        const payload = {members: membersIds};
        console.log(payload);
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callGetNotAssignedNumber(teamId: number) {
        const url = this.apiUrl + `/teams/${teamId}/assign_numbers`;
        return this.http.get(url);
    }

    callAssignNumber(teamId: number, numbersIds: string[]) {
        const url = this.apiUrl + `/teams/${teamId}/assign_numbers`;
        // const formData = new FormData();
        // numbersIds.forEach((id, index) => {
        //     formData.append(`did_id[${index}]`, `${id}`);
        // });

        const payload = {did_id: numbersIds};
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callGetMembersRoles() {
        const url = this.apiUrl + `/members/roles`;
        return this.http.get(url, this.runLoadingHeader);
    }

    callUpdateMembersRoles(memberId: number, newRoleId: number) {
        const url = this.apiUrl + `/members/${memberId}/role`;
        // const formData = new FormData();
        // formData.append('member_id', `${memberId}`);
        // formData.append('role_id', `${newRoleId}`);
        const payload = {member_id: memberId, role_id: newRoleId};
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callCreateNewMember(payload: IMemberRequest) {
        const url = this.apiUrl + `/members`;
        // const formData = this.generateFormData(payload);
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callGetMemberSeatCost() {
        const url = this.apiUrl + `/members/member_seat_cost`;
        return this.http.get(url);
    }

    getCompanyNumbers() {
        const url = this.apiUrl + `/company/numbers`;
        return this.http.get(url);
    }

    callDeleteNumber(payload: ITerminateNumber) {
        const url = this.apiUrl + `/vendor/did_terminate`;
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callGetDIDTeams(did_id: string) {
        const url = this.apiUrl + `/did/${did_id}/teams`;
        return this.http.get(url);
    }

    callGetDIDDetails(did_id: string) {
        const url = this.apiUrl + `/did/${did_id}/details`;
        return this.http.get(url);
    }

    callRemoveTeamFromNumber(did_id: string, teamId: number) {
        const url = this.apiUrl + `/did/${did_id}/teams/${teamId}`;
        return this.http.delete(url);
    }

    callAssignTeamForNumber(did_id: string, teams_ids: number[]) {
        const url = this.apiUrl + `/did/${did_id}/teams`;
        const payload = {teams: teams_ids};
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callGetTeamColors() {
        const url = this.apiUrl + `/teams/colors`;
        return this.http.get(url);
    }

    callUpdateTeamColors(team_id: number, color_Id: number) {
        const url = this.apiUrl + `/teams/${team_id}/colors`;
        // const formData = new FormData();
        // formData.append('team_id', `${team_id}`);
        // formData.append('team_color_id', `${color_Id}`);
        const payload = {team_id: team_id, team_color_id: color_Id};
        const formData = this.generateEncryptedFormData(payload);

        return this.http.post(url, formData);
    }

    callReplaceDID(did_id: string) {
        const url = this.apiUrl + '/vendor/replace_number';
        const formData = new FormData();
        formData.append('did_id', `${did_id}`);
        return this.http.post(url, formData);
    }

    callChangeCompanyPassword(payload: ICompanyChangePasswordRequest) {
        const url = this.apiUrl + `/members/change_password`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData);
    }

    callChangeCompanyOwner(member_id: number) {
        const url = this.apiUrl + `/company/change_owner`;
        const formData = new FormData();
        formData.append('member_id', `${member_id}`);
        return this.http.post(url, formData);
    }

    callUploadDocument(payload: IUploadDocument) {
        const url = this.apiUrl + `/did/documents`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData, {reportProgress: true, observe: 'events'});
    }


    getDashboardData() {
        const url = this.apiUrl + `/company/dashboard`;
        return this.http.get(url, this.runLoadingHeader);
    }


    callGetAllTeamsCallerId() {
        const url = this.apiUrl + `/teams/caller_id_settings`;
        return this.http.get(url);
    }

    getDeviceConfiguration() {
        const url = this.apiUrl + `/members/device_configuration`;
        return this.http.get(url, this.runLoadingHeader);
    }

    getWalletSettings() {
        const url = this.apiUrl + `/company/wallet_settings`;
        return this.http.get(url, this.runLoadingHeader);
    }

    callWalletUpdate(payload: IWalletRequest) {
        const url = this.apiUrl + `/company/wallet_settings`;
        const formData = this.generateEncryptedFormData(payload);
        return this.http.post(url, formData);
    }

    callGetTopupHistory() {
        const url = this.apiUrl + `/company/topup_history`;
        return this.http.get(url);
    }


    getCallflowByDidNumber(did_number: string) {
        const url = this.apiUrl + `/voip/callflow/did/${did_number}`;
        return this.http.get(url);
    }

    getTimeConditions() {
        const url = this.apiUrl + `/voip/time_conditions`;
        return this.http.get(url);
    }

    getTimeConditionsById(time_condition_id) {
        const url = this.apiUrl + `/voip/time_conditions/${time_condition_id}`;
        return this.http.get(url);
    }

    getCellPhoneDevices() {
        const url = this.apiUrl + `/voip/cell_phone_devices`;
        return this.http.get(url);
    }

    createCellPhoneDevices(payload: KazooCellPhoneDeviceRequest) {
        const url = this.apiUrl + `/voip/cell_phone_devices`;
        return this.http.post(url, this.generateFormData(payload));
    }

    callDeleteDevice(device_id) {
        const url = this.apiUrl + `/voip/cell_phone_devices/${device_id}`;
        return this.http.delete(url, this.runLoadingHeader);
    }

    callCellPhoneDevicesUpdate(device_id, payload: KazooCellPhoneDeviceRequest) {
        const url = this.apiUrl + `/voip/cell_phone_devices/${device_id}`;
        return this.http.post(url, this.generateFormData(payload), this.runLoadingHeader);
    }

    getVMBoxesList() {
        const url = this.apiUrl + `/voip/vmboxes`;
        return this.http.get(url);
    }

    // getMediaList() {
    //     const url = this.apiUrl + `/voip/media`;
    //     return this.http.get(url);
    // }

    callGetMediaList() {
        const url = this.apiUrl + `/media`;
        return this.http.get(url);
    }

    uploadMedia(payload: KazooMediaUpload) {
        const url = this.apiUrl + `/voip/media`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData, {reportProgress: true, observe: 'events'});
    }

    getCallflow(callFlowId: string) {
        const url = this.apiUrl + `/voip/callflow/${callFlowId}`;
        return this.http.get(url);
    }

    updateCallflow(flow: KazooFlowRequest) {
        const url = this.apiUrl + `/voip/callflow/${flow.data.id}`;
        return this.http.post(url, flow.data);
    }

    createIvr(ivr: KazooIvrRequest) {
        const url = this.apiUrl + `/voip/ivr`;
        return this.http.post(url, ivr);
    }

    updateIvr(ivr: KazooIvrRequest, ivr_uuid: string) {
        const url = this.apiUrl + `/voip/ivr/${ivr_uuid}`;
        return this.http.post(url, ivr);
    }

    getIvrById(id: string) {
        const url = this.apiUrl + `/voip/ivr/${id}`;
        return this.http.get(url);
    }

    getInvoiceList() {
        const url = this.apiUrl + `/invoices`;
        return this.http.get(url);
    }


    renewNumber(did_id) {
        const url = this.apiUrl + `/did/renew`;
        const formData = new FormData();
        formData.append('did_id', `${did_id}`);
        return this.http.post(url, formData);
    }

    changePlan(price_id) {
        const url = this.apiUrl + `/subscriptions/plan_change`;
        const formData = new FormData();
        const payload = {'price_id': price_id};
        const data = this.generateEncryptedFormData(payload);
        return this.http.post(url, data, this.runLoadingHeader);
    }

    getPlans() {
        const url = this.apiUrl + `/subscriptions/v2/plans`;
        return this.http.get(url, this.runLoadingHeader);
    }

    getPaymentMethod() {
        const url = this.apiUrl + `/subscriptions/payment_method`;
        return this.http.get(url);
    }

    getSubscription() {
        const url = this.apiUrl + `/subscriptions`;
        return this.http.get(url, this.runLoadingHeader);
    }

    addPaymentMethod(payload: IPaymentMethodRequest) {
        const url = this.apiUrl + `/subscriptions/create_payment_method`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    replacePaymentMethod(payload: IPaymentMethodRequest) {
        const url = this.apiUrl + `/subscriptions/payment_method`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    getReportFilter() {
        const url = this.apiUrl + `/cdrs/get_report_filter`;
        return this.http.post(url, {});
    }

    getCustomReportStatus() {
        const url = this.apiUrl + `/reports/custom_report_status`;
        return this.http.get(url, {});
    }

    reportExportIsActive() {
        const url = this.apiUrl + `/reports/report_export_is_Active`;
        return this.http.get(url, {});
    }

    getVoiceMails(payload: IVoiceMailRequest) {
        const url = this.apiUrl + `/cdrs/get_voice_mail`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    getFinancialDashboard(payload: any) {
        const url = this.apiUrl + `/cdrs/get_financial_dashboard`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData);
    }

    getDashboardCdrs(payload: any) {
        const url = this.apiUrl + `/cdrs/get_dashboard_cdrs`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData);
    }

    getInboundCdrs(payload: ICallsCDRRequest) {
        const url = this.apiUrl + `/cdrs/get_inbound_cdrs`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData);
    }

    getOutboundCdrs(payload: ICallsCDRRequest) {
        const url = this.apiUrl + `/cdrs/get_outbound_cdrs`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData);
    }

    getInternalCdrs(payload: ICallsCDRRequest) {
        const url = this.apiUrl + `/cdrs/get_internal_cdrs`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData);
    }

    getInboundCdrsPdf(payload: ICallsCDRRequest) {
        const url = this.apiUrl + `/reports/inbound`;
        // const url = this.reportsUrl + `/pdf/inbound`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData, {responseType: 'blob'});
    }

    getOutboundCdrsPdf(payload: ICallsCDRRequest) {
        const url = this.apiUrl + `/reports/outbound`;
        // const url = this.reportsUrl + `/pdf/outbound`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData, {responseType: 'blob'});
    }

    getInternalCdrsPdf(payload: ICallsCDRRequest) {
        const url = this.apiUrl + `/reports/internal`;
        // const url = this.reportsUrl + `/pdf/internal`;
        const formData = this.generateFormData(payload);
        return this.http.post(url, formData, {responseType: 'blob'});
    }

    deactivateCompanyAccount() {
        const url = this.apiUrl + `/company/company_deactivate`;
        return this.http.get(url);
    }

    getCompanyUpcomingInvoices() {
        const url = this.apiUrl + `/subscriptions/upcoming_invoice`;
        return this.http.get(url);
    }

    payInvoice(invoiceId) {
        const url = this.apiUrl + `/subscriptions/pay_invoice`;
        const formData = new FormData();
        formData.append('invoice_id', `${invoiceId}`);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    refreshToken() {
        const url = this.apiUrl + `/members/token`;
        const formData = new FormData();
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    getCs() {
        const url = this.apiUrl + `/control/list`;
        return this.http.get(url, this.runLoadingHeader);
    }

    get_companies_activation_status() {
        const url = this.apiUrl + `/control/companies_activation_status`;
        return this.http.get(url, this.runLoadingHeader);
    }


    impersonate(id) {
        const url = this.apiUrl + `/control/impersonate`;
        const formData = new FormData();
        formData.append('id', `${id}`);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    updateCompanyActivation(company_id, status) {
        const url = this.apiUrl + `/control/update_company_activation`;
        const formData = new FormData();
        formData.append('id', `${company_id}`);
        formData.append('status', `${status}`);
        return this.http.post(url, formData, this.runLoadingHeader);
    }


    suspendCompany(company_id) {
        const url = this.apiUrl + `/control/suspend`;
        const formData = new FormData();
        formData.append('company_id', `${company_id}`);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    unsuspendCompany(company_id) {
        const url = this.apiUrl + `/control/unsuspend`;
        const formData = new FormData();
        formData.append('company_id', `${company_id}`);
        return this.http.post(url, formData, this.runLoadingHeader);
    }


    getNumberUsedBy(didNumber) {
        const url = this.apiUrl + `/members/members_using_did`;
        const formData = new FormData();
        formData.append('did_number', `${didNumber}`);
        return this.http.post(url, formData, this.runLoadingHeader);
    }


    getSetupIntent(paymentMethod) {
        const url = this.apiUrl + `/subscriptions/setup_intent`;
        // return this.http.get(url);
        const formData = new FormData();
        formData.append('payment_method', `${paymentMethod}`);
        return this.http.post(url, formData, this.runLoadingHeader);

    }

    getPaymentIntent() {
        const url = this.apiUrl + `/subscriptions/payment_intent`;
        // return this.http.get(url);
        const formData = new FormData();
        // formData.append('payment_method', `${payload}`);
        return this.http.post(url, formData, this.runLoadingHeader);

    }


    setDefaultPaymentMethod(payment_method) {
        const url = this.apiUrl + `/subscriptions/default_payment_method`;
        const formData = new FormData();
        formData.append('payment_method', `${payment_method}`);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    getQueueStats(timezone, queue_uid = null) {
        const url = this.apiUrl + `/queues/stats`;
        const formData = new FormData();
        formData.append('time_zone', timezone);
        if (queue_uid !== null) {
            formData.append('queue_id', queue_uid);
        }
        return this.http.post(url, formData);
    }

    getQueueSettings(queue_uid) {
        const url = this.apiUrl + `/queues/${queue_uid}/setting`;
        return this.http.get(url, this.runLoadingHeader);
    }

    createNewQueue(data) {
        // console.log('data', data);
        const url = this.apiUrl + `/queues`;
        const formData = this.generateFormData(data);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    updateQueue(queue_uid, data) {
        const url = this.apiUrl + `/queues/${queue_uid}/setting`;
        const formData = this.generateFormData(data);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    queueAssignMembers(queue_uid, members) {

        const url = this.apiUrl + `/queues/${queue_uid}/add_remove_agent`;
        const formData = new FormData();
        // console.log('agent_list', members);
        const payload = {'agent_list': members};
        // console.log('formData', formData);
        return this.http.post(url, payload, this.runLoadingHeader);
    }

    // queueAssignMembers(queue_uid, members, agents_login_manually) {
    //     const url = this.apiUrl + `/queues/${queue_uid}/add_remove_agent`;
    //     const formData = new FormData();
    //     console.log('agent_list', members);
    //     const payload = {'agent_list': members, 'agents_login_manually': agents_login_manually};
    //     console.log('formData', formData);
    //     return this.http.post(url, payload, this.runLoadingHeader);
    // }

    deleteQueue(queue_uid) {
        const url = this.apiUrl + `/queues/${queue_uid}`;
        return this.http.delete(url, this.runLoadingHeader);
    }

    callUpdateQueueColors(queue_id: string, queue_color: string) {
        const url = this.apiUrl + `/queues/${queue_id}/colors`;
        // const formData = new FormData();
        // formData.append('queue_id', `${queue_id}`);
        // formData.append('queue_color', `${queue_color}`);
        const payload = {queue_id: queue_id, queue_color: queue_color};
        const formData = this.generateEncryptedFormData(payload);

        return this.http.post(url, formData);
    }

    /*queueAgentLogin(queue_id, data) {
        const url = this.apiUrl + `/queues/agents/login`;
        const payload = {
            'queue_id': queue_id,
            'members': data
        };
        return this.http.post(url, payload, this.runLoadingHeader);
    }*/

    breakStatuses() {
        const url = this.apiUrl + `/queues/break_statuses`;
        return this.http.get(url, this.runLoadingHeader);
    }

    agentBreaks(agent_id) {
        const url = this.apiUrl + `/queues/agents/${agent_id}/breaks`;
        return this.http.get(url, this.runLoadingHeader);
    }

    queuesByAgent(agent_id) {
        const url = this.apiUrl + `/queues/agents/${agent_id}`;
        return this.http.get(url, this.runLoadingHeader);
    }

    queueAgentLogin(agent_id, queue_id, action) {
        const url = this.apiUrl + `/queues/agents/${agent_id}/${action}`;
        const payload = {
            'queue_id': queue_id,
            'action': action
        };
        return this.http.post(url, payload, this.runLoadingHeader);
    }

    queueAgentBreak(breakCode) {
        const url = this.apiUrl + `/queues/agent_break`;
        const payload = {
            'break_code': breakCode,
        };
        return this.http.post(url, payload, this.runLoadingHeader);
    }

    queueAgentResume() {
        const url = this.apiUrl + `/queues/agent_resume`;
        const payload = {};
        return this.http.post(url, payload, this.runLoadingHeader);
    }

    createBreakStatus(data) {
        const url = this.apiUrl + `/queues/break_statuses`;
        const payload = {
            'break_name': data.break_name,
            'work_break': data.work_break,
            'break_icon': data.break_icon,
        };
        return this.http.post(url, payload, this.runLoadingHeader);
    }

    editBreakStatus(data, id) {
        const url = this.apiUrl + `/queues/break_statuses/${id}`;
        const payload = {
            'break_name': data.break_name,
            'work_break': data.work_break,
            'break_icon': data.break_icon,
        };
        return this.http.post(url, payload, this.runLoadingHeader);
    }

    deleteBreakStatus(id) {
        const url = this.apiUrl + `/queues/break_statuses/${id}`;

        return this.http.delete(url, this.runLoadingHeader);
    }

    setBreakSettings(data) {
        const url = this.apiUrl + `/queues/break_setting`;
        const payload = {
            'allow_break': data.allow_break,
            'allowed_break_minutes': data.allowed_break_minutes,
        };
        return this.http.post(url, payload, this.runLoadingHeader);
    }

    getBreakSettings() {
        const url = this.apiUrl + `/queues/break_setting`;
        return this.http.get(url, this.runLoadingHeader);
    }

    getRates(data) {
        const url = this.apiUrl + `/billing/get_company_rates_by_code`;
        const payload = {
            'code': data.countryCode,
        };
        const formData = this.generateFormData(data);
        // return this.http.post(url, payload, this.runLoadingHeader);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    getAllCustomWorkingHours() {
        const url = this.apiUrl + `/custom_working_hours`;
        return this.http.get(url, this.runLoadingHeader);
    }

    createCustomWorkingHours(data) {
        const url = this.apiUrl + `/custom_working_hours`;
        const formData = this.prepareFormDataForCustomWorkingHours(data);
        console.log('formData', formData);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    getCustomWorkingHoursDetails(kz_business_hour_set_uuid) {
        const url = this.apiUrl + `/custom_working_hours/${kz_business_hour_set_uuid}`;
        return this.http.get(url, this.runLoadingHeader);
    }

    updateCustomWorkingHours(kz_business_hour_set_uuid, data) {
        const url = this.apiUrl + `/custom_working_hours/${kz_business_hour_set_uuid}`;
        const formData = this.prepareFormDataForCustomWorkingHours(data);
        return this.http.post(url, formData, this.runLoadingHeader);
    }

    deleteCustomWorkingHoursDetails(kz_business_hour_set_uuid) {
        const url = this.apiUrl + `/custom_working_hours/${kz_business_hour_set_uuid}`;
        return this.http.delete(url, this.runLoadingHeader);
    }

    // prepareFormDataForCustomWorkingHours(payload: any) {
    //     const formData = new FormData();
    //     const keys = Object.keys(payload);
    //     for (let i = 0; i < keys.length; i++) {
    //         const key = keys[i];
    //         let value;
    //         if (Array.isArray(payload[key])) {
    //             const array = payload[key];
    //             for (let j = 0; j < array.length; j++) {
    //                 value = array[j];
    //                 formData.append(key[i], value);
    //             }
    //         } else {
    //             value = payload[key];
    //             formData.append(key, value);
    //         }
    //     }
    //     return formData;
    // }

    prepareFormDataForCustomWorkingHours(obj: any, formData?: FormData, parentKey?: string): FormData {
        formData = formData || new FormData();
        // debugger;
        for (const property in obj) {
            if (obj.hasOwnProperty(property)) {
                const formKey = parentKey ? `${parentKey}[${property}]` : property;
                const value = obj[property];

                if (!parentKey && Array.isArray(value)) {
                    value.forEach((item, index) => {
                        const arrayKey = `${formKey}[${index}]`;

                        if (!parentKey && typeof item === 'object' && item !== null) {
                            this.prepareFormDataForCustomWorkingHours(item, formData, arrayKey);
                        } else {
                            formData.append(arrayKey, Array.isArray(item) ? `[${item}]` : item);
                        }
                    });
                } else if (!parentKey && typeof value === 'object' && value !== null) {
                    this.prepareFormDataForCustomWorkingHours(value, formData, formKey);
                } else {
                    formData.append(formKey, Array.isArray(value) ? `[${value}]` : value);
                }
            }
        }

        return formData;
    }

    // getCallerIdOptions() {
    //
    //     const options = [
    //         {
    //             value: 0,
    //             label: this.localizationService.translate('custom'),
    //             selected: false,
    //         },
    //         {
    //             value: 1,
    //             label: this.localizationService.translate('default'),
    //             selected: false,
    //         },
    //     ];
    //     return options;
    // }

    // getInsightFeaturesSettings() {
    //     const url = this.apiUrl + `/members/members_using_did`;
    //
    // }
}
