import {Component, Input, Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '@app/_services/api.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {finalize} from 'rxjs/operators';
import {KazooCellPhoneDeviceRequest, KazooCreateCellPhoneDeviceResponse} from '@app/@core/interfaces/kz.interface';
import {combineLatest, Subscription} from 'rxjs';
import {IOption} from '@app/@core/interfaces';

@Component({
    selector: 'app-edit-cellphone-modal',
    templateUrl: './edit-cellphone-modal.component.html',
    styleUrls: ['./edit-cellphone-modal.component.scss']
})
export class EditCellphoneModalComponent implements OnInit, OnDestroy {


    @Input() device: any;
    @Output()
    editEvent: EventEmitter<KazooCreateCellPhoneDeviceResponse> = new EventEmitter<KazooCreateCellPhoneDeviceResponse>();
    subscription$: Subscription;

    form: FormGroup;
    device_name: any;
    phone_number: any;
    loading: any;
    followMeStatusChecked = false;
    followMeStatus = 0;
    require_keypress = false;
    allow_recording = false;
    countriesOptions: IOption[] = [];
    flag;
    countryPrefix: any;

    constructor(private fb: FormBuilder,
                private apiService: ApiService,
                private toasterService: ToastrService,
                public bsModalRef: BsModalRef,
                private bsModalService: BsModalService) {
    }

    ngOnInit() {
        console.log('device', this.device);
        this.device_name = this.device.device_name;
        this.phone_number = this.device.phone_number;
        this.allow_recording = this.device.call_recording;
        this.require_keypress = this.device.require_keypress;
        this.countryPrefix = this.device.country_prefix;
        this.initForm();
        this.initData();
    }

    initData() {
        combineLatest(
            this.apiService.getCountries(),
        ).subscribe(([countriesResponse]: [any]) => {
            if (countriesResponse.success) {
                this.countriesOptions = this.generateIOption(countriesResponse.data, 'country_id', 'country_name');
            }
            this.watchCountryFlag();
        }, (err) => {
            this.toasterService.error(err.error.message);
        });
    }

    generateIOption(items: any[], valueKey: string, labelKey: string) {
        let newItems = [];
        if (items) {
            newItems = items.map(i => {
                return {
                    label: i[labelKey],
                    value: i[valueKey],
                    selected: false,
                    data: i,
                } as IOption;
            });
        }
        return newItems;
    }

    private initForm() {
        this.form = this.fb.group({
            device_name: [this.device_name, [Validators.required]],
            // phone_number: [this.phone_number, [Validators.required]],
            follow_me_num: [this.phone_number, [Validators.required, Validators.minLength(9), Validators.maxLength(13)]],
            follow_me_country_prefix: [null, [Validators.required]],
            countryId: [null, [Validators.required]],
            require_keypress: [this.require_keypress],
            allow_recording: [this.allow_recording],
        });
    }

    save() {
        if (this.form.invalid) {
            this.toasterService.warning('Please fill all required fields.');
            return;
        }
        this.loading = true;
        const payload: KazooCellPhoneDeviceRequest = {
            device_name: this.form.get('device_name').value,
            // phone_number: this.form.get('phone_number').value,
            phone_number: this.form.value.follow_me_num,
            country_prefix: this.form.value.follow_me_country_prefix,
            // follow_me_num: this.form.value.follow_me_num,
            // follow_me_status: this.followMeStatus,
            require_keypress: this.form.value.require_keypress,
            call_recording: this.form.value.allow_recording
        };

        this.apiService.callCellPhoneDevicesUpdate(this.device.device_id, payload)
            .pipe(
                finalize(() => {
                    this.loading = false;
                }),
            ).subscribe((response: any) => {
            if (response.success) {
                this.toasterService.success('Success');
                this.editEvent.emit({
                    ...response.data,
                    call_recording: this.form.value.allow_recording,
                    country_flag: this.flag
                });
                this.bsModalRef.hide();


            } else {
                this.toasterService.warning(response.message);
            }
        }, (error) => {
            this.toasterService.error(error.error.message);
        });
    }


    toggleVisibility(e) {
        this.followMeStatusChecked = e.target.checked;

        if (this.followMeStatusChecked) {
            this.followMeStatus = 1;
        } else {
            this.followMeStatus = 0;
        }
    }

    toggleRequire_keypress(e) {
        this.require_keypress = e.target.checked;
        if (this.require_keypress && this.require_keypress === true) {
            this.require_keypress = false;
        } else {
            this.require_keypress = false;
        }
    }

    toggleAllowRecording(e) {
        this.allow_recording = e.target.checked;
        if (this.allow_recording && this.allow_recording === true) {
            this.allow_recording = false;
        } else {
            this.allow_recording = false;
        }
    }


    watchCountryFlag() {
        this.form.get('countryId').valueChanges.subscribe(value => {
            const selectedCountry = this.countriesOptions.find((o) => o.value === value);
            if (!!selectedCountry) {
                this.flag = selectedCountry.data.flag;
                this.form.get('follow_me_country_prefix').setValue(selectedCountry.data.country_prefix);
            }
        });

        this.form.get('follow_me_country_prefix').valueChanges.subscribe(value => {
            const selectedCountry = this.countriesOptions.find((o) => o.data && o.data.country_prefix === `${value}`);
            const currenctCountryValue = this.form.get('countryId').value;
            if (selectedCountry && !currenctCountryValue) {
                this.form.get('countryId').setValue(selectedCountry.value);
            }
        });
        this.form.get('follow_me_country_prefix').setValue(this.countryPrefix);
    }




    close(noChange = false) {
        if (noChange) {
            // this.bsModalService.hide();
            // this.bsModalService.removeBackdrop();
            // this.bsModalService._hideModal(5);
        }
        this.bsModalRef.hide();
        // this.bsModalRef;
    }

    ngOnDestroy() {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }


}
