import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';


import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AgentLayoutComponent } from './layouts/agent-layout/agent-layout.component';

import { NgbModule, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { SharedModule } from './@shared/shared.module';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { UnsavedChangesModalComponent } from './@shared/components/unsaved-changes-modal/unsaved-changes-modal.component';
import { PendingChangesGuard } from './_helpers/pending-changes.guard';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DownloadAppPopupComponent } from './pages/dashboard/components/download-app-popup/download-app-popup.component';
import { QRCodeModule } from 'angularx-qrcode';
import { EditTeamNameComponent } from './pages/teams-and-members/components/edit-team-name/edit-team-name.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
// import { AgentDashboardComponent } from './pages/agent-dashboard/agent-dashboard.component';
import { CallerIdUnsavedChangesModalComponent } from '@app/pages/caller-id/components/caller-id-unsaved-changes-modal/caller-id-unsaved-changes-modal.component';
import { ConfirmationModalComponent } from './@shared/components/confirmation-modal/confirmation-modal.component';
import { MemberSelectComponent } from './pages/call-flow/components/modals/member-select/member-select.component';
import { QueueSelectComponent } from './pages/call-flow/components/modals/queue-select/queue-select.component';
import { WorkingHoursComponent } from './pages/call-flow/components/modals/working-hours/working-hours.component';
import {CallForwardComponent} from './pages/call-flow/components/modals/call-forward/call-forward.component';
import { SelectMediaComponent } from './pages/call-flow/components/modals/select-media/select-media.component';
import { PlayMediaComponent } from './pages/call-flow/components/modals/play-media/play-media.component';
import { SelectTeamComponent } from './pages/call-flow/components/modals/select-team/select-team.component';
import { ManageMembersModalComponent } from './pages/call-flow/components/modals/manage-members-modal/manage-members-modal.component';
import { IVRSettingsComponent } from './pages/call-flow/components/modals/ivr-settings/ivr-settings.component';
import { VMBoxSelectComponent } from './pages/call-flow/components/modals/vmbox-select/vmbox-select.component';
import { PlayAudioModalComponent } from './@shared/components/play-audio/play-audio.component';
import { RequestCustomReportComponent } from './pages/reports/request-custom-report/request-custom-report.component';
import { AddCardPopupComponent } from './pages/settings/components/add-card-popup/add-card-popup.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NumbersUsedByComponent } from './pages/numbers/components/numbers-used-by/numbers-used-by.component';
import { BuyNumberModalComponent } from './pages/numbers/components/buy-number-modal/buy-number-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { LottieAnimationViewModule } from 'ng-lottie';
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '@environments/environment';
import { DeactivateReasonsModalComponent } from '@app/pages/settings/components/deactivate-reasons-modal/deactivate-reasons-modal.component';
import { BreaksListComponent } from './pages/breaks/components/breaks-list/breaks-list.component';
import { AmazingTimePickerModule } from 'amazing-time-picker';

import { InternationalizationModule } from './internationalization/internationalization.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {WebhooksComponent} from '@app/pages/call-flow/components/modals/webhooks/webhooks.component';
import {
    AdvancedWorkingHoursTimezoneComponent
} from '@app/pages/call-flow/components/modals/advanced-working-hours-timezone/advanced-working-hours-timezone.component';
import {LoadingInterceptor} from '@app/_helpers/loading.interceptor';
import {CallFlowSelectComponent} from '@app/pages/call-flow/components/modals/call-flow/call-flow-select.component';

// export function TranslationLoaderFactory(http: HttpClient) {
//     return new TranslateHttpLoader(http);
// }


/**
 * The http loader factory : Loads the files from define path.
 * @param {HttpClient} http
 * @returns {TranslateHttpLoader}
 * @constructor
 */
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}


@NgModule({
    imports: [
        FormsModule,
        NgxPermissionsModule.forRoot(),
        ReactiveFormsModule,
        BrowserAnimationsModule,
        BrowserModule,
        RecaptchaModule.forRoot(),
        RecaptchaFormsModule,
        HttpClientModule,
        ComponentsModule,
        AmazingTimePickerModule,
        NgbModule,
        NgbButtonsModule,
        RouterModule,
        AppRoutingModule,
        NgxMaskModule.forRoot(),
        SharedModule,
        InternationalizationModule.forRoot({ locale_id: 'en' }), // iniating with default language: en-US
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
            toastClass: 'toast ngx-toastr dsk-toast',
            positionClass: 'toast-top-center',
            preventDuplicates: true,
        }),
        ModalModule.forRoot(),
        NgxSkeletonLoaderModule,
        QRCodeModule,
        NgxDatatableModule,
        AngularMyDatePickerModule,
        LottieAnimationViewModule.forRoot(),
        Ng2DeviceDetectorModule.forRoot(),
        NgxStripeModule.forRoot(environment.s_key),

    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        AgentLayoutComponent,
        UnsavedChangesModalComponent,
        ConfirmationModalComponent,
        BuyNumberModalComponent,
        CallerIdUnsavedChangesModalComponent,
        EditTeamNameComponent,
        DownloadAppPopupComponent,
        MemberSelectComponent,
        QueueSelectComponent,
        WorkingHoursComponent,
        SelectMediaComponent,
        PlayMediaComponent,
        SelectTeamComponent,
        ManageMembersModalComponent,
        IVRSettingsComponent,
        CallForwardComponent,
        VMBoxSelectComponent,
        PlayAudioModalComponent,
        RequestCustomReportComponent,
        AddCardPopupComponent,
        NumbersUsedByComponent,
        DownloadAppPopupComponent,
        DeactivateReasonsModalComponent,
        WebhooksComponent,
        AdvancedWorkingHoursTimezoneComponent,
        CallFlowSelectComponent,
        // AgentDashboardComponent,
        // TrialEndedComponent,
        // PaymentFailedComponent,
    ],
    providers: [{
        useClass: JwtInterceptor,
        multi: true,
        provide: HTTP_INTERCEPTORS,
    },
    {
        useClass: LoadingInterceptor,
        multi: true,
        provide: HTTP_INTERCEPTORS,
    },
        BsModalRef,
        PendingChangesGuard,
    {
        provide: RECAPTCHA_SETTINGS,
        useValue: {
            siteKey: '6Lce0e8ZAAAAANEKDkQTBHx1x8KkC4AokHQiiD1m'
        },
    }
    ],
    entryComponents: [
        UnsavedChangesModalComponent,
        DownloadAppPopupComponent,
        CallerIdUnsavedChangesModalComponent,
        ConfirmationModalComponent,
        EditTeamNameComponent,
        MemberSelectComponent,
        QueueSelectComponent,
        WorkingHoursComponent,
        SelectMediaComponent,
        PlayMediaComponent,
        SelectTeamComponent,
        ManageMembersModalComponent,
        IVRSettingsComponent,
        CallForwardComponent,
        VMBoxSelectComponent,
        PlayAudioModalComponent,
        RequestCustomReportComponent,
        AddCardPopupComponent,
        NumbersUsedByComponent,
        BuyNumberModalComponent,
        EditTeamNameComponent,
        DeactivateReasonsModalComponent,
        WebhooksComponent,
        AdvancedWorkingHoursTimezoneComponent,
        CallFlowSelectComponent,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
