import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-caller-id-unsaved-changes-modal',
  templateUrl: './caller-id-unsaved-changes-modal.component.html',
  styleUrls: ['./caller-id-unsaved-changes-modal.component.scss']
})
export class CallerIdUnsavedChangesModalComponent implements OnInit {
  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
  ) {
  }

  ngOnInit() {
  }

  closeModal() {
    this.bsModalService.setDismissReason('close');
    this.bsModalRef.hide();
  }

  ignoreChanges() {
    this.bsModalService.setDismissReason('ignoreChanges');
    this.bsModalRef.hide();
  }
}
