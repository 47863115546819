import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IOption } from '@app/@core/interfaces';
import { isEqual } from 'lodash';
import { PopoverService } from './popover.service';

@Component({
  selector: 'dsk-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, OnChanges {

  @ViewChild('popover') popover;

  @Input() control: FormControl;
  _control = new FormControl();
  @Input() placeholder: string;
  @Input() key: string;
  @Input() disabled = false;
  @Input() options: IOption[];
  filteedOptions: IOption[] = [];
  _valueSelected = false;
  @Input() typeStyle = 'basic';
  @Input() noMargin = false;
  @Input() searchable = false;
  @Input() isMonthFilter = false;
  isFoucs = false;
  @Input() customRequiredErrorMessage;
  @Input() submitted = false;

  constructor(private popoverService: PopoverService) { }

  ngOnInit() {
    this.watchInputChanges();
    this.prepareDefaultValue();
    this.popoverService.addPopover(this.popover);
  }

  ngOnChanges(change: SimpleChanges) {
    if (this.filteedOptions.length === 0 || this.isMonthFilter) {
      this.filteedOptions = this.options;
    }
    if (!change.control || (change.control && (!isEqual(change.control.currentValue, change.control.previousValue)))) {
      this.prepareDefaultValue();
      this.control.markAsUntouched();
      this._control.markAsUntouched();
    }
  }

  prepareDefaultValue() {
    const defaultValue = this.control && this.control.value || null;
    this.setSelectedOption(defaultValue);
  }

  watchInputChanges() {
    this.resetOptions();
    this._control.valueChanges.subscribe((value: string) => {
      if (value && !this._valueSelected) {
        this.filteedOptions = this.options && this.options.filter((o) => o.label && o.label.toLowerCase().includes(String(value).toLowerCase())) || [];
      } else {
        this.resetOptions();
      }
      this._valueSelected = false;
    });

    this.control.valueChanges.subscribe((value: string) => {
      this.setSelectedOption(value);
    });
  }

  setSelectedOption(value: string) {
    const selectedOption = this.options && this.options.find(o => o.value === value);
    if (selectedOption) {
      this._valueSelected = true;
      this._control.setValue(selectedOption.label, { emitEvent: false });
      this.markOptionAsSelected(selectedOption);
    } else {
      this.resetOptions();
    }
  }

  markOptionAsSelected(selectedOption: IOption) {
    this.options.forEach(o => {
      if (o.value === selectedOption.value) {
        o.selected = true;
      } else {
        o.selected = false;
      }
    });
  }

  onClosePopover() {
    this.isFoucs = false;
    this.resetOptions();
    this.prepareDefaultValue();
    this._valueSelected = false;
  }

  onOpenPopover() {
    if (!this.disabled) {
      this._control.setValue('', { emitEvent: false });
    }
  }

  resetOptions() {
    this.filteedOptions = this.options ? [...this.options] : [];
    this._valueSelected = false;
    this._control.setValue(null, { emitEvent: false });
  }

  onSelectOption(option: IOption, event) {
    if (event) {
      event.stopPropagation();
    }
    if (!!option.value) {
      this._valueSelected = true;
      this.control.setValue(option.value);
      if (this.popover !== undefined) {
        this.popover.close();
      }
    }
  }

  openPopover(popover, event) {
    if (event) {
      event.stopPropagation();
    }
    if (!this.disabled && !this.isFoucs) {
      popover.toggle({popover});
    }
  }

  closePopover(popover, event) {
    this.isFoucs = false;
    if (event) {
      event.stopPropagation();
    }
    if (event.relatedTarget && event.relatedTarget.localName !== 'modal-container') {
      popover.close();
    }
  }

  openPopoverOnFocus(popover, event) {
    this.isFoucs = true;
    if (event) {
      event.stopPropagation();
    }
    if (!this.disabled) {
      // close all opened popovers
      if (event.relatedTarget && event.relatedTarget.localName !== 'modal-container') {
        this.popoverService.popovers.forEach(p => p && p.isOpen() && p.close());
      }
      popover.open();
    }
  }
}
