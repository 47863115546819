import {Component, OnInit, ViewChild, ElementRef, Optional} from '@angular/core';
import {
    Inviter,
    RegistererState,
    UserAgentState,
    Invitation,
    UserAgent,
    Registerer,
    SessionState,
    Info,
    InviterInviteOptions,
    SessionInviteOptions
} from 'sip.js';
import {combineLatest} from 'rxjs';
import {ApiService} from '@app/_services/api.service';
import {finalize} from 'rxjs/operators';
import {environment} from '@environments/environment';
import {OneWayService} from '@app/_services/one-way.service';

let status: any;
let statusbackup: any;

@Component({
    selector: 'app-dialer',
    templateUrl: './dialer.component.html',
    styleUrls: ['./dialer.component.scss']
})
export class DialerComponent implements OnInit {

    @ViewChild('search')
    set search(element: ElementRef<HTMLInputElement>) {
        if (element) {
            element.nativeElement.focus();
        }
    }

    userAgent: any;
    connectionstatus: any = 'Connecting';
    dialnumber: any = '';
    calltransfernumber: any = '';
    dtmfnumber: any = '';
    callstatus: any = 'No Call';
    // sipshow = 'close';
    // sipshow = 'offlineopen';
    sipshow = 'close';
    registerer: any;
    username: any;
    passwrd: any;
    proxy: any;
    domain: any;
    usersipcallhistory = [];
    callhistorydata = [];
    togglemute: any = false;
    held: any = true;
    expandtrue = true;
    timer: any;
    callengagednumber;
    callengagedname;
    dialpadshow = true;
    historyshow = false;
    incomingdailnumber;
    ctrs: any;
    dialname: any;
    incomingdialname: any;
    calltrue = false;
    expandcall = true;
    pickupcallshow = false;
    pausepress = false;
    transfervalidate = true;
    mutepress = false;
    transferpress = false;
    calldialpad = false;
    inputcalltransfervalidate = true;
    calltransfermemberid: any = '';
    incomingcalltrue = false;
    dialeroutput: any;
    manualcheckbox = false;
    calltransclick: any;
    calltransfernumbersend: any;
    calltransfershow = false;
    historybackup = [];
    reconnectionAttempts = 3;
    reconnectionDelay = 4;
    attemptingReconnection = false;
    shouldBeConnected = true;
    customerrormessage: any;
    callbuttonvalue = false;
    mobNumberPattern = '[0-9,*,#]+';
    expandDialer = '';

    constructor(
        @Optional() private oneWay: OneWayService,
        @Optional() private apiService: ApiService
    ) {

    }


    ngOnInit(): void {
        console.log('dialer start');

        // this.expand('close');
        combineLatest(
            this.apiService.getDeviceConfiguration()
        ).pipe(
            finalize(() => {
            })
        )
            .subscribe(([deviceConfigurationResponse]: [any]) => {
                if (deviceConfigurationResponse.success) {
                    this.username = deviceConfigurationResponse.data.username;
                    this.passwrd = this.oneWay.decrypt(JSON.parse(localStorage.getItem('call_key')));
                    this.domain = deviceConfigurationResponse.data.domain;
                    this.proxy = deviceConfigurationResponse.data.proxy;
                    localStorage.setItem('currentUserName', JSON.stringify(deviceConfigurationResponse.data.username));
                    localStorage.setItem('currentUserDomainLink', JSON.stringify(this.domain));
                    localStorage.setItem('currentUserServerLink', JSON.stringify(this.proxy));
                    // check expand dialer
                    if (localStorage.getItem(`expandDialer`)) {
                        this.expandDialer = localStorage.getItem(`expandDialer`);
                        if (this.expandDialer && this.expandDialer === 'open') {
                            this.sipshow = 'open';
                        }
                    }

                    this.userAgent = new UserAgent({
                        authorizationPassword: this.passwrd,
                        authorizationUsername: this.username,
                        transportOptions: {
                            server: `${environment.dialerapiUrl}`,
                            // server: "wss://dev-kz.talkchief.io:5065",
                        },
                        uri: UserAgent.makeURI('sip:' + `${this.username}` + '@' + `${this.domain}`),
                    });
                    this.userAgent.delegate = {
                        onConnect: (): void => {
                            console.log('DIALER ON CONNECT ? ');
                        },
                        onDisconnect: (): void => {
                            this.connectionstatus = 'Offline';
                            this.customerrormessage = 'Connection with the server was lost, can\'t connect';
                            if (status) {
                                endcall();
                            }
                        },
                        onInvite(Invitation: Invitation) {
                            status = Invitation;
                            calling();
                        },
                        onInfo(info: Info) {
                            console.log('msg tone', info);
                        },
                        onRefer: (referral) => {
                            alert('refer 2');

                        }
                    };

                    this.registerer = new Registerer(this.userAgent);
                    this.userAgent.start().then(() => {
                        this.registerer.register();
                        this.registerer.stateChange.addListener((newStat) => {
                            switch (newStat) {
                                case RegistererState.Registered:
                                    this.connectionstatus = 'Online';
                                    this.expandDialer = localStorage.getItem(`expandDialer`);
                                    if (!this.expandDialer || this.expandDialer === 'open') {
                                        this.sipshow = 'open';
                                    }
                                    break;
                                case RegistererState.Unregistered:
                                    this.connectionstatus = 'Offline';
                                    this.customerrormessage = 'Aurthorization Failed';
                                    this.registerer.unregister();
                                    break;
                                case RegistererState.Terminated:
                                    this.connectionstatus = 'Offline';
                                    this.customerrormessage = 'Aurthorization Failed';
                                    break;
                            }
                        });
                    }).catch((err) => {
                        this.connectionstatus = 'Offline';
                        this.customerrormessage = 'Connection with the server was lost, can\' t connect';
                        this.userAgent.stop();
                    });
                    Object.entries(localStorage).map(l => {
                        if (l[0] == `${this.username}`) {
                            this.historybackup = JSON.parse(localStorage.getItem(`${this.username}`));
                            this.usersipcallhistory = this.usersipcallhistory.concat(this.historybackup);
                        }
                    });
                    localStorage.setItem(`${this.username}`, JSON.stringify(this.usersipcallhistory));
                    this.calltransferData();
                } else {
                    this.connectionstatus = 'Offline';
                    this.customerrormessage = 'Connection with the server was lost, can\' t connect';
                }
            });

        window.addEventListener('beforeunload', function (e) {
            if (status) {
                status.bye();
            }
        });

    }

    calltransferData() {
        combineLatest(
            this.apiService.getextensionlist(),
        ).pipe(
            finalize(() => {
            }),
        )
            .subscribe(([calltransferResponse]: [any]) => {
                if (calltransferResponse.success) {
                    this.ctrs = calltransferResponse.data;
                }

            });
    }

    attemptReconnectionRefresh() {
        window.location.reload();
    }

    attemptReconnection = (reconnectionAttempt = 1) => {

        //If not intentionally connected, don't reconnect.
        if (!this.shouldBeConnected) {
            return;
        }

        // Reconnection attempt already in progress
        if (this.attemptingReconnection) {
            return;
        }

        // Reconnection maximum attempts reached
        if (reconnectionAttempt > this.reconnectionAttempts) {
            return;
        }

        // We're attempting a reconnection
        this.attemptingReconnection = true;

        setTimeout(() => {
            // If not intentionally connected, don't reconnect.
            if (!this.shouldBeConnected) {
                this.attemptingReconnection = false;
                return;
            }
            // Attempt reconnect

            this.userAgent.reconnect()
                .then(() => {
                    // Reconnect attempt succeeded
                    if (this.registerer.state != 'Terminated' && this.registerer.state != 'Unregistered' && this.userAgent.state != 'Stopped') {
                        this.attemptingReconnection = false;
                        byerequest();
                    }
                })
                .catch((error: Error) => {
                    // Reconnect attempt failed
                    this.attemptingReconnection = false;
                    this.attemptReconnection(++reconnectionAttempt);
                });
        }, reconnectionAttempt === 1 ? 0 : this.reconnectionDelay * 1000);
    };

    historycall(item) {
        this.dialnumber = item.Dialnumber;
        this.makecall();
    }

    makecall() {
        if (this.dialnumber == '') {
            return;
        }


        this.dialname = this.dialnumber;
        this.sipshow = 'outgoingcall';
        this.startoutgoing();
        this.calltrue = true;
        this.expandcall = false;
        this.expandtrue = false;
        this.historyshow = false;
        this.dialpadshow = false;
        const target = UserAgent.makeURI('sip:' + `${this.dialnumber}` + '@' + `${this.domain}`);
        console.log('called.......', target);
        if (!target) {
            throw new Error('Failed to create target URI.');
        }

        const inviter = new Inviter(this.userAgent, target, {});
        status = inviter;

        const inviteOptions: InviterInviteOptions = {
            requestDelegate: {
                onAccept: (response) => {
                    statusbackup = status;
                    this.sipshow = 'callengaged';
                    this.stopoutgoing();
                    this.callengagednumber = this.dialnumber;
                    this.callengagedname = this.dialnumber;
                    this.starttimer(0, 0, 0);
                    this.setupRemoteMedia();
                    let obj = {
                        Dialnumber: this.dialnumber,
                        callstatus: 'Outgoing',
                        calltype: 'Accepted',
                        Dialname: this.dialname ? this.dialname : this.dialnumber,
                        Time: this.formatDate(new Date()),
                    };
                    this.usersipcallhistory.push(obj);
                    localStorage.setItem(`${this.username}`, JSON.stringify(this.usersipcallhistory));
                },
                onReject: (response) => {
                    let obj = {
                        Dialnumber: this.dialnumber,
                        callstatus: 'Outgoing',
                        calltype: 'Rejected',
                        Dialname: this.dialname ? this.dialname : this.dialnumber,
                        Time: this.formatDate(new Date()),
                    };
                    this.usersipcallhistory.push(obj);
                    localStorage.setItem(`${this.username}`, JSON.stringify(this.usersipcallhistory));
                    this.sipshow = 'open';
                    clearInterval(this.timer);
                    this.stopoutgoing();
                    this.dialnumber = '';
                    this.dialname = '';
                    this.incomingdialname = '';
                    this.callengagedname = '';
                    this.historyshow = false;
                    this.dialpadshow = true;
                    status = null;
                    statusbackup = null;
                    this.callbuttonvalue = false;
                },
                onTrying: (response) => {
                }
            },
            sessionDescriptionHandlerOptions: {
                constraints: {
                    audio: true,
                    video: false,
                },
            },
        };


        this.newsess();
        inviter
            .invite(inviteOptions)
            .then(() => {
                // INVITE sent

                console.log('called.......', 'Success.....');
            })
            .catch((error) => {
                // INVITE did not send
                console.log('called....... Error :- ' + error + target);
            });
    }

    starttimer(a, b, c) {
        this.timer = setInterval(clock, 1000);
        // var timer = 0;
        var msec = c;
        var sec = b;
        var min = a;

        function clock() {
            msec += 1;
            if (msec == 60) {
                sec += 1;
                msec = 0;
                if (sec == 60) {
                    sec = 0;
                    min += 1;
                    if (sec % 2 == 0) {
                    }
                }
            }
            let h = min.toString().length === 1 ? '0' + min.toString() : min;
            let s = sec.toString().length === 1 ? '0' + sec.toString() : sec;
            let ms = msec.toString().length === 1 ? '0' + msec.toString() : msec;
            let element = <HTMLInputElement>document.getElementById('starttime');
            element.innerHTML = h + ':' + s + ':' + ms;
        }
    }

    startincoming() {
        let incomingtone = <HTMLAudioElement>document.getElementById('incoming');
        incomingtone.play();
    }

    stopincoming() {
        let incomingtone = <HTMLAudioElement>document.getElementById('incoming');
        incomingtone.pause();
    }

    startoutgoing() {
        let outgoingtone = <HTMLAudioElement>document.getElementById('outgoing');
        outgoingtone.play();
    }

    stopoutgoing() {
        let outgoingtone = <HTMLAudioElement>document.getElementById('outgoing');
        outgoingtone.pause();
    }

    Accept() {
        if (status) {
            if (!statusbackup) {
                statusbackup = status;
            }
            if (statusbackup != status) {
                this.callbuttonvalue = true;
                statusbackup.bye();
                clearInterval(this.timer);
            }
            this.sipshow = 'callengaged';
            this.callengagednumber = this.incomingdailnumber;
            this.callengagedname = this.incomingdialname;
            this.callstatus = 'Accepted';
            this.calltrue = false;
            this.incomingcalltrue = false;
            this.pickupcallshow = true;
            status.accept();
            this.starttimer(0, 0, 0);
            this.stopincoming();
            this.setupRemoteMedia();
        }
    }

    Reject() {
        if (status) {
            this.callstatus = 'Rejected';
            status.reject();
            status = null;
            statusbackup = null;
        }
    }

    incoming_call() {
        let sethistory = false;
        this.sipshow = 'incomingcall';
        this.startincoming();
        this.incomingdailnumber = status.incomingInviteRequest.message.from.uri.normal['user'];
        this.incomingdialname = status.incomingInviteRequest.message.from._displayName;
        this.calltrue = false;
        this.incomingcalltrue = true;
        this.expandcall = true;
        this.expandtrue = false;
        this.historyshow = false;
        this.dialpadshow = false;
        this.callstatus = 'Ringing';

        status.stateChange.addListener((newState: SessionState) => {
            switch (newState) {
                case SessionState.Establishing:
                    // Session is establishing.
                    break;
                case SessionState.Established:
                    sethistory = true;
                    let obj1 = {
                        Dialnumber: this.incomingdailnumber,
                        callstatus: 'Incoming',
                        calltype: 'Accepted',
                        Dialname: this.incomingdialname ? this.incomingdialname : this.incomingdailnumber,
                        Time: this.formatDate(new Date()),
                    };
                    this.usersipcallhistory.push(obj1);
                    localStorage.setItem(`${this.username}`, JSON.stringify(this.usersipcallhistory));
                    break;
                case SessionState.Terminated:
                    if (statusbackup) {
                        if (statusbackup != status) {
                            if (this.callbuttonvalue == false) {
                                let ahour = (document.getElementById('starttime').innerText.split(':'))[0];
                                let bmin = (document.getElementById('starttime').innerText.split(':'))[1];
                                let csec = (document.getElementById('starttime').innerText.split(':'))[2];
                                status = statusbackup;
                                this.starttimer(parseInt(ahour), parseInt(bmin), parseInt(csec));
                                this.sipshow = 'callengaged';
                                this.callengagednumber = statusbackup.incomingInviteRequest.message.from.uri.normal['user'];
                                this.callengagedname = statusbackup.incomingInviteRequest.message.from._displayName;
                            }
                        }
                    } else {
                        this.dialnumber = '';
                        this.sipshow = 'open';
                        this.dtmfnumber = '';
                        this.calltransfermemberid = '';
                        this.calltransfernumber = '';
                        this.manualcheckbox = false;
                        this.transfervalidate = true;
                        this.inputcalltransfervalidate = true;
                        this.mutepress = false;
                        this.held = true;
                        this.transferpress = false;
                        this.calltransfershow = false;
                        this.stopincoming();
                        this.calldialpadhide();
                        clearInterval(this.timer);
                        this.calltrue = false;
                        this.expandcall = true;
                        this.expandtrue = true;
                        this.historyshow = false;
                        this.dialpadshow = true;
                        this.pickupcallshow = false;
                        if (sethistory == false) {
                            let obj2 = {
                                Dialnumber: this.incomingdailnumber,
                                callstatus: 'Incoming',
                                calltype: 'Rejected',
                                Dialname: this.incomingdialname ? this.incomingdialname : this.incomingdailnumber,
                                Time: this.formatDate(new Date()),
                            };
                            this.usersipcallhistory.push(obj2);
                            localStorage.setItem(`${this.username}`, JSON.stringify(this.usersipcallhistory));
                        }
                        this.dialname = '';
                        this.incomingdialname = '';
                        this.callengagedname = '';
                        status = null;
                        statusbackup = null;
                        this.callbuttonvalue = false;
                    }
                    break;
                default:
                    break;
            }
        });
        this.newsess();
    }

    call() {
        let element: HTMLElement = document.getElementById('myDiv') as HTMLElement;
        element.click();
    }

    private setupRemoteMedia(): void {

        let mediaElement = <HTMLAudioElement>document.getElementById('audioplayer');

        if (mediaElement) {
            const remoteStream = status.sessionDescriptionHandler.remoteMediaStream;
            if (!remoteStream) {
                throw new Error('Remote media stream undefiend.');
            }
            mediaElement.autoplay = true; // Safari hack, because you cannot call .play() from a non user action
            mediaElement.srcObject = remoteStream;
            mediaElement.play().catch((error: Error) => {
                console.log(`Failed to play remote media`);
            });
            remoteStream.onaddtrack = (): void => {
                console.log(`setup remote media`);
                mediaElement.load(); // Safari hack, as it doesn't work otheriwse
                mediaElement.play().catch((error: Error) => {
                    console.log(`Failed to play remote media`);
                });
            };
        }
    }

    checking() {
        this.connectionstatus = 'Online';
        this.sipshow = 'open';
        clearInterval(this.timer);
        this.dialnumber = '';
        this.historyshow = false;
        this.calldialpadhide();
        this.dialpadshow = true;
    }

    forceclick() {
        let element = <HTMLInputElement>document.getElementById('mybutton');
        element.click();
    }

    endcall() {
        this.sipshow = 'open';
        this.dtmfnumber = '';
        this.calltransfermemberid = '';
        this.calltransfernumber = '';
        this.manualcheckbox = false;
        this.transfervalidate = true;
        this.inputcalltransfervalidate = true;
        this.mutepress = false;
        this.held = true;
        this.transferpress = false;
        this.calltransfershow = false;
        this.stopincoming();
        this.calldialpadhide();
        this.stopoutgoing();
        clearInterval(this.timer);
        this.calltrue = false;
        this.expandcall = true;
        this.expandtrue = true;
        this.historyshow = false;
        this.dialpadshow = true;
        this.pickupcallshow = false;
        switch (status.state) {
            case SessionState.Initial:
                if (status instanceof Inviter) {
                    return status.cancel().then(() => {
                        console.log(`msg Inviter never sent INVITE (canceled)`);
                    });
                } else if (status instanceof Invitation) {
                    return status.reject().then(() => {
                        console.log(`msg Invitation rejected (sent 480)`);
                    });
                } else {
                    throw new Error('Unknown session type.');
                }
            case SessionState.Establishing:
                if (status instanceof Inviter) {
                    return status.cancel().then(() => {
                        console.log(`msg Inviter canceled (sent CANCEL)`);
                    });
                } else if (status instanceof Invitation) {
                    return status.reject().then(() => {
                        console.log(`msg Invitation rejected (sent 480)`);
                    });
                } else {
                    throw new Error('Unknown session type.');
                }
            case SessionState.Established:
                return status.bye().then(() => {
                    status = null;
                    statusbackup = null;

                    this.callbuttonvalue = false;
                    console.log(`msg Session ended (sent BYE)`);
                });
            case SessionState.Terminating:
                break;
            case SessionState.Terminated:
                break;
            default:
                throw new Error('Unknown state');
        }
    }

    sethold(hold: boolean) {
        const options: SessionInviteOptions = {
            requestDelegate: {
                onAccept: (): void => {
                    console.log('dj a');
                    this.held = hold;
                    this.enableReceiverTracks(!this.held);
                    this.enableSenderTracks(!this.held && !this.togglemute);
                },
                onReject: (): void => {
                    console.log('dj c');
                    this.enableReceiverTracks(!this.held);
                    this.enableSenderTracks(!this.held && !this.togglemute);
                }
            }
        };
        status.invite(options)
            .then(() => {
                console.log('dj sent');
                // this.enableReceiverTracks(!hold);
                // this.enableSenderTracks(!hold && !this.muted);
            })
            .catch((error: Error) => {
                console.log('dj', error);

            });
    }


    Mute(togglemute: any) {
        this.mutepress = !this.mutepress;
        this.enableSenderTracks(togglemute);
    }

    private enableSenderTracks(togglemute: boolean): void {
        const pc = status.sessionDescriptionHandler.peerConnection;
        pc.getSenders().forEach(function (sender: any) {
            if (sender.track) {
                sender.track.enabled = !togglemute;
                console.log('msg sender');
            }
        });
    }

    private enableReceiverTracks(togglemute: boolean): void {
        const pc = status.sessionDescriptionHandler.peerConnection;
        pc.getReceivers().forEach(function (receiver: any) {
            if (receiver.track) {
                receiver.track.enabled = !togglemute;
                console.log('msg receiver');
            }
        });
    }

    newsess() {
        status.delegate = {
            onBye() {
                byerequest();
                clearInterval(this.timer);
                statusbackup = null;
                this.callbuttonvalue = false;
            },
            onInfo(info: Info) {
                console.log('msg tone', info);
                //   const contentType = info.request.getHeader("content-type");
                //   if (!contentType || !/^application\/dtmf-relay/i.exec(contentType)) {
                //     info.reject();
                //     return;
                //   }

                //   const body = info.request.body.split("\r\n", 2);
                //   if (body.length !== 2) {
                //     info.reject();
                //     return;
                //   }

                //   let tone: string | undefined;
                //   const toneRegExp = /^(Signal\s*?=\s*?)([0-9A-D#*]{1})(\s)?.*/;
                //   if (toneRegExp.test(body[0])) {
                //     tone = body[0].replace(toneRegExp, "$2");
                //   }
                //   if (!tone) {
                //     info.reject();
                //     return;
                //   }

                //   let duration: number | undefined;
                //   const durationRegExp = /^(Duration\s?=\s?)([0-9]{1,4})(\s)?.*/;
                //   if (durationRegExp.test(body[1])) {
                //     duration = parseInt(body[1].replace(durationRegExp, "$2"), 10);
                //   }
                //   if (!duration) {
                //     info.reject();
                //     return;
                //   }

                //   info
                //     .accept()
                //     .then(() => {
                //       console.log("tone: ", tone, ",duration :", duration)
                //     })
                //     .catch((error: Error) => {

                //     });
            },
            onRefer: (referral) => {
                alert('refer 2');

            }
        };
    }

    onlydtmfNumber(event) {
        let k = event.charCode;
        if (k == 8 || k == 42 || k == 35 || (k >= 48 && k <= 57)) {
            this.dtmf(event.key);
        }
    }

    dtmfadd(number) {
        this.dtmfnumber += number;
        this.dtmf(number);
    }

    dtmf(tone: string) {
        const options = {
            requestOptions: {
                body: {
                    contentDisposition: 'render',
                    contentType: 'application/dtmf-relay',
                    content: 'Signal=' + tone + '\r\nDuration=10'
                }
            }
        };
        status.info(options);
    }


    expand(string) {
        console.log('DIALER expand', string);
        localStorage.setItem('expandDialer', string);
        this.sipshow = string;
    }

    dailpad() {
        this.dialpadshow = true;
        this.historyshow = false;
    }

    history() {
        this.historyshow = true;
        this.dialpadshow = false;
        this.callhistorydata = JSON.parse(localStorage.getItem(`${this.username}`)).reverse();
    }


    calltransfershowpopup() {
        this.calltransfernumber = '';
        this.transferpress = !this.transferpress;
        this.calltransfershow = !this.calltransfershow;
    }

    calltransfervalidation(event) {
        let k = event.charCode;
        let regularExpression = /^[0-9*#+]+$/;
        let alpha = regularExpression.test(String(this.calltransfernumber).toLowerCase());
        if (alpha === true) {
            this.inputcalltransfervalidate = true;
            this.transfervalidate = false;
        } else {
            this.inputcalltransfervalidate = false;
            this.transfervalidate = true;
        }

    }

    calltransfer() {
        if (this.manualcheckbox == false) {
            this.ctrs.map(c => {
                if (c.member_name == this.calltransfermemberid) {
                    this.calltransfernumbersend = c.member_extension;
                }
            });
        } else {
            this.calltransfernumbersend = this.calltransfernumber;
        }
        const target = UserAgent.makeURI('sip:' + `${this.calltransfernumbersend}` + '@' + `${this.domain}`);
        status.refer(target);
        this.transferpress = false;
        this.calltransfershow = false;
    }


    calldialpadshow() {
        this.calldialpad = true;
    }

    calldialpadhide() {
        this.calldialpad = false;
    }

    onclickkeypad(number) {
        this.dialnumber += number;
    }

    manualcheck() {
        this.manualcheckbox = !this.manualcheckbox;
        this.inputcalltransfervalidate = true;
        this.calltransfernumber = '';
    }

    onlyNumber(event) {
        let k = event.charCode;
        return (k == 8 || k == 42 || k == 35 || (k >= 48 && k <= 57));

    }

    onPaste(event: any) {
        if (event.length) {
            let pastedText = event;
            this.dialnumber = '';
            // let output = pastedText.match(/[0-9]+/g);
            let output = pastedText.match(/\d+/g);
            output = output.toString().replace(/\,/g, ''); // 1125, but a string, so convert it to number
            this.dialnumber = output;
        }

    }

    formatDate(date) {

        let monthNames = [
            'Jan', 'Feb', 'Mar',
            'Apr', 'May', 'Jun', 'Jul',
            'Aug', 'Sep', 'Oct',
            'Nov', 'Dec'
        ];

        let hours = date.getHours();
        let minutes = date.getMinutes();
        let todaydate = date.getDate();
        let month = (date.getMonth() + 1);
        const ampm = hours >= 12 ? 'pm' : 'am';

        hours %= 12;
        hours = hours || 12;
        minutes = minutes < 10 ? 0 + `${minutes}` : minutes;
        todaydate = todaydate < 10 ? 0 + `${todaydate}` : todaydate;
        month = month < 10 ? 0 + `${month}` : month;

        const strTime = `${todaydate}/${monthNames[month - 1]} ${hours}:${minutes} ${ampm}`;

        return strTime;
    }
}


function calling() {
    const foo: DialerComponent = new DialerComponent(Optional(), Optional());
    foo.call();
}

function endcall() {
    const foo: DialerComponent = new DialerComponent(Optional(), Optional());
    foo.endcall();
}

function byerequest() {
    const foo: DialerComponent = new DialerComponent(Optional(), Optional());
    foo.forceclick();
}
