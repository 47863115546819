import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/_services/authentication.service';
import { User } from '@app/_models/user';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HighSpanScoreModalComponent } from '@app/@shared/components/high-span-score-modal/high-span-score-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
    providedIn: 'root'
})

export class CompanyLoginStatusGuradGuard implements CanActivate {
    bsModalRef: BsModalRef;

    constructor(private router: Router,
        private bsModalService: BsModalService,
        private authenticationService: AuthenticationService) {
    }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser: User = this.authenticationService.currentUserValue;
        const url = state.url;

        if (currentUser.company_status_id == 1) {
            if (currentUser && currentUser.hss && currentUser.hss === 1 && currentUser.role_id !== 3) {
                this.bsModalRef = this.bsModalService.show(HighSpanScoreModalComponent,
                    { class: 'dsk-modal dsk-modal-fullscreen', ignoreBackdropClick: true }
                );
            }
            return true;
        }
        else if (!url.toLowerCase().includes('payment-failed') && currentUser.company_status_id == 5) {
            await this.router.navigateByUrl('/payment-failed');
            return false;
        }
        else if (!url.toLowerCase().includes('trial-ended') && currentUser.company_status_id == 2) {
            await this.router.navigateByUrl('/trial-ended');
            return false;
        } else {
            await this.router.navigateByUrl('/login');
            return false;
        }
    }

}
