// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: 'http://localhost/talkchief_api/public/api',
  apiUrl: 'https://dev-api.talkchief.io/public/api',
   //supersetUrl : 'https://analytics.talkchief.io',
  supersetUrl : 'http://bi.talkchief.io:8088',
  reportsUrl: 'https://dev-api.talkchief.io/public/api',
  s_key: 'pk_test_51Hnqc7Ln4596Yi95u0mM9bvPefURF25sRXycRP63bXVV5AzxqCcWcidqgpwjTW6nNqeQVeQvYSAhgo7wIzt6AgMy00G1focJNA',
  un_country: '1f6fc2bd-f081-4202-9b1a-d9cb88d942b9',
  app_url: 'http://localhost:4200/#/',
  appCode: 'e66ddd384f3149738ad0aab4c11a6d2c',
  encryptIsActive: false,
  dialerapiUrl: 'wss://dev-softphone.talkchief.io:443',
  one_way: 'base64:iR2DN2Y6B9swQNxHX9inn96dWs364GZKPTFRqxkP010=',
};
  