import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ApiService} from '@app/_services/api.service';
import {IPaymentMethodRequest} from '@app/@core/interfaces';
import {finalize, switchMap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';


import {StripeService} from 'ngx-stripe';
// tslint:disable-next-line:max-line-length
import {
    StripeElements,
    StripeCardElement,
    StripeCardElementOptions,
    StripeElementsOptions,
    PaymentRequestPaymentMethodEvent,
    PaymentIntent
} from '@stripe/stripe-js';

import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LocalizationService} from '@app/internationalization/localization.service';


@Component({
    selector: 'app-add-card-popup',
    templateUrl: './add-card-popup.component.html',
    styleUrls: ['./add-card-popup.component.scss']
})

export class AddCardPopupComponent implements OnInit {
    @Input() icon;
    @Input() title;
    @Input() type;
    @Input() local;
    @Output() response: EventEmitter<any> = new EventEmitter();
    form: FormGroup;

    paymentRequestOptions = {
        country: 'ES',
        currency: 'eur',
        total: {
            label: 'Demo Total',
            amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
    };

    elementsOptions: StripeElementsOptions = {
        locale: 'en'
    };

    elements: StripeElements;
    card: StripeCardElement;
    cardOptions: StripeCardElementOptions = {
        style: {
            base: {
                iconColor: '#0f93f3',
                color: '#002a3f',
                fontWeight: '500',
                fontFamily: '"Rubik", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                    color: '#adb6be'
                }
            }
        }
    };
    setupIntent: any;
    client_secret: any;
    paymentMethodId: any;

    constructor(private fb: FormBuilder,
                public bsModalRef: BsModalRef,
                private apiService: ApiService,
                private toastr: ToastrService,
                private bsModalService: BsModalService,
                private stripeService: StripeService,
                private http: HttpClient,
                private localizationService: LocalizationService,
    ) {
    }

    ngOnInit() {
        // this.getStepIntent();
        this.form = this.fb.group({
            name: ['', [Validators.required]]
        });

        this.elementsOptions.locale = this.local;

        this.stripeService.elements(this.elementsOptions)
            .subscribe(elements => {
                this.elements = elements;
                // Only mount the element the first time
                if (!this.card) {
                    this.card = this.elements.create('card', this.cardOptions);
                    this.card.mount('#card-element');
                }
            });
    }


    createPaymentMethod() {
        this.stripeService
            .createPaymentMethod({
                type: 'card',
                card: this.card,
                billing_details: {
                    name: this.form.get('name').value,
                },
            }).subscribe((result: any) => {
            if (result.error) {
                console.log(result.error.message);
                this.toastr.error(result.error.message);
            } else {
                this.getStepIntent(result.paymentMethod.id);
                this.paymentMethodId = result.paymentMethod.id;
            }
            // console.log('createPaymentMethod ..', result);
        });
    }

    getStepIntent(paymentMethod) {
        this.apiService.getSetupIntent(paymentMethod)
            .subscribe((result: any) => {
                // console.log('getSetupIntent  ...', result);
                if (result.success === true) {
                    this.setupIntent = result.data.id;
                    this.client_secret = result.data.client_secret;
                    let status = result.data.status;
                    this.saveCard(status);
                } else {
                    // console.log('getSetupIntent', result);
                    this.toastr.error(result.message);
                }
            });
    }

    getPaymentIntent() {
        this.apiService.getPaymentIntent()
            .subscribe((result: any) => {
                // console.log('getPaymentIntent  ...', result);
                if (result.success === true) {
                    this.setupIntent = result.data.id;
                    this.client_secret = result.data.client_secret;
                    let status = result.data.status;
                    this.saveCard(status);
                } else {
                    // console.log('getSetupIntent', result);
                    this.toastr.error(result.message);
                }
            });
    }

    saveCard(status) {
        // console.log('saveCard start ...');
    
        if (status == 'succeeded') {
            this.setDefaultPaymentMethod(this.paymentMethodId);
        } else {
            this.stripeService
                .confirmCardSetup(this.client_secret, {
                    payment_method: {
                        card: this.card,
                        billing_details: {
                            name: this.form.get('name').value,
                        },
                    }
                }).subscribe((result: any) => {
                    if (result.error) {
                        // Handle error during card setup
                        this.toastr.error(result.error.message);
                    } else {
                        // Handle successful card setup
                        if (result.setupIntent.status === 'requires_action') {
                            this.stripeService.handleCardAction(result.setupIntent.client_secret)
                                .subscribe((res: any) => {
                                    if (res.error) {
                                        // Handle error during 3D Secure authentication
                                        this.toastr.error(res.error.message);
                                        this.rejectProcess();
                                    } else {
                                        // Handle successful 3D Secure authentication
                                        if (res.setupIntent.status === 'succeeded') {
                                            this.setDefaultPaymentMethod(res.setupIntent.payment_method);
                                        } else {
                                            this.toastr.error('3D Secure authentication failed');
                                        }
                                    }
                                });
                        } else {
                            this.setDefaultPaymentMethod(result.setupIntent.payment_method);
                        }
                    }
                });
        }
    }

    rejectProcess() {
        this.closeModal({
            response: 'no',
            type: this.type,
            msg: 'Card authentication failed'
        });
    }
    
    setDefaultPaymentMethod(payment_method) {
        this.apiService.setDefaultPaymentMethod(payment_method)
            .subscribe((result: any) => {
                this.closeModal({
                    response: 'yes',
                    type: this.type,
                    msg: 'Card added successfully'
                });
            });
    }

    closeModal(response) {
        this.bsModalService.setDismissReason(response);
        this.bsModalRef.hide();
    }

    // createToken() {
    //     const name = this.form.get('name').value;
    //     this.stripeService
    //         .createToken(this.card, {name})
    //         .subscribe((result) => {
    //             if (result.token) {
    //                 // Use the token
    //                 console.log(result.token.id);
    //             } else if (result.error) {
    //                 // Error creating the token
    //                 console.log(result.error.message);
    //             }
    //         });
    // }
    preparePaymentMethodPayload(): IPaymentMethodRequest {
        let expiryDate = this.form.controls['expiryDate'].value;
        var payload: IPaymentMethodRequest = {
            number: this.form.controls['cardNumber'].value,
            exp_month: expiryDate.slice(0, 2),
            exp_year: expiryDate.slice(2, 4),
            cvc: this.form.controls['cvc'].value
        };
        return payload;
    }

    paymentMethodAction() {
        let payload = this.preparePaymentMethodPayload();
        if (this.type === 'add') {
            this.apiService.addPaymentMethod(payload)
                .pipe(
                ).subscribe((response: any) => {
                if (response.success) {
                    this.closeModal({
                        response: 'yes',
                        type: this.type,
                        msg: 'Card added successfully'
                    });
                } else {
                    console.log(response);
                    this.toastr.error(response.message);
                }
            }, (err) => {
                this.toastr.error(err.message);
            });
        } else if (this.type === 'replace') {
            this.apiService.addPaymentMethod(payload)
                .pipe(
                ).subscribe((response: any) => {
                if (response.success) {
                    this.closeModal({
                        response: 'yes',
                        type: this.type,
                        msg: 'Card replaced successfully'
                    });
                } else {
                    this.toastr.error(response.message);
                }
            }, (err) => {
                this.toastr.error(err.message);
            });
        }
    }


    // onPaymentMethod(ev: PaymentRequestPaymentMethodEvent) {
    //     this.createPaymentIntent()
    //         .pipe(
    //             switchMap((pi) => {
    //                 return this.stripeService.confirmCardPayment(
    //                     pi.client_secret,
    //                     {payment_method: '123123123'},
    //                     {handleActions: true}
    //                 )
    //                     .pipe(
    //                         switchMap((confirmResult) => {
    //                             if (confirmResult.error) {
    //                                 // Report to the browser that the payment failed,
    //                                 // prompting it to re-show the payment interface,
    //                                 // or show an error message and close the payment.
    //                                 ev.complete('fail');
    //                                 return of({
    //                                     error: new Error('Error Confirming the payment'),
    //                                 });
    //                             } else {
    //                                 // Report to the browser that the confirmation was
    //                                 // successful, prompting it to close the browser
    //                                 // payment method collection interface.
    //                                 ev.complete('success');
    //                                 // Let Stripe.js handle the rest of the payment flow.
    //                                 return this.stripeService.confirmCardPayment(
    //                                     pi.client_secret
    //                                 );
    //                             }
    //                         })
    //                     );
    //             })
    //         )
    //         .subscribe((result) => {
    //             if (result.error) {
    //                 // The payment failed -- ask your customer for a new payment method.
    //             } else {
    //                 // The payment has succeeded.
    //             }
    //         });
    // }

    // createPaymentIntent(): Observable<PaymentIntent> {
    //     // Replace this with your own custom implementation
    //     // to perform a Payment Intent Creation
    //     // You will need your own Server to do that
    //     return this.http.post<PaymentIntent>(
    //         '/create-payment-intent',
    //         {amount: this.paymentRequestOptions.total.amount}
    //     );
    // }


}
