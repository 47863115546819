import {IApiKazooResponse, IApiResponse} from './../../../../../@core/interfaces/api.interface';
import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '@app/_services/api.service';
import {combineLatest, Subscription} from 'rxjs';
import {IOption, ITeamNumber} from '@app/@core/interfaces';
import {isNullOrUndefined} from 'util';
import {DataHelperService} from '@app/_services/data.helper.service';
import {
    KazooCellPhoneDevice,
    KazooCellPhoneDeviceRequest,
    KazooCreateCellPhoneDeviceResponse,
    KazooFlowResponse
} from '@app/@core/interfaces/kz.interface';
import {MODAL_CLOSE_REASON} from '@app/variables/constants';
import {alphaNumericValidator} from '../alphanumeric-validator';
import {DeleteCellphoneModalComponent} from '@app/pages/call-flow/components/delete-cellphone-modal/delete-cellphone-modal.component';
import {EditCellphoneModalComponent} from '@app/pages/call-flow/components/edit-cellphone-modal/edit-cellphone-modal.component';
import {take, tap} from 'rxjs/operators';

@Component({
    selector: 'app-call-flow-select',
    templateUrl: './call-flow-select.component.html',
    styleUrls: ['./call-flow-select.component.scss']
})
export class CallFlowSelectComponent implements OnInit, OnDestroy {
    @Input() isEdit = false;
    @Input() isEditRoot = false;
    @Input() data;
    @Input() isOriginal;
    @Input() isSubIvr = false;
    @Input() isIvr = false;
    @Input() src;
    @Input() target;
    @Input() popupTitle = 'call_flow';
    selectedCellPhone: ITeamNumber = null;
    numbers: ITeamNumber[] = [];
    numberOptions: IOption[] = [];
    form: FormGroup;
    loading = false;
    subscription$: Subscription;
    addNew = false;
    countriesOptions: IOption[] = [];
    flag;

    constructor(
        private fb: FormBuilder,
        private apiService: ApiService,
        private toasterService: ToastrService,
        public bsModalRef: BsModalRef,
        public bsModalRef2: BsModalRef,
        public bsModalRef3: BsModalRef,
        private bsModalService: BsModalService,
        private dataHelper: DataHelperService
    ) {
    }

    ngOnInit() {
        console.log('data', this.data);
        console.log('src', this.src);
        this.initForm();
        this.getCellPhoneDevices();

        this.initData();
    }

    initData() {
        combineLatest(
            this.apiService.getCountries(),
        ).subscribe(([countriesResponse]: [any]) => {
            if (countriesResponse.success) {
                this.countriesOptions = this.generateIOption(countriesResponse.data, 'country_id', 'country_name');
            }
        }, (err) => {
            this.toasterService.error(err.error.message);
        });
    }

    generateIOption(items: any[], valueKey: string, labelKey: string) {
        let newItems = [];
        if (items) {
            newItems = items.map(i => {
                return {
                    label: i[labelKey],
                    value: i[valueKey],
                    selected: false,
                    data: i,
                } as IOption;
            });
        }
        return newItems;
    }

    getCallFlow(numberSelected) {
        this.apiService.getCallflow(this.data.id).pipe(
            take(1),
            tap((callFlow: IApiResponse<any>) => {
                if (callFlow.success && callFlow.data) {
                    numberSelected = callFlow.data.did_id;
                }
                // todo check if needs to submit to form on edit, then get did from callflow id to get the number
                this.form.get('number').setValue(numberSelected);
            }),
        ).subscribe();
    }

    getCellPhoneDevices(deviceId?: any) {
        if (!this.data) {
            console.log('getCellPhoneDevices no id', this.data);
        } else {
            console.log('getCellPhoneDevices data', this.data);
        }
        this.apiService.callGetCompanyActiveNumbersList().pipe(
            take(1),
        ).subscribe((numbers: IApiResponse<any>) => {
            this.numbers = numbers.data as ITeamNumber[];
            this.numberOptions = this.numbers.map<IOption>(cellphone => {
                return {
                    label: cellphone.did_name,
                    value: cellphone.did_id, selected: false,
                    phone_number: cellphone.did_number
                };
            });
            let numberSelected = null;
            if (this.isEdit || deviceId) {
                if (this.isOriginal) {
                    console.log('isOriginal');
                    numberSelected = this.data.id;
                } else {
                    // todo what to do here?
                }
                const num = this.numbers.find(numberItem => numberItem.did_id === numberSelected);
                if (!isNullOrUndefined(num)) {
                    this.selectedCellPhone = num;
                }
                if (this.data) {
                    this.getCallFlow(numberSelected);
                }
            }
            if (!isNullOrUndefined(subscription) && !subscription.closed) {
                subscription.unsubscribe();
            }
        }, error => {
            if (!isNullOrUndefined(subscription) && !subscription.closed) {
                subscription.unsubscribe();
            }
        });


        const subscription = this.apiService.callGetCompanyActiveNumbersList().subscribe((res: IApiResponse<any>) => {
            console.log('callGetCompanyActiveNumbersList', res);
            console.log('callGetCompanyActiveNumbersList deviceId', deviceId);
            this.numbers = res.data as ITeamNumber[];
            this.numberOptions = this.numbers.map<IOption>(cellphone => {
                return {
                    label: cellphone.did_name,
                    value: cellphone.did_id, selected: false,
                    phone_number: cellphone.did_number
                };
            });
            let numberSelected = null;
            if (this.isEdit || deviceId) {
                if (this.isOriginal) {
                    numberSelected = this.data.id;
                } else {
                    numberSelected = this.data.cellPhone.device_id;
                }
                console.log('numberSelected', numberSelected);
                console.log('numberSelected this.numbers', this.numbers);
                console.log('numberSelected this.data', this.data);
                const num = this.numbers.find(numberItem => numberItem.did_id === numberSelected);
                if (!isNullOrUndefined(num)) {
                    this.selectedCellPhone = num;
                }
                if (deviceId) {
                    numberSelected = deviceId;
                }
                // todo check if needs to submit to form on edit, then get did from callflow id to get the number
                // this.form.get('number').setValue(numberSelected);
            }
            if (!isNullOrUndefined(subscription) && !subscription.closed) {
                subscription.unsubscribe();
            }
        }, error => {
            if (!isNullOrUndefined(subscription) && !subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }


    private initForm() {
        this.form = this.fb.group({
            number: [null, [Validators.required]],
        });
        this.form.get('number').valueChanges.subscribe(value => {
            if (!isNullOrUndefined(value)) {
                const num = this.numbers.find(number => number.did_id === value);
                if (!isNullOrUndefined(num)) {
                    this.selectedCellPhone = num;
                }
            }
        });
    }

    onSubmit() {
        if (this.form.invalid) {
            this.toasterService.warning('Please select a number.');
            return;
        }
        this.apiService.getCallflowByDidNumber(this.selectedCellPhone.did_number + '').pipe(
            take(1),
        ).subscribe((res: IApiKazooResponse<KazooFlowResponse>) => {
            if (!isNullOrUndefined(this.selectedCellPhone)) {
                if (this.isSubIvr) {
                    console.log('submit this.isSubIvr');
                    this.dataHelper.sendPendingSubIvrData({
                        isEditRoot: this.isEditRoot,
                        id: res.data.id,
                        cellPhone: this.selectedCellPhone,
                        target: this.target,
                        isIvr: this.isIvr,
                        src: this.src,
                        isEdit: this.isEdit,
                        nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                    });
                } else if (this.isIvr && !this.isSubIvr) {
                    console.log('submit this.isIvr');
                    console.log('sex', {
                        isEditRoot: this.isEditRoot,
                        id: res.data.id,
                        cellPhone: this.selectedCellPhone,
                        target: this.target,
                        src: this.src,
                        isIvr: this.isIvr,
                        isEdit: this.isEdit,
                        nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                    });
                    this.dataHelper.sendPendingIvrData({
                        isEditRoot: this.isEditRoot,
                        id: res.data.id,
                        cellPhone: this.selectedCellPhone,
                        target: this.target,
                        src: this.src,
                        isIvr: this.isIvr,
                        isEdit: this.isEdit,
                        nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                    });
                } else {
                    console.log('submit else');
                    this.dataHelper.sendPendingData({
                        isEditRoot: this.isEditRoot,
                        id: res.data.id,
                        cellPhone: this.selectedCellPhone,
                        timeout: this.form.value.timeout,
                        target: this.target,
                        src: this.src,
                        isIvr: this.isIvr,
                        isEdit: this.isEdit,
                        nodeId: !isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                    });
                }
                this.closeModal(true);
            }
        });
    }

    closeModal(noChange = false) {
        console.log('noChange');
        this.bsModalRef.hide();

        if (!noChange) {
            this.bsModalService.setDismissReason('close');
        } else {
            this.bsModalService.setDismissReason(MODAL_CLOSE_REASON.CALL_FLOW);
        }
        this.bsModalRef.hide();
        this.isEdit = null;
        this.isEditRoot = null;
        this.data = null;
        this.isOriginal = null;
        this.isSubIvr = null;
        this.isIvr = null;
        this.src = null;
        this.target = null;
    }

    ngOnDestroy() {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }

}
