import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ApiService} from '@app/_services/api.service';
import {ToastrService} from 'ngx-toastr';
import {INumber, ITerminateNumber} from '@app/@core/interfaces';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'app-delete-cellphone-modal',
    templateUrl: './delete-cellphone-modal.component.html',
    styleUrls: ['./delete-cellphone-modal.component.scss']
})
export class DeleteCellphoneModalComponent implements OnInit {


    @Input() device: any;
    form: FormGroup = this.fb.group({
        deviceName: [null, [Validators.required]],
    });
    loading = false;
    deleteEvent: EventEmitter<string> = new EventEmitter<string>();

    constructor(private fb: FormBuilder,
                private apiService: ApiService,
                private toasterService: ToastrService,
                public bsModalRef: BsModalRef,
                private bsModalService: BsModalService) {

    }

    ngOnInit() {
    }

    onSubmit() {
        if (this.form.invalid) {
            this.toasterService.warning('Please fill all required fields.');
            return;
        }
        this.loading = true;
        this.apiService.callDeleteDevice(this.device.device_id)
            .pipe(
                finalize(() => {
                    this.loading = false;
                }),
            ).subscribe((response: any) => {
            if (response.success) {
                this.toasterService.success('Success');
                this.bsModalRef.hide();
                this.deleteEvent.emit(response.data);
            } else {
                this.toasterService.warning(response.message);
            }
        }, (error) => {
            this.toasterService.error(error.error.message);
        });
    }


    isFormValid() {
        const isTeamNameConfirmed = this.form.get('deviceName').value === this.device.device_name;
        const valid = this.form.valid && isTeamNameConfirmed;
        return valid;
    }

    closeModal(noChange = false) {
        if (noChange) {
            this.bsModalService.setDismissReason('close');
        }
        this.bsModalRef.hide();
    }

}
