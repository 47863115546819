import { SelectAltComponent } from './../../../../../@shared/components/select/select.component';
import { isNullOrUndefined } from 'util';
import { IMember } from './../../../../../@core/interfaces/member.interface';
import { Component, OnInit, Input, OnDestroy, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@app/_services/api.service';
import { finalize, take } from 'rxjs/operators';
import { Subscription, combineLatest } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IOption } from '@app/@core/interfaces';
import { cloneDeep, fill, orderBy } from 'lodash';
import { Router } from '@angular/router';
import { CallFlowService } from '@app/_services/call-flow.service';

@Component({
  selector: 'app-manage-members-modal',
  templateUrl: './manage-members-modal.component.html',
  styleUrls: ['./manage-members-modal.component.scss']
})
export class ManageMembersModalComponent implements OnInit, OnDestroy {
  @ViewChildren('selectAlt') selectComponents: QueryList<SelectAltComponent>;
  @Input() members: IMember[] = [];
  @Input() isEdit = false;
  loading = false;
  subscription$: Subscription;
  searchValue: string;
  form: FormGroup;

  orderOptions: IOption[] = [];
  teamsFilterOptions: IOption[] = [];
  membersFilterOptions: IOption[] = [];
  membersListOptions: IOption[] = [];
  filteredMembersListOptions: IOption[] = [];
  memberStatusMap: Map<number, any> = new Map();
  @Input() selectedMembers: Map<number, number> = new Map();
  @Input() originalSelectedMembers: Map<number, number> = new Map();

  showAll = true;
  ready = false;
  skeletonItems = fill(Array(5), 0);
  orderOptionsObject: any = {};

  get showOrderSelection(): boolean {
    if (!isNullOrUndefined(this.selectedMembers)) {
      return (this.orderOptions.length === this.selectedMembers.size) && this.orderOptions.every(o => !isNullOrUndefined(o));
    }
    return true;
  }

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toasterService: ToastrService,
    public bsModalRef: BsModalRef,
    private router: Router,
    private bsModalService: BsModalService,
    private callflowService: CallFlowService,
    private cdf: ChangeDetectorRef
  ) {
    this.initForm();
    this.memberStatusMap.set(1, { label: 'Active', value: 'active' });
    this.memberStatusMap.set(2, { label: 'Inactive', value: 'inactive' });
    this.memberStatusMap.set(3, { label: 'Invite Sent', value: 'invite_sent' });
    this.memberStatusMap.set(4, { label: 'Deleted', value: 'deleted' });

  }

  ngOnInit() {
    if (!this.isEdit) {
      this.selectedMembers = new Map();
    }
    this.initData();
  }

  initForm() {
    this.form = this.fb.group({
      search: [null],
      team_filter: ['all'],
      member_filter: ['all'],
    });

    this.form.valueChanges.subscribe(value => this.doFiltering());
  }

  initData() {
    this.ready = false;
    combineLatest(
      this.apiService.callGetTeams()
    ).pipe(
      take(1),
      finalize(() => this.ready = true),
    ).subscribe(([teamResponse]: [any]) => {
      if (teamResponse.success) {
        this.teamsFilterOptions = this.generateIOption(teamResponse.data.teams, 'team_id', 'team_name');
        this.teamsFilterOptions.unshift({
          selected: true,
          label: 'All Teams',
          value: 'all',
        });
      }

      const allMembersOptions = this.generateIOption(this.members, 'member_id', 'member_first_name', 'member_last_name');
      this.membersListOptions = this.filteredMembersListOptions = cloneDeep(allMembersOptions);

      this.membersFilterOptions = allMembersOptions;
      this.membersFilterOptions.unshift({
        selected: true,
        label: 'All Members',
        value: 'all',
      });
      // if(this.isEdit){
      //   if(!isNullOrUndefined(this.originalSelectedMembers) && this.selectedMembers.size === 0){
      //     this.selectedMembers = this.originalSelectedMembers;
      //   }
      // }
      this.fillSelectedMembersWithOrders();
    });
  }

  private fillSelectedMembersWithOrders() {
    if (!isNullOrUndefined(this.selectedMembers)) {
      this.originalSelectedMembers = cloneDeep(this.selectedMembers);
      const array = Array.from(this.selectedMembers.keys());
      for (let i = 0; i < array.length; i++) {
        const key = array[i];
        const value = this.selectedMembers.get(key);
        this.filteredMembersListOptions.find(member => (member.value as Number) === key).selected = true;
        const orderOption = {
          label: (i + 1).toString(),
          value: (i + 1).toString(),
          selected: false,
          data: { value: (i + 1) }
        };
        this.orderOptions.push(orderOption);
      }
      const orderArray = Array.from(this.selectedMembers.values());
      this.selectComponents.forEach((selectComponent, index) => {
        const orderOption = {
          label: (index + 1).toString(),
          value: (index + 1).toString(),
          selected: false,
          data: { value: (index + 1) }
        };
        selectComponent.onSelectOption(orderOption);
        // selectComponent.onSelectOption({
        //   label: '' + orderArray[index],
        //   value: '' + orderArray[index],
        //   selected: false,
        //   data: orderArray[index]
        // });
      });
      this.cdf.detectChanges();
    }
  }

  onChangeOrder(event, membersOption: IOption) {
    if (this.selectedMembers.size > 0) {
      this.selectedMembers.forEach((val, key) => {
        if (val === Number(event)) {
          this.selectedMembers.set(key, this.selectedMembers.get(Number(membersOption.value)));
        }
      });
    }
    this.selectedMembers.set(Number(membersOption.value), Number(event));
    this.orderOptions.map(option => option.data.hidden = false);
  }

  onSubmit() {
    this.callflowService.teamManageChange(this.selectedMembers);
    this.closeModal(true);

  }

  onClickOption(selectedOptions: IOption) {
    selectedOptions.selected = !selectedOptions.selected;
    console.log('selectedOptions', selectedOptions);
    console.log('this.orderOptions', this.orderOptions);
    console.log('this.selectedMembers', this.selectedMembers);
    if (selectedOptions.selected) {
      this.selectedMembers.set(Number(selectedOptions.value), null);
      this.selectedMembers.set(Number(selectedOptions.value), this.selectedMembers.size);
      this.orderOptions.push({
        label: this.selectedMembers.size.toString(),
        value: this.selectedMembers.size.toString(),
        selected: false,
        data: { value: this.selectedMembers.size }
      });
    } else {
      const keys = Array.from(this.selectedMembers.keys());
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (this.selectedMembers.get(key) > this.selectedMembers.get(Number(selectedOptions.value))) {
          this.selectedMembers.set(key, this.selectedMembers.get(key) - 1);
        }
      }
      this.selectedMembers.delete(Number(selectedOptions.value));
      this.orderOptions.splice(-1);
    }

    // If there is not selected then show all.
    if (this.selectedMembers.size === 0) {
      this.showAllMember();
    } else {
      this.doFiltering();
    }

  }

  doFiltering() {
    const search = this.f.search.value && this.f.search.value.toLowerCase();
    const teamId = this.f.team_filter.value;
    const memberId = this.f.member_filter.value;

    this.filteredMembersListOptions = this.membersListOptions.filter((o) => {
      return (!this.showAll ? o.selected : true)
        && (search ? o.label.toLowerCase().includes(search) : true)
        && (teamId && teamId !== 'all' ? o.data.team_id === teamId : true)
        && (memberId && memberId !== 'all' ? o.data.member_id === memberId : true);
    });

  }

  showSelectedMember() {
    this.showAll = false;
    this.doFiltering();
  }

  showAllMember() {
    this.showAll = true;
    this.doFiltering();
  }

  onSearchInputChanged(value: string) {
    this.searchValue = value;
    this.doFiltering();
  }

  generateIOption(items: any[], valueKey: string, labelKey: string, secondLabelKey?: string) {
    let newItems = [];
    if (items) {
      newItems = items.map(i => {
        return {
          label: secondLabelKey ? `${i[labelKey]} ${i[secondLabelKey]}` : i[labelKey],
          value: i[valueKey],
          selected: false,
          data: i,
        } as IOption;
      });
    }
    return newItems;
  }

  getIvrNumbersOptionsClone(selectedValue, nodeId): IOption[] {
    const clone = cloneDeep(this.orderOptions);
    const optionfound = clone.find(option1 => option1.data.value === selectedValue);
    const realOptionfound = this.orderOptions.find(option2 => option2.data.value === selectedValue);
    if (!isNullOrUndefined(optionfound)) {
      clone.find(option3 => Number(option3.value) === Number(selectedValue)).selected = true;
      // realOptionfound.data.nodeId = nodeId;
      // realOptionfound.data.hidden = true;
    }
    this.orderOptionsObject[selectedValue] = clone;
    return clone;
  }

  getMemberStatusMap(statusId: number) {
    return this.memberStatusMap.get(statusId) || '';
  }

  closeModal(noChange = false) {
    if (noChange) {
      this.bsModalService.setDismissReason('close');
    } else {
      if (this.isEdit) {
        if (this.originalSelectedMembers.size > 0) {
          this.selectedMembers = cloneDeep(this.originalSelectedMembers);
          // this.fillSelectedMembersWithOrders();
        } else {
          this.selectedMembers = new Map();
        }
      } else {
        this.selectedMembers = new Map();
      }
    }
    this.callflowService.teamManageChange(this.selectedMembers);
    this.bsModalRef.hide();
  }

  get f() {
    return this.form.controls;
  }

  ngOnDestroy() {
    if (this.subscription$) {
      this.selectedMembers = null;
      this.subscription$.unsubscribe();
    }
  }
}
