import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {AuthenticationService} from '@app/_services/authentication.service';
import {User} from '@app/_models/user';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser: User = this.authenticationService.currentUserValue;
        const url = state.url;

        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login']);
            return true;
        }

        if (url) {
            if (!url.toLowerCase().includes('onboarding') && currentUser && currentUser.is_onboarded === 0 && currentUser.role_id != 3) {
                await this.router.navigateByUrl('/onboarding');
                return false;
            }
            if (!url.toLowerCase().includes('onboarding') && currentUser && currentUser.is_onboarded === 1 && currentUser.activation != 2 && currentUser.role_id != 3) {
                await this.router.navigateByUrl('/onboarding');
                return false;
            }

            if (url.toLowerCase().includes('onboarding') && currentUser && currentUser.is_onboarded === 1 && currentUser.role_id != 3 && currentUser.activation == 2) {
                await this.router.navigateByUrl('/dashboard');
                return false;
            }
        }
        if (currentUser && currentUser.role_id == 3) {
            await this.router.navigateByUrl('/agent-dashboard');
            return false;

        }

        return true;
    }
}
