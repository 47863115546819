import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agent-layout',
  templateUrl: './agent-layout.component.html',
  styleUrls: ['./agent-layout.component.scss']
})
export class AgentLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
