import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'dsk-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

  @Input() group: FormGroup;
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() submitted = false;
  popovervalue: any;
  control = new FormControl();
  days = [
    {
      label: 'Su',
      value: 1,
    },
    {
      label: 'Mo',
      value: 2,
    },
    {
      label: 'Tu',
      value: 3,
    },
    {
      label: 'We',
      value: 4,
    },
    {
      label: 'Th',
      value: 5,
    },
    {
      label: 'Fr',
      value: 6,
    },
    {
      label: 'Sa',
      value: 7,
    },
  ];

  error = null; // The starting hour must be prior to the ending hour.
  constructor(private atp: AmazingTimePickerService) { }

  ngOnInit() {
    this.group.valueChanges.subscribe((values) => {
      const from = values.working_time_from || '';
      const fromMeridian = from && values.working_time_from_meridian || '';
      const to = values.working_time_to || '';
      const toMeridian = to && values.working_time_to_meridian || '';
      const value = `${from} ${fromMeridian} - ${to} ${toMeridian}`;

      const beginningTime = moment(`${from} ${fromMeridian}`, 'h:mm a');
      const endTime = moment(`${to} ${toMeridian}`, 'h:mm a');
      if (!beginningTime.isBefore(endTime)) {
        this.error = 'The starting hour must be prior to the ending hour.';
      } else {
        this.error = null;
      }

      this.control.setValue(value);
    });

    if (this.control.value === null) {
      this.group.patchValue({
        days_off: null,
        working_days: { 1: false, 2: true, 3: true, 4: true, 5: true, 6: true, 7: false },
        working_time_from: "09:00",
        working_time_from_meridian: "am",
        working_time_to: "05:00",
        working_time_to_meridian: "pm",
      });
    }
  }

  openPop(popover: NgbPopover): void {
    this.popovervalue = popover;
    popover.isOpen() ? popover.close() : popover.open();
  }

  opentimefrom(ev: any) {
    this.popovervalue.autoClose = 'inside';
    const amazingTimePicker = this.atp.open({
      changeToMinutes: true,
      time: this.transformtimetohrsec(this.group.controls.working_time_from.value, this.group.controls.working_time_from_meridian.value)
    });
    amazingTimePicker.afterClose().subscribe(time => {
      this.popovervalue.open();
      this.popovervalue.autoClose = 'outside';
      this.f.working_time_from.setValue(this.transformtimefrom(time));
      this.f.working_time_from_meridian.setValue(this.transformtimefrommeridian(time));
    });
  }

  transformtimefrom(time: any): any {
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    if (parseInt(hour) == 0)
      hour = 12;
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min}`
  }

  transformtimefrommeridian(time: any): any {
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    let part = hour >= 12 ? 'pm' : 'am';
    if (parseInt(hour) == 0) {
      hour = 12;
      part = 'am';
    }
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    return `${part}`
  }
  transformtimetohrsec(time: any, meridian: any): any {
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    if (meridian == "pm") {
      hour = parseInt(hour) + 12;
    }
    return `${hour}:${min}`
  }

  opentimeto(ev: any) {
    this.popovervalue.autoClose = 'inside';
    const amazingTimePicker = this.atp.open({
      changeToMinutes: true,
      time: this.transformtimetohrsec(this.group.controls.working_time_to.value, this.group.controls.working_time_to_meridian.value)
    });
    amazingTimePicker.afterClose().subscribe(time => {
      this.popovervalue.open();
      this.popovervalue.autoClose = 'outside';
      this.f.working_time_to.setValue(this.transformtimeto(time));
      this.f.working_time_to_meridian.setValue(this.transformtimetomeridian(time));
    });
  }

  transformtimeto(time: any): any {
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    if (parseInt(hour) == 0)
      hour = 12;
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min}`
  }

  transformtimetomeridian(time: any): any {
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    let part = hour >= 12 ? 'pm' : 'am';
    if (parseInt(hour) == 0) {
      hour = 12;
      part = 'am';
    }
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    return `${part}`
  }

  get f() {
    return this.group.controls;
  }

  workingDaysControl(key: number) {
    return this.group && this.group.get('working_days') ? this.group.get('working_days').get(`${key}`) : new FormControl();
  }

}
