import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ChangeDetectorRef} from '@angular/core';

@Component({
    selector: 'dsk-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {

    @Input() title = '';
    @Input() type: BUTTON_TYPE = 'button';
    @Input() status: BUTTON_STATUS = 'default';
    @Input() size: BUTTON_SIZE = 'small';
    @Input() disabled = false;
    @Input() loading = false;
    @Input() fullWidth = false;
    @Input() modified = false;
    @Input() rounded = false;
    @Input() locked = false;

    @Output() clicked = new EventEmitter<boolean>();

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {

    }

    onClick() {
        this.cdRef.detectChanges();
        if (!this.disabled) {
            this.clicked.emit(true);
        }
    }
}

type BUTTON_STATUS = 'default' | 'primary' | 'danger' | 'basic' |
    'warning' | 'success' | 'info' | 'subtle-danger' | 'subtle-primary' | 'default-transparent';
type BUTTON_TYPE = 'submit' | 'button';
type BUTTON_SIZE = 'tiny' | 'small' | 'medium' | 'large' | 'extreme';
