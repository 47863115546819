import { isNullOrUndefined } from 'util';
import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { IOption } from '@app/@core/interfaces';

@Component({
  selector: 'dsk-select-alt',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectAltComponent implements OnInit, OnChanges {

  @ViewChild('popover') popover;
  @Output() changed = new EventEmitter<string | number>();
  @Input() disabled = false;
  @Input() options: IOption[] = [];
  selectedOption: IOption;
  @Input() isSelected =  false;
  @Input() size = 'small';
  @Input() placeholder = '';
  @Input() fontSize = '14';
  @Input() isDarkMode = false;
  @Input() doubleCheckChange = false;

  constructor() { }

  ngOnInit() {
    if (this.options) {
      this.selectedOption = this.options.find(o => o.selected);
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (this.doubleCheckChange) {
      if (
        change
        && change.options
        && (!isNullOrUndefined(change.options.previousValue) && (change.options.previousValue as Array<IOption>).length > 0)
        && (!isNullOrUndefined(change.options.currentValue) && (change.options.currentValue as Array<IOption>).length > 0)
        && this.options
      ) {
        this.selectedOption = this.options.find(o => o.selected);
      }
      return;
    }
    if (change && change.options && this.options) {
      this.selectedOption = this.options.find(o => o.selected);
    }
  }

  onClosePopover() {

  }

  openDropdown() {
    if (!this.disabled) {
      this.popover.toggle();
    }
  }

  markOptionAsSelected(selectedOption: IOption) {
    this.options.forEach(o => {
      if (o.value === selectedOption.value) {
        o.selected = true;
      } else {
        o.selected = false;
      }
    });
  }

  onOpenPopover() {
  }

  onSelectOption(option: IOption) {
    this.changed.emit(option.value);
    this.selectedOption = option;
    this.markOptionAsSelected(option);
    
    this.popover.close();
  }

}
