import {isNullOrUndefined} from 'util';
import {IMyDateModel, IAngularMyDpOptions, AngularMyDatePickerDirective} from 'angular-mydatepicker';
import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import * as moment from 'moment';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {LocalizationService} from '@app/internationalization/localization.service';

@Component({
    selector: 'dsk-calendar-range-picker',
    templateUrl: './calendar-range-picker.component.html',
    styleUrls: ['./calendar-range-picker.component.scss']
})
export class CalendarRangePickerComponent implements OnInit {
    @ViewChild('popover') popover: NgbPopover;
    @ViewChild('dp') mydp: AngularMyDatePickerDirective;
    @Input() group: FormGroup;
    @Input() placeholder: string;
    @Input() disabled = false;
    @Input() isPrintView = false;
    _resetValue;
    @Input() set resetValue(val) {
        this._resetValue = val;
        if (!isNullOrUndefined(val)) {
            this.init();
            this.getTodayRange();
            // this.getMonthRange(0);
            this.apply();
        }
    }

    get resetValue() {
        return this._resetValue;
    }

    controlGroup: FormGroup;
    changeCounter = 0;
    days = [
        {
            label: 'Su',
            value: 1,
        },
        {
            label: 'Mo',
            value: 2,
        },
        {
            label: 'Tu',
            value: 3,
        },
        {
            label: 'We',
            value: 4,
        },
        {
            label: 'Th',
            value: 5,
        },
        {
            label: 'Fr',
            value: 6,
        },
        {
            label: 'Sa',
            value: 7,
        },
    ];

    myDpOptions: IAngularMyDpOptions = {
        inline: true,
        dateRange: true,
        dateFormat: 'yyyy-mm-dd',
        firstDayOfWeek: 'su',
        dayLabels: {su: 'Su', mo: 'Mo', tu: 'Tu', we: 'We', th: 'Th', fr: 'Fr', sa: 'Sa'},
        monthLabels: {
            1: 'January',
            2: 'February',
            3: 'March',
            4: 'April',
            5: 'May',
            6: 'June',
            7: 'July',
            8: 'August',
            9: 'September',
            10: 'October',
            11: 'November',
            12: 'December'
        },
        sunHighlight: false,
        markCurrentMonth: false,
        markCurrentYear: false
    };

    model: IMyDateModel;
     selectedLabel = 'This Month';
    //selectedLabel = 'Today';

    constructor(private fb: FormBuilder, private localizationService: LocalizationService) {
    }

    ngOnInit() {
        this.init();
        // this.selectedLabel = this.localizationService.translate('today');
    }

    init() {
        if (this.selectedLabel === 'This Month') {
            this.model = {
                isRange: true,
                singleDate: null,
                dateRange: {
                    beginJsDate: moment().startOf('month').toDate(),
                    endJsDate: moment().endOf('month').toDate()
                }
            };
            this.getMonthRange(0);
            this.apply();
        } else if (this.selectedLabel === 'Today') {
            this.model = {
                isRange: false,
                singleDate: null,
                dateRange: {
                    beginJsDate: moment().toDate(),
                    endJsDate: moment().toDate()
                }
            };
            this.getTodayRange(0);
            this.apply();
        }
        this.controlGroup = this.fb.group({
            from: [moment(this.group.value.startDate).format('YYYY-MM-DD')],
            to: [moment(this.group.value.endDate).format('YYYY-MM-DD')],
        });
        if (this.controlGroup.valid && this.group.valid) {
            this.selectedLabel =
                `${moment(this.controlGroup.get('from').value).format('YYYY-MM-DD')} - ${moment(this.controlGroup.get('to').value).format('YYYY-MM-DD')}`;
        }
        this.controlGroup.valueChanges.subscribe((values) => {
            const from = values.from;
            const to = values.to;
            this.writeRange(from, to);
        });
    }

    writeRange(from, to) {
        if (!isNullOrUndefined(from) && !isNullOrUndefined(to)) {
            this.model = {
                isRange: true,
                singleDate: null,
                dateRange: {
                    beginJsDate: moment(from).toDate(),
                    endJsDate: moment(to).toDate(),
                    formatted: this.generateFormattedLabel(from, to)
                }
            };
            if (!isNullOrUndefined(this.mydp)) {
                this.mydp.writeValue(this.model);
            }
        }
    }

    private generateFormattedLabel(from, to) {

        const momentToday = moment();
        const momentLastWeek = moment().add('week', -1);
        const momentLastMonth = moment().add('month', -1);
        const momentLast3Months = moment().add('month', -3);
        const momentLast6Months = moment().add('month', -6);

        const momentStartDate = moment(from);
        const momentEndDate = moment(to);
        const isToday = momentToday.isSame(momentStartDate, 'day') && momentToday.isSame(momentEndDate, 'day');

        const isThisWeek = momentToday.isSame(momentStartDate, 'week') && momentToday.isSame(momentEndDate, 'week');

        const isLastWeek = momentLastWeek.isSame(momentStartDate, 'week') && momentLastWeek.isSame(momentEndDate, 'week');

        const isThisMonth = momentToday.isSame(momentStartDate, 'month') && momentToday.isSame(momentEndDate, 'month');

        const isLastMonth = momentLastMonth.isSame(momentStartDate, 'month') && (momentLastMonth.isSame(momentEndDate, 'month') || momentToday.format('YYYY-MM-DD') === momentEndDate.format('YYYY-MM-DD'));

        const isLastThreeMonths = momentLast3Months.isSame(momentStartDate, 'month') && momentToday.format('YYYY-MM-DD') === momentEndDate.format('YYYY-MM-DD');

        const isLastSixMonths = momentLast6Months.isSame(momentStartDate, 'month') && momentToday.format('YYYY-MM-DD') === momentEndDate.format('YYYY-MM-DD');

        if (isToday) {
            return 'Today';
        }
        if (momentStartDate.isSame(momentEndDate)) {
            return `${moment(from).format('YYYY-MM-DD')} - ${moment(to).format('YYYY-MM-DD')}`;
        }
        if (isThisWeek) {
            return 'This Week';
        }
        if (isLastWeek) {
            return 'Last Week';
        }
        if (isThisMonth) {
            return 'This Month';
        }
        if (isLastMonth) {
            return 'Last Month';
        }
        if (isLastThreeMonths) {
            return 'Last 3 Months';
        }
        if (isLastSixMonths) {
            return 'Last 6 Months';
        }
        return `${moment(from).format('YYYY-MM-DD')} - ${moment(to).format('YYYY-MM-DD')}`;
    }

    clearDate(): void {
        this.mydp.clearDate();
        this.group.setValue({
            startDate: null,
            endDate: null,
            from: null,
            to: null,
        });
    }

    onDateChanged(event: IMyDateModel): void {
        this.selectedLabel = event.dateRange.formatted;
        if (!isNullOrUndefined(event.dateRange.beginJsDate) && !isNullOrUndefined(event.dateRange.endJsDate)) {
            const change = {
                from: event.dateRange.beginJsDate,
                to: event.dateRange.endJsDate
            };
            this.changeControlGroup(change);
        }
    }

    private changeControlGroup(change: { from, to }) {
        const from = moment(change.from).format('YYYY-MM-DD');
        const to = moment(change.to).format('YYYY-MM-DD');
        if (!isNullOrUndefined(this.controlGroup)) {
            this.controlGroup.setValue({
                from,
                to,
            });
        }
    }

    get f() {
        return this.group.controls;
    }

    prev(event: MouseEvent) {
        event.stopPropagation();
        // this.getMonthRange(-1);
        this.getTodayRange(-1);
        this.apply();
    }

    next(event: MouseEvent) {
        event.stopPropagation();
        // this.getMonthRange(1);
        this.getTodayRange(1);
        this.apply();
    }

    initToday() {
        const range = {beginJsDate: new Date(), endJsDate: new Date()};
        const change = {
            from: range.beginJsDate,
            to: range.endJsDate
        };
        this.changeControlGroup(change);
        this.writeRange(range.beginJsDate, range.endJsDate);
        return range;
    }

    getTodayRange(dayAddition: number = 0) {
        const beginJsAddition = moment(moment(this.model.dateRange.beginJsDate).add('days', dayAddition).toDate());
        const endDate = moment(beginJsAddition.isBefore(moment(this.model.dateRange.endJsDate)) ?
            moment(this.model.dateRange.endJsDate).add('days', dayAddition).toDate() : new Date()).toDate();
        // const range = { beginJsDate: new Date(), endJsDate: new Date() };
        const range = {
            beginJsDate: beginJsAddition.toDate(),
            endJsDate: beginJsAddition.toDate()
        };
        if (dayAddition === 0) {
            range.beginJsDate = moment().toDate();
            range.endJsDate = moment().toDate();
        }
        const change = {
            from: range.beginJsDate,
            to: range.endJsDate
        };
        this.changeControlGroup(change);
        this.writeRange(range.beginJsDate, range.endJsDate);
        return range;
    }

    getMonthRange(monthAddition: number, fromToday = false) {
        const beginJsAddition = moment(moment(this.model.dateRange.beginJsDate).add('months', monthAddition).toDate()).startOf('month');
        let endDate: Date;
        if (fromToday) {
            endDate = this.model.dateRange.endJsDate;
        } else {
            endDate = moment(beginJsAddition.isBefore(moment(this.model.dateRange.endJsDate)) ?
                moment(this.model.dateRange.endJsDate).add('months', monthAddition).endOf('month').toDate() : new Date()).toDate();
        }
        const range = {
            beginJsDate: beginJsAddition.toDate(),
            endJsDate: (monthAddition < 0) ?
                endDate : moment(moment(this.model.dateRange.endJsDate).add('months', monthAddition).toDate()).endOf('month').toDate()
        };
        if (monthAddition === 0) {
            range.beginJsDate = moment(moment().startOf('month')).toDate();
            range.endJsDate = moment(moment().endOf('month')).toDate();
        }
        const change = {
            from: range.beginJsDate,
            to: range.endJsDate
        };
        this.changeControlGroup(change);
        this.writeRange(range.beginJsDate, range.endJsDate);
    }

    getWeekRange(weekAddition: number) {
        const range = {
            beginJsDate: moment(moment().add('week', weekAddition).toDate()).startOf('week').toDate(),
            endJsDate: moment(moment().add('week', (weekAddition < 0 ? -1 : 0)).toDate()).endOf('week').toDate(),
        };
        const change = {
            from: range.beginJsDate,
            to: range.endJsDate
        };
        this.changeControlGroup(change);
        this.writeRange(range.beginJsDate, range.endJsDate);
    }


    closePopOver() {
        if (!isNullOrUndefined(this.popover) && this.popover.isOpen()) {
            this.popover.close();
        }
    }

    apply() {
        if (isNullOrUndefined(this.model)) {
            const range = {
                beginJsDate: moment().startOf('month').toDate(),
                endJsDate: moment().endOf('month').toDate()
            };
            this.group.setValue({
                startDate: range.beginJsDate,
                endDate: range.endJsDate,
                from: range.beginJsDate,
                to: range.endJsDate,
            });
            return;
        }
        if (!isNullOrUndefined(this.group) && !isNullOrUndefined(this.model.dateRange)) {
            this.group.setValue({
                startDate: this.model.dateRange.beginJsDate,
                endDate: this.model.dateRange.endJsDate,
                from: this.model.dateRange.beginJsDate,
                to: this.model.dateRange.endJsDate
            });
        }
        this.closePopOver();
    }
}
