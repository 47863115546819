import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-unsaved-changes-modal',
  templateUrl: './unsaved-changes-modal.component.html',
  styleUrls: ['./unsaved-changes-modal.component.scss']
})
export class UnsavedChangesModalComponent implements OnInit {
  constructor(
    public bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
  ) {
  }

  ngOnInit() {
  }

  closeModal() {
    this.bsModalService.setDismissReason('close');
    this.bsModalRef.hide();
  }

  onLeavePageClicked() {
    this.bsModalService.setDismissReason('leave');
    this.bsModalRef.hide();
  }
}
