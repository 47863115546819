import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '@app/_services/api.service';
import {Subscription} from 'rxjs';
import {IOption, ISettings} from '@app/@core/interfaces';
import {isNullOrUndefined} from 'util';
import {DataHelperService} from '@app/_services/data.helper.service';
import {MODAL_CLOSE_REASON} from '@app/variables/constants';
import {OptionService} from '@app/_services/option.service';

@Component({
    selector: 'app-advanced-working-hours-timezone',
    templateUrl: './advanced-working-hours-timezone.component.html',
    styleUrls: ['./advanced-working-hours-timezone.component.scss']
})
export class AdvancedWorkingHoursTimezoneComponent implements OnInit, OnDestroy {
    @Input() isEdit = false;
    @Input() data;
    @Input() isOriginal;
    @Input() isIvr = false;
    @Input() isEditRoot = false;
    @Input() isSubIvr = false;
    @Input() src;
    @Input() target;
    @Input() popupTitle = 'working_hours_timezone';
    form: FormGroup;
    timezoneOptions: IOption[] = [];
    private subscription$: Subscription;
    private subscription$1: Subscription;
    loading = false;

    get timezone() {
        return this.form.get('timezone').value;
    }

    constructor(
        private fb: FormBuilder,
        private apiService: ApiService,
        private toasterService: ToastrService,
        public  bsModalRef: BsModalRef,
        private bsModalService: BsModalService,
        private dataHelper: DataHelperService,
        private optionService: OptionService
    ) {
    }

    ngOnInit() {
        console.log('this.data', this.data);
        console.log('this.bsModalService', this.bsModalService);
        console.log('this.bsModalRef', this.bsModalRef);
        this.getTimezones();
        this.initForm();
    }

    getTimezones() {
        this.subscription$ = this.apiService.getTimezones().subscribe((response: any) => {
            this.timezoneOptions = this.optionService.generateIOption(response.data, 'gmtzone', 'timezone_name');
            console.log('this.timezoneOptions', this.timezoneOptions);
            this.subscription$1 = this.apiService.callGetCompanySettings().subscribe((response: any) => {
                if (response.success) {
                    const settings: ISettings =  response.data;
                    console.log('settings', settings);
                    const selectedTimeZone = this.timezoneOptions.find(option => option.data.id === settings.timezone);
                    this.form.get('timezone').setValue(selectedTimeZone.value);
                }
            });
        });
    }

    private initForm() {
        this.form = this.fb.group({
            timezone: [null, Validators.required],
        });

    }

    onSubmit() {
        if (this.form.invalid) {
            this.toasterService.warning('Please select a team.');
            return;
        }
        if (!isNullOrUndefined(this.timezone)) {
            if (this.isSubIvr) {
                this.dataHelper.sendPendingSubIvrData({
                    isEditRoot: this.isEditRoot,
                    isIvr: this.isIvr,
                    timezone: this.timezone,
                    target: this.target,
                    src: this.src,
                    isEdit: this.isEdit,
                    isAdvancedTimeCondition: this.bsModalRef.content.isAdvancedTimeCondition,
                    nodeId: !isNullOrUndefined(this.data) &&
                        !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                });
            } else if (this.isIvr && !this.isSubIvr) {
                this.dataHelper.sendPendingIvrData({
                    isEditRoot: this.isEditRoot,
                    isIvr: this.isIvr,
                    timezone: this.timezone,
                    target: this.target,
                    src: this.src,
                    isEdit: this.isEdit,
                    isAdvancedTimeCondition: this.bsModalRef.content.isAdvancedTimeCondition,
                    nodeId: !isNullOrUndefined(this.data) &&
                        !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                });
            } else {
                this.dataHelper.sendPendingData({
                    timezone: this.timezone,
                    target: this.target,
                    src: this.src,
                    isEdit: this.isEdit,
                    isAdvancedTimeCondition: this.bsModalRef.content.isAdvancedTimeCondition,
                    nodeId: !isNullOrUndefined(this.data) &&
                        !isNullOrUndefined(this.data.nodeId) ? this.data.nodeId : Math.floor(Math.random() * 1000) + 1
                });
            }
            this.closeModal(true);
        }
    }

    closeModal(noChange = false) {
        if (!noChange) {
            this.bsModalService.setDismissReason('close');
        } else {
            this.bsModalService.setDismissReason(MODAL_CLOSE_REASON.WORKING_HOURS_ADVANCED);
        }
        this.bsModalRef.hide();
    }

    ngOnDestroy() {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
        if (this.subscription$1) {
            this.subscription$1.unsubscribe();
        }
    }

}
