import {Component, OnInit, ElementRef} from '@angular/core';
import {ROUTES} from '../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {Router} from '@angular/router';
import {LocalizationService} from '@app/internationalization/localization.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public focus;
    public listTitles: any[];
    public location: Location;
    public languages = [];
    public currentLang: string = '';

    constructor(
        location: Location,
        private element: ElementRef,
        private router: Router,
        private localizationService: LocalizationService) {
        this.location = location;
    }

    ngOnInit() {
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        this.languages = this.localizationService.getLanguages();
        this.currentLang = this.localizationService.getMyLanguage();
    }

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }

        for (var item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === titlee) {
                return this.listTitles[item].title;
            }
        }
        return 'Dashboard';
    }

    public useLanguage(lang: string): void {
        console.log('useLanguage', lang);
        localStorage.setItem('language', lang);
        this.localizationService.useLanguage(lang);
        this.currentLang = this.localizationService.getMyLanguage();

        setTimeout(() => {
            window.location.reload();
        }, 100);
    }


}
