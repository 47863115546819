import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '@environments/environment';
import {User} from '@app/_models/user';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import {isNullOrUndefined} from 'util';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    public asyncLocalStorage = {
        setItem: function (key, value) {
            return Promise.resolve().then(function () {
                localStorage.setItem(key, value);
            });
        },
        getItem: function (key) {
            return Promise.resolve().then(function () {
                return localStorage.getItem(key);
            });
        }
    };
    isProfessionalPlan = false;

    constructor(private http: HttpClient,
                private toastr: ToastrService,
                private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.setIsProfessional();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    setCurrentUser(data) {
        let userString = data;
        let userObject = data;
        if (typeof data !== 'string') {
            userString = JSON.stringify(data);
        }
        if (typeof data !== 'object') {
            userObject = JSON.parse(data);
        }

        this.currentUserSubject.next(userObject);
        return this.asyncLocalStorage.setItem('currentUser', userString);
    }

    login(email: string, password: string) {

        const url = `login`;

        return this.http.post<any>(`${environment.apiUrl}/login`, {email, password})
            // return this.http.post<any>(`/login`, {email, password})
            .pipe(map(response => {
                if (response.success) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(response.data));
                    this.currentUserSubject.next(response.data);
                } else {
                    this.toastr.error(response.message);
                }
                return response;
            }));
    }


    public isAuthenticated(): boolean {
        let token = localStorage.getItem('token');
        if (token) {
            let tokenInfo = this.getDecodedAccessToken(token); // decode token
            let expireDate = tokenInfo.exp; // get token expiration dateTime
            var current_time = Date.now() / 1000;
            if (expireDate < current_time) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }


    logout() {
        let uri = JSON.parse(localStorage.getItem('currentUserServerLink'));
        let wsUri = 'wss://' + uri + ':5065/';
        if (uri) {
            let websocket = new WebSocket(wsUri);
            websocket.close();
        }
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentUserServerLink');
        // localStorage.removeItem('currentUserServerPass');
        localStorage.removeItem('call_key');
        this.currentUserSubject.next(null);
        this.router.navigateByUrl('/login');
    }

    forgotPassword(email) {
        // let url:string = `${environment.apiUrl}/password/create`;
        // return this.http.post(url, email);
        return this.http.post<any>(`${environment.apiUrl}/password/create`, {email});


    }

    resetPasswordCheckToken(token) {
        let url: string = `${environment.apiUrl}/password/find/` + token;
        return this.http.get(url);
    }

    resetPasswordProcess(data) {
        let url: string = `${environment.apiUrl}/password/reset`;
        return this.http.post(url, data);
    }

    markUserAsNonOnBoarding() {
        const user: User = this.currentUserValue;
        user.is_onboarded = 1;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
    }


    getPermission() {
        let user = this.currentUserSubject.value;
        return user['permissions'];

    }

    private setIsProfessional() {
        this.asyncLocalStorage.getItem('currentUser').then(val => {
            if (!isNullOrUndefined(val)) {
                const currentUser = JSON.parse(val);
                this.isProfessionalPlan = currentUser.plan_name === 'Professional';
            }
        });
    }
}
