import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HttpLoadingService {
    private _activeRequestsCount = new BehaviorSubject<number>(0);

    constructor() {}

    get activeRequestsCount(): Observable<number> {
        return this._activeRequestsCount.asObservable();
    }

    increment() {
        this._activeRequestsCount.next(this._activeRequestsCount.value + 1);
    }

    decrement() {
        this._activeRequestsCount.next(this._activeRequestsCount.value - 1);
    }
}
