import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataHelperService {
    private _pendingData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public pendingDataChanged: Observable<any> = this._pendingData.asObservable();

    private _pendingIvrData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public pendingIvrDataChanged: Observable<any> = this._pendingIvrData.asObservable();

    private _pendingSubIvrData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public pendingSubIvrDataChanged: Observable<any> = this._pendingSubIvrData.asObservable();

    // subIvrLevel = 0;

    constructor() { }

    sendPendingData(data: any) {
        this._pendingData.next(data);
    }

    sendPendingIvrData(data: any) {
        this._pendingIvrData.next(data);
    }

    sendPendingSubIvrData(data: any) {
        // data.subIvrLevel = this.subIvrLevel;
        // ++this.subIvrLevel;
        this._pendingSubIvrData.next(data);
    }
}
